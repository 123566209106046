import React from "react"
import classNames from "classnames"
import { UseFormRegister } from "react-hook-form"

type Props = {
    type: string
    placeholder: string
    label: string
    layout?: string
    errorMessage?: string
    required?: boolean
    className?: string
    name: string
    disabled?: boolean
    register: UseFormRegister<any>
}

export const InputField: React.FC<Props> = ({
    register,
    label,
    placeholder,
    className,
    required,
    type,
    disabled,
    name,
    errorMessage,
    layout,
}) => {
    return (
        <div className={layout}>
            <label
                htmlFor={label}
                className="block text-sm font-semibold tracking-wide text-gray-100">
                {label} {required && <span className="text-red-500">*</span>}
            </label>
            <input
                type={type}
                {...register(name)}
                disabled={disabled}
                placeholder={placeholder}
                className={classNames(inputStyles.input + className, {
                    [inputStyles.error]: !!errorMessage,
                    [inputStyles.focused]: !errorMessage,
                })}
            />
            <p className={inputStyles.errorMessage}>{errorMessage}</p>
        </div>
    )
}

export const inputStyles = {
    input: "mt-1 placeholder:text-sm rounded-md text-gray-100 appearance-none bg-black block w-full focus:ring-0 p-3.5 border border-gray-700 placeholder-gray-400 focus:outline-none sm:text-sm ",
    error: "border-red-500 focus:ring-0 focus:border-2 focus:border-red-600 placeholder:text-red-400 placehoder:font-xs ",
    focused: "ring-0 focus:border-[1px] focus:border-gray-400",
    errorMessage: "text-red-500 text-xs font-medium tracking-wide mt-1",
}
