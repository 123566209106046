import { Table } from "common/Table/Table";
import { Fragment, useCallback, useEffect, useState } from "react";
import UserService from "services/user.service";
import { useDebounce } from "hooks/useDebounce";
import { exportToCSV } from "utils/funcs";

const service = new UserService();

export const VivintLeads = () => {
  const columns = [
    {
      key: "ID",
      dbColName: "ID",
      title: "ID",
      freeze: true,
      freezeIndex: "left-0",
    },
    {
      key: "FIRST_NAME",
      dbColName: "FIRST_NAME",
      title: "FIRST NAME",
      freeze: true,
      freezeIndex: "left-12",
    },
    { key: "LAST_NAME", dbColName: "LAST_NAME", title: "LAST NAME", 
      freeze: true,
      freezeIndex: "left-36",
     },
    { key: "EMAIL", dbColName: "EMAIL", title: "EMAIL" },
    { key: "PHONE", dbColName: "PHONE", title: "PHONE" },
    { key: "BADGEID", dbColName: "BADGEID", title: "BADGEID" },
    { key: "OFFICE", dbColName: "OFFICE", title: "OFFICE" },
    { key: "ADDRESS", dbColName: "ADDRESS", title: "ADDRESS" },
    { key: "CITY", dbColName: "CITY", title: "CITY" },
    { key: "STATE", dbColName: "STATE", title: "STATE" },
    { key: "ZIPCODE", dbColName: "ZIPCODE", title: "ZIPCODE" },
    {
      key: "CREATION_DATE",
      dbColName: "CREATION_DATE",
      title: "CREATION DATE",
    },
    {
      key: "POWER_BILL",
      dbColName: "POWER_BILL",
      title: "POWER BILL",
      render: (image) => (
        <a
          className="text-white bg-green-500 text-sm rounded-lg px-3 py-2"
          href={image}
          target="_blank"
          rel="noreferrer"
        >
          Download Bill
        </a>
      ),
    },
    { key: "SIGNATURE", dbColName: "SIGNATURE", title: "SIGNATURE" },
  ];

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 100,
  });

  const debounced = useDebounce(pagination, 500);

  const fetchData = useCallback(() => {
    setLoading(true);
    service
      .getVivintLeads(debounced)
      .then((resp) => {
        setTotalItems(resp.data.data.length);
        setData(resp.data.data);
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setLoading(false);
      });
  }, [debounced]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleExport = () => {
    exportToCSV(data, "leads");
  };

  return (
    <Fragment>
      <div className="flex flex-col mb-4">
        <h1 className="font-medium text-gray-700 tracking-wide text-xl">
          Vivint Leads
        </h1>
        <p className="text-gray-500 text-sm">View all leads</p>
      </div>
      <Table
        data={data}
        loadingData={loading}
        handleExport={handleExport}
        pagination={pagination}
        setPagination={setPagination}
        setData={setData}
        totalItems={totalItems}
        columns={columns}
      />
    </Fragment>
  );
};
