import React from "react"
import 'flowbite'
import "react-notifications-component/dist/theme.css"

import { ReactNotifications } from "react-notifications-component"

import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"

import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import { persistor, store } from "redux/store"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <ReactNotifications />
            <App />
        </PersistGate>
    </Provider>,
)