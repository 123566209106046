import { Table } from "common/Table/Table";
import { useEffect, useState } from "react";
import UserService from "services/user.service";
import { exportToCSV } from "utils/funcs";

const service = new UserService();

export const DigitalHire = () => {
  const columns = [
    { key: "ROW_ID", title: "ID", label: "ID", dbColName: "ROW_ID" },
    {
      key: "FIRST_NAME",
      title: "FIRST NAME",
      label: "FIRST NAME",
      dbColName: "FIRST_NAME",
    },
    {
      key: "LAST_NAME",
      title: "LAST NAME",
      label: "LAST NAME",
      dbColName: "LAST_NAME",
    },
    {
      key: "FULL_NAME",
      title: "FULL NAME",
      label: "FULL NAME",
      dbColName: "CONTACT_FULL_NAME",
    },
    { key: "EMAIL", title: "EMAIL", label: "EMAIL", dbColName: "EMAIL" },
    { key: "PHONE", title: "PHONE", label: "PHONE", dbColName: "PHONE" },
    { key: "TYPE", title: "TYPE", label: "TYPE", dbColName: "TYPE" },
    { key: "SOURCE", title: "SOURCE", label: "SOURCE", dbColName: "SOURCE" },
    {
      key: "TIMESTAMP",
      title: "TIMESTAMP",
      label: "TIMESTAMP",
      dbColName: "TIMESTAMP",
    },
    {
      key: "PRESEASON_BLITZ_REGISTERED",
      title: "PRESEASON BLITZ REGISTERED",
      label: "PRESEASON BLITZ REGISTERED",
      dbColName: "PRESEASON_BLITZ_REGISTERED",
    },
    {
      key: "PRESEASON_BLITZ_ATTENDED",
      title: "PRESEASON BLITZ ATTENDED",
      label: "PRESEASON BLITZ ATTENDED",
      dbColName: "PRESEASON_BLITZ_ATTENDED",
    },
    {
      key: "LAST_SIGNED_CONTACT_DATE",
      title: "LAST SIGNED CONTACT DATE",
      label: "LAST SIGNED CONTACT DATE",
      dbColName: "LAST_SIGNED_CONTACT_DATE",
    },
    {
      key: "LIST_STARTED",
      title: "LIST STARTED",
      label: "LIST STARTED",
      dbColName: "LIST_STARTED",
    },
    {
      key: "LAST_COMPLETED",
      title: "LAST COMPLETED",
      label: "LAST COMPLETED",
      dbColName: "LAST_COMPLETED",
    },

    {
      key: "AGREEMENT_SIGNED",
      title: "AGREEMENT SIGNED",
      label: "AGREEMENT SIGNED",
      dbColName: "AGREEMENT_SIGNED",
    },
    {
      key: "LAST_STREET_GENIE_LOGIN",
      title: "LAST STREET GENIE LOGIN",
      label: "LAST STREET GENIE LOGIN",
      dbColName: "LAST_STREET_GENIE_LOGIN",
    },
    {
      key: "COMPLETED_ONBOARD_TRAINING",
      title: "COMPLETED ONBOARD TRAINING",
      label: "COMPLETED ONBOARD TRAINING",
      dbColName: "COMPLETED_ONBOARD_TRAINING",
    },

    {
      key: "FIRST_INSTALL_DATE",
      title: "FIRST INSTALL DATE",
      label: "FIRST INSTALL DATE",
      dbColName: "FIRST_INSTALL_DATE",
    },
    {
      key: "LAST_INSTALL_DATE",
      title: "LAST INSTALL DATE",
      label: "LAST INSTALL DATE",
      dbColName: "LAST_INSTALL_DATE",
    },
    {
      key: "LAST_SOLAR_LEAD",
      title: "LAST SOLAR LEAD",
      label: "LAST SOLAR LEAD",
      dbColName: "LAST_SOLAR_LEAD",
    },
    { key: "RWS", title: "RWS", label: "RWS", dbColName: "RWS" },
    {
      key: "AGREEMENT_SENT",
      title: "AGREEMENT SENT",
      label: "AGREEMENT SENT",
      dbColName: "AGREEMENT_SENT",
    },
    {
      key: "RECRUITER_BADGE_ID",
      title: "RECRUITER BADGE ID",
      label: "RECRUITER BADGE ID",
      dbColName: "RECRUITER_BADGE_ID",
    },
    {
      key: "LAST_SOLAR_CLOSED",
      title: "LAST SOLAR CLOSED",
      label: "LAST SOLAR CLOSED",
      dbColName: "LAST_SOLAR_CLOSED",
    },
    { key: "STAGE", title: "STAGE", label: "STAGE", dbColName: "STAGE" },
    { key: "FP", title: "FP", label: "FP", dbColName: "FP" },
    {
      key: "SOURCE_TYPE",
      title: "SOURCE TYPE",
      label: "SOURCE TYPE",
      dbColName: "SOURCE_TYPE",
    },
    {
      key: "SOURCE_CATEGORY",
      title: "SOURCE CATEGORY",
      label: "SOURCE CATEGORY",
      dbColName: "SOURCE_CATEGORY",
    },
    { key: "EFP", title: "EFP", label: "EFP", dbColName: "EFP" },
    {
      key: "SIGNED_REPS",
      title: "SIGNED REPS",
      label: "SIGNED REPS",
      dbColName: "SIGNED_REPS",
    },
    {
      key: "MAX_POSITION",
      title: "MAX POSITION",
      label: "MAX POSITION",
      dbColName: "MAX_POSITION",
    },
    {
      key: "ONBOARD DATE",
      title: "ONBOARD DATE",
      label: "ONBOARD DATE",
      dbColName: "ONBOARD_DATE",
    },
    {
      key: "LAST_SOLAR_INSTALL",
      title: "LAST SOLAR INSTALL",
      label: "LAST SOLAR INSTALL",
      dbColName: "LAST_SOLAR_INSTALL",
    },
    {
      key: "LAST_RECRUIT_WITH_SALE",
      title: "LAST RECRUIT WITH SALE",
      label: "LAST RECRUIT WITH SALE",
      dbColName: "LAST_RECRUIT_WITH_SALE",
    },
    { key: "TENURE", title: "TENURE", label: "TENURE", dbColName: "TENURE" },
    {
      key: "APPLICATION_SUBMITTED",
      title: "APPLICATION SUBMITTED",
      label: "APPLICATION SUBMITTED",
      dbColName: "APPLICATION_SUBMITTED",
    },
    {
      key: "MANAGER_INTERVIEW",
      title: "MANAGER INTERVIEW",
      label: "MANAGER INTERVIEW",
      dbColName: "MANAGER_INTERVIEW",
    },
    {
      key: "CONTACTS",
      title: "CONTACTS",
      label: "CONTACTS",
      dbColName: "CONTACTS",
    },
    { key: "TEAM", title: "TEAM", label: "TEAM", dbColName: "TEAM" },
    {
      key: "SENIOR_REGION",
      title: "SENIOR REGION",
      label: "SENIOR REGION",
      dbColName: "SENIOR_REGION",
    },
    {
      key: "PARTNERSHIP",
      title: "PARTNERSHIP",
      label: "PARTNERSHIP",
      dbColName: "PARTNERSHIP",
    },
    {
      key: "TEAM_LEADER_EMAIL",
      title: "TEAM LEADER EMAIL",
      label: "TEAM LEADER EMAIL",
      dbColName: "TEAM_LEADER_EMAIL",
    },
    {
      key: "MANAGEMENT_GROUP_LEADER_ID",
      title: "MANAGEMENT GROUP LEADER ID",
      label: "MANAGEMENT GROUP LEADER ID",
      dbColName: "MANAGEMENT_GROUP_LEADER_ID",
    },
    {
      key: "MANAGEMENT_GROUP_LEADER_NAME",
      title: "MANAGEMENT GROUP LEADER NAME",
      label: "MANAGEMENT GROUP LEADER NAME",
      dbColName: "MANAGEMENT_GROUP_LEADER_NAME",
    },
    { key: "REGION", title: "REGION", label: "REGION", dbColName: "REGION" },
    {
      key: "DIVISION",
      title: "DIVISION",
      label: "DIVISION",
      dbColName: "DIVISION",
    },
    {
      key: "TEAM_LEADER_ID",
      title: "TEAM LEADER ID",
      label: "TEAM LEADER ID",
      dbColName: "TEAM_LEADER_ID",
    },
    {
      key: "TEAM_LEADER_NAME",
      title: "TEAM LEADER NAME",
      label: "TEAM LEADER NAME",
      dbColName: "TEAM_LEADER_NAME",
    },
    {
      key: "TEAM_LEADER_PHONE_NUMBER",
      title: "TEAM LEADER PHONE NUMBER",
      label: "TEAM LEADER PHONE NUMBER",
      dbColName: "TEAM_LEADER_PHONE_NUMBER",
    },
    {
      key: "SENIOR_MANAGEMENT_GROUP",
      title: "SENIOR MANAGEMENT GROUP",
      label: "SENIOR MANAGEMENT GROUP",
      dbColName: "SENIOR_MANAGEMENT_GROUP",
    },
    {
      key: "MANAGEMENT_GROUP_LEADER_EMAIL",
      title: "MANAGEMENT GROUP LEADER EMAIL",
      label: "MANAGEMENT GROUP LEADER EMAIL",
      dbColName: "MANAGEMENT_GROUP_LEADER_EMAIL",
    },
    {
      key: "SENIOR_MANAGEMENT_GROUP_LEADER_ID",
      title: "SENIOR MANAGEMENT GROUP LEADER ID",
      label: "SENIOR MANAGEMENT GROUP LEADER ID",
      dbColName: "SENIOR_MANAGEMENT_GROUP_LEADER_ID",
    },
    {
      key: "MANAGEMENT_GROUP",
      title: "MANAGEMENT GROUP",
      label: "MANAGEMENT GROUP",
      dbColName: "MANAGEMENT_GROUP",
    },
    {
      key: "MANAGEMENT_GROUP_LEADER_PHONE_NUMBER",
      title: "MANAGEMENT GROUP LEADER PHONE NUMBER",
      label: "MANAGEMENT GROUP LEADER PHONE NUMBER",
      dbColName: "MANAGEMENT_GROUP_LEADER_PHONE_NUMBER",
    },
    {
      key: "SENIOR_MANAGEMENT_GROUP_LEADER",
      title: "SENIOR MANAGEMENT GROUP LEADER",
      label: "SENIOR MANAGEMENT GROUP LEADER",
      dbColName: "SENIOR_MANAGEMENT_GROUP_LEADER",
    },
    {
      key: "SENIOR_MANAGEMENT_GROUP_LEADER_PHONE_NUMBER",
      title: "SENIOR MANAGEMENT GROUP LEADER PHONE NUMBER",
      label: "SENIOR MANAGEMENT GROUP LEADER PHONE NUMBER",
      dbColName: "SENIOR_MANAGEMENT_GROUP_LEADER_PHONE_NUMBER",
    },
    {
      key: "SENIOR_MANAGEMENT_GROUP_LEADER_EMAIL",
      title: "SENIOR MANAGEMENT GROUP LEADER EMAIL",
      label: "SENIOR MANAGEMENT GROUP LEADER EMAIL",
      dbColName: "SENIOR_MANAGEMENT_GROUP_LEADER_EMAIL",
    },
    {
      key: "REGION_LEADER_ID",
      title: "REGION LEADER ID",
      label: "REGION LEADER ID",
      dbColName: "REGION_LEADER_ID",
    },
    {
      key: "REGION_LEADER_NAME",
      title: "REGION LEADER NAME",
      label: "REGION LEADER NAME",
      dbColName: "REGION_LEADER_NAME",
    },
    {
      key: "REGION_LEADER_PHONE_NUMBER",
      title: "REGION LEADER PHONE NUMBER",
      label: "REGION LEADER PHONE NUMBER",
      dbColName: "REGION_LEADER_PHONE_NUMBER",
    },
    {
      key: "REGION_LEADER_EMAIL",
      title: "REGION LEADER EMAIL",
      label: "REGION LEADER EMAIL",
      dbColName: "REGION_LEADER_EMAIL",
    },
    {
      key: "SENIOR_REGION_ID",
      title: "SENIOR REGION ID",
      label: "SENIOR REGION ID",
      dbColName: "SENIOR_REGION_ID",
    },
    {
      key: "SENIOR_REGION_NAME",
      title: "SENIOR_REGION_NAME",
      label: "SENIOR_REGION_NAME",
      dbColName: "SENIOR_REGION_NAME",
    },
    {
      key: "REMINDER_TEXT_TIME",
      title: "REMINDER TEXT TIME",
      label: "REMINDER TEXT TIME",
      dbColName: "REMINDER_TEXT_TIME",
    },
    {
      key: "TEMINDER_TEXT_TIMEZONE",
      title: "TEMINDER TEXT TIMEZONE",
      label: "TEMINDER TEXT TIMEZONE",
      dbColName: "TEMINDER_TEXT_TIMEZONE",
    },
    {
      key: "SENIOR_REGION_LEADER_EMAIL",
      title: "SENIOR REGION LEADER EMAIL",
      label: "SENIOR REGION LEADER EMAIL",
      dbColName: "SENIOR_REGION_LEADER_EMAIL",
    },
    {
      key: "CELL_PHONE",
      title: "CELL PHONE",
      label: "CELL PHONE",
      dbColName: "CELL_PHONE",
    },
    {
      key: "CONTACT_CREATED",
      title: "CONTACT CREATED",
      label: "CONTACT CREATED",
      dbColName: "CONTACT_CREATED",
    },
    {
      key: "D2D_EXP",
      title: "D2D EXP",
      label: "D2D EXP",
      dbColName: "D2D_EXP",
    },
    {
      key: "SENIOR_REGION_LEADER_PHONE_NUMBER",
      title: "SENIOR REGION LEADER PHONE NUMBER",
      label: "SENIOR REGION LEADER PHONE NUMBER",
      dbColName: "SENIOR_REGION_LEADER_PHONE_NUMBER",
    },
    {
      key: "WEEKLY_TRAINING_ATTENDED",
      title: "WEEKLY TRAINING ATTENDED",
      label: "WEEKLY TRAINING ATTENDED",
      dbColName: "WEEKLY_TRAINING_ATTENDED",
    },
    {
      key: "PARTNER_LEADER_PHONE_NUMBER",
      title: "PARTNER LEADER PHONE NUMBER",
      label: "PARTNER LEADER PHONE NUMBER",
      dbColName: "PARTNER_LEADER_PHONE_NUMBER",
    },
    {
      key: "RECUITER_PHONE",
      title: "RECUITER PHONE",
      label: "RECUITER PHONE",
      dbColName: "RECUITER_PHONE",
    },
    {
      key: "ONLINE_INFO_SESSION_REGISTERED",
      title: "ONLINE INFO SESSION REGISTERED",
      label: "ONLINE INFO SESSION REGISTERED",
      dbColName: "ONLINE_INFO_SESSION_REGISTERED",
    },
    {
      key: "PATNER_LEADER_ID",
      title: "PATNER LEADER ID",
      label: "PATNER LEADER ID",
      dbColName: "PATNER_LEADER_ID",
    },
    {
      key: "RECUITER_BADGEID",
      title: "RECUITER BADGEID",
      label: "RECUITER BADGEID",
      dbColName: "RECUITER_BADGEID",
    },
    {
      key: "RECUITER_NAME",
      title: "RECUITER NAME",
      label: "RECUITER NAME",
      dbColName: "RECUITER_NAME",
    },
    {
      key: "ONLINE_INFO_SESSION_ATTENDED",
      title: "ONLINE INFO SESSION ATTENDED",
      label: "ONLINE INFO SESSION ATTENDED",
      dbColName: "ONLINE_INFO_SESSION_ATTENDED",
    },
    {
      key: "PARTNER_LEADER_NAME",
      title: "PARTNER LEADER NAME",
      label: "PARTNER LEADER NAME",
      dbColName: "PARTNER_LEADER_NAME",
    },
    {
      key: "SIGNED_EMAIL_LOOKUP",
      title: "SIGNED EMAIL LOOKUP",
      label: "SIGNED EMAIL LOOKUP",
      dbColName: "SIGNED_EMAIL_LOOKUP",
    },
    {
      key: "SIGNED_FULL_NAME_LOOKUP",
      title: "SIGNED FULL NAME LOOKUP",
      label: "SIGNED FULL NAME LOOKUP",
      dbColName: "SIGNED_FULL_NAME_LOOKUP",
    },
    {
      key: "REMINDER_TEXT_DATE",
      title: "REMINDER TEXT DATE",
      label: "REMINDER TEXT DATE",
      dbColName: "REMINDER_TEXT_DATE",
    },
    { key: "OTHER", title: "OTHER", label: "OTHER", dbColName: "OTHER" },
    {
      key: "SIGNED_PHONE_LOOKUP",
      title: "SIGNED PHONE LOOKUP",
      label: "SIGNED PHONE LOOKUP",
      dbColName: "SIGNED_PHONE_LOOKUP",
    },
    {
      key: "SIGNED_BADGE_ID",
      title: "SIGNED BADGE ID",
      label: "SIGNED BADGE ID",
      dbColName: "SIGNED_BADGE_ID",
    },
  ];

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 100,
  });

  useEffect(() => {
    setLoading(true);
    service
      .getDigitalHire(pagination)
      .then((resp) => {
        setData(
          resp.data.data.map((item) => ({
            ...item,
            TIMESTAMP: new Date(item.TIMESTAMP).toLocaleString(),
          }))
        );
        setTotalItems(resp.data.total_docs);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [pagination]);

  const handleExport = () => {
    exportToCSV(data, "digital")
  }
  return (
    <Table
      data={data}
      loadingData={loading}
      handleExport={handleExport}
      setPagination={setPagination}
      pagination={pagination}
      totalItems={totalItems}
      columns={columns}
    />
  );
};
