import { Button, Modal } from "flowbite-react";

export const ConfirmModal = ({ message, onClickConfirm, open, setOpen }) => {
  return (
    <Modal show={open} onClose={() => setOpen(false)} size="md" popup>
      <Modal.Header />
      <Modal.Body>
        <div className="text-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="mx-auto mb-2 h-20 w-20 text-red-400"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
            />
          </svg>
          <h3 className="mb-5 text-2xl font-medium text-gray-500 dark:text-gray-400">
            {message}
          </h3>
          <div className="flex justify-center gap-4">
            <Button color="failure" onClick={onClickConfirm}>
              Yes, I'm sure
            </Button>
            <Button color="gray" onClick={() => setOpen(false)}>
              No, cancel
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
