import { Table } from "common/Table/Table";
import { useEffect, useState } from "react";
import UserService from "services/user.service";

const service = new UserService();

export const InfoSessions = () => {
  const columns = [
    {
      key: "FIRST_NAME",
      title: "FIRST NAME",
      label: "FIRST NAME",
      dbColName: "FIRST_NAME",
    },
    {
      key: "LAST_NAME",
      title: "LAST NAME",
      label: "LAST NAME",
      dbColName: "LAST_NAME",
    },
    { key: "PHONE", title: "PHONE", label: "PHONE", dbColName: "PHONE" },
    { key: "EMAIL", title: "EMAIL", label: "EMAIL", dbColName: "EMAIL" },
    {
      key: "ZIPCODE",
      title: "ZIPCODE",
      label: "ZIPCODE",
      dbColName: "ZIPCODE",
    },
    {
      key: "DATE_SUBMISSION",
      title: "DATE SUBMISSION",
      label: "DATE SUBMISSION",
      dbColName: "DATE_SUBMISSION",
    },
    {
      key: "RECRUITER_BADGE_ID",
      title: "RECRUITER BADGE ID",
      label: "RECRUITER BADGE ID",
      dbColName: "RECRUITER_BADGE_ID",
    },
    {
      key: "CELL_PHONE",
      title: "CELL PHONE",
      label: "CELL PHONE",
      dbColName: "CELL_PHONE",
    },
    {
      key: "CONTACT_FIRST_NAME",
      title: "CONTACT FIRST NAME",
      label: "CONTACT FIRST NAME",
      dbColName: "CONTACT_FIRST_NAME",
    },
    {
      key: "CONTACT_LAST_NAME",
      title: "CONTACT LAST NAME",
      label: "CONTACT LAST NAME",
      dbColName: "CONTACT_LAST_NAME",
    },
    {
      key: "CONTACT_FULL_NAME",
      title: "CONTACT FULL NAME",
      label: "CONTACT FULL NAME",
      dbColName: "CONTACT_FULL_NAME",
    },
    {
      key: "WEB_OCCURANCE",
      title: "WEB OCCURANCE",
      label: "WEB OCCURANCE",
      dbColName: "WEB_OCCURANCE",
    },
    {
      key: "DATE_AS_TEXT",
      title: "DATE AS TEXT",
      label: "DATE AS TEXT",
      dbColName: "DATE_AS_TEXT",
    },
    {
      key: "DATE_REGISTERED",
      title: "DATE REGISTERED",
      label: "DATE REGISTERED",
      dbColName: "DATE_REGISTERED",
    },
    {
      key: "CONTACT_EMAIL",
      title: "CONTACT EMAIL",
      label: "CONTACT EMAIL",
      dbColName: "CONTACT_EMAIL",
    },
    { key: "SOURCE", title: "SOURCE", label: "SOURCE", dbColName: "SOURCE" },
    {
      key: "D2D_EXP",
      title: "D2D_EXP",
      label: "D2D_EXP",
      dbColName: "D2D_EXP",
    },
    { key: "OTHER", title: "OTHER", label: "OTHER", dbColName: "OTHER" },
    { key: "NOTES", title: "NOTES", label: "NOTES", dbColName: "NOTES" },
    {
      key: "DATE_SUBMITTED",
      title: "DATE_SUBMITTED",
      label: "DATE_SUBMITTED",
      dbColName: "DATE_SUBMITTED",
    },
    {
      key: "REMINDER_TEXT_DATE",
      title: "REMINDER_TEXT_DATE",
      label: "REMINDER_TEXT_DATE",
      dbColName: "REMINDER_TEXT_DATE",
    },
    {
      key: "REMINDER_TEXT_TIME",
      title: "REMINDER TEXT TIME",
      label: "REMINDER TEXT TIME",
      dbColName: "REMINDER_TEXT_TIME",
    },
    {
      key: "REMINDER_TEXT_TIMEZONE",
      title: "REMINDER TEXT TIMEZONE",
      label: "REMINDER TEXT TIMEZONE",
      dbColName: "REMINDER_TEXT_TIMEZONE",
    },
    {
      key: "SIGNED_PHONE_LOOKUP",
      title: "SIGNED PHONE LOOKUP",
      label: "SIGNED PHONE LOOKUP",
      dbColName: "SIGNED_PHONE_LOOKUP",
    },
    {
      key: "SIGNED_EMAIL_LOOKUP",
      title: "SIGNED EMAIL LOOKUP",
      label: "SIGNED EMAIL LOOKUP",
      dbColName: "SIGNED_EMAIL_LOOKUP",
    },
    {
      key: "SIGNED_FULL_NAME_LOOKUP",
      title: "SIGNED FULL NAME LOOKUP",
      label: "SIGNED FULL NAME LOOKUP",
      dbColName: "SIGNED_FULL_NAME_LOOKUP",
    },
    {
      key: "SIGNED_BADGE_ID",
      title: "SIGNED BADGE ID",
      label: "SIGNED BADGE ID",
      dbColName: "SIGNED_BADGE_ID",
    },
    {
      key: "CONTACT_CREATED",
      title: "CONTACT_CREATED",
      label: "CONTACT_CREATED",
      dbColName: "CONTACT_CREATED",
    },
    {
      key: "ONLINE_INFO_SESSION_REGISTERED",
      title: "ONLINE_INFO_SESSION_REGISTERED",
      label: "ONLINE_INFO_SESSION_REGISTERED",
      dbColName: "ONLINE_INFO_SESSION_REGISTERED",
    },
    {
      key: "ONLINE_INFO_SESSION_ATTENDED",
      title: "ONLINE_INFO_SESSION_ATTENDED",
      label: "ONLINE_INFO_SESSION_ATTENDED",
      dbColName: "ONLINE_INFO_SESSION_ATTENDED",
    },
    {
      key: "WEEKLY_TRAINING_ATTENDED",
      title: "WEEKLY_TRAINING_ATTENDED",
      label: "WEEKLY_TRAINING_ATTENDED",
      dbColName: "WEEKLY_TRAINING_ATTENDED",
    },
    {
      key: "APPLICATION_SUBMITTED",
      title: "APPLICATION_SUBMITTED",
      label: "APPLICATION_SUBMITTED",
      dbColName: "APPLICATION_SUBMITTED",
    },
    {
      key: "MANAGER_INTERVIEW",
      title: "MANAGER_INTERVIEW",
      label: "MANAGER_INTERVIEW",
      dbColName: "MANAGER_INTERVIEW",
    },
    {
      key: "AGREEMENT_SENT",
      title: "AGREEMENT_SENT",
      label: "AGREEMENT_SENT",
      dbColName: "AGREEMENT_SENT",
    },
    {
      key: "AGREEMENT_SIGNED",
      title: "AGREEMENT_SIGNED",
      label: "AGREEMENT_SIGNED",
      dbColName: "AGREEMENT_SIGNED",
    },
    {
      key: "ONBOARD_DATE",
      title: "ONBOARD_DATE",
      label: "ONBOARD_DATE",
      dbColName: "ONBOARD_DATE",
    },
    {
      key: "LAST_STREET_GENIE_LOGIN",
      title: "LAST_STREET_GENIE_LOGIN",
      label: "LAST_STREET_GENIE_LOGIN",
      dbColName: "LAST_STREET_GENIE_LOGIN",
    },
    {
      key: "COMPLETED_ONBOARD_TRAINING",
      title: "COMPLETED_ONBOARD_TRAINING",
      label: "COMPLETED_ONBOARD_TRAINING",
      dbColName: "COMPLETED_ONBOARD_TRAINING",
    },
    {
      key: "PRESEASON_BLITZ_REGISTERED",
      title: "PRESEASON_BLITZ_REGISTERED",
      label: "PRESEASON_BLITZ_REGISTERED",
      dbColName: "PRESEASON_BLITZ_REGISTERED",
    },
    {
      key: "PRESEASON_BLITZ_ATTENDED",
      title: "PRESEASON_BLITZ_ATTENDED",
      label: "PRESEASON_BLITZ_ATTENDED",
      dbColName: "PRESEASON_BLITZ_ATTENDED",
    },
    {
      key: "LAST_SIGNED_CONTACT_DATE",
      title: "LAST_SIGNED_CONTACT_DATE",
      label: "LAST_SIGNED_CONTACT_DATE",
      dbColName: "LAST_SIGNED_CONTACT_DATE",
    },
    {
      key: "LIST_STARTED_100",
      title: "LIST_STARTED_100",
      label: "LIST_STARTED_100",
      dbColName: "LIST_STARTED_100",
    },
    {
      key: "LIST_COMPLETED_100",
      title: "LIST_COMPLETED_100",
      label: "LIST_COMPLETED_100",
      dbColName: "LIST_COMPLETED_100",
    },
    {
      key: "FIRST_INSTALL_DATE",
      title: "FIRST_INSTALL_DATE",
      label: "FIRST_INSTALL_DATE",
      dbColName: "FIRST_INSTALL_DATE",
    },
    {
      key: "LAST_INSTALL_DATE",
      title: "LAST_INSTALL_DATE",
      label: "LAST_INSTALL_DATE",
      dbColName: "LAST_INSTALL_DATE",
    },
    {
      key: "LAST_SOLAR_LEAD_DATE",
      title: "LAST_SOLAR_LEAD_DATE",
      label: "LAST_SOLAR_LEAD_DATE",
      dbColName: "LAST_SOLAR_LEAD_DATE",
    },
    {
      key: "LAST_SOLAR_CLOSED_DATE",
      title: "LAST_SOLAR_CLOSED_DATE",
      label: "LAST_SOLAR_CLOSED_DATE",
      dbColName: "LAST_SOLAR_CLOSED_DATE",
    },
    {
      key: "LAST_SOLAR_INSTALL_DATE",
      title: "LAST_SOLAR_INSTALL_DATE",
      label: "LAST_SOLAR_INSTALL_DATE",
      dbColName: "LAST_SOLAR_INSTALL_DATE",
    },
    {
      key: "LAST_RECRUIT_WITH_SALE_DATE",
      title: "LAST_RECRUIT_WITH_SALE_DATE",
      label: "LAST_RECRUIT_WITH_SALE_DATE",
      dbColName: "LAST_RECRUIT_WITH_SALE_DATE",
    },
    { key: "STAGE", title: "STAGE", label: "STAGE", dbColName: "STAGE" },
    { key: "FP", title: "FP", label: "FP", dbColName: "FP" },
    { key: "EFP", title: "EFP", label: "EFP", dbColName: "EFP" },
    {
      key: "CONTACTS",
      title: "CONTACTS",
      label: "CONTACTS",
      dbColName: "CONTACTS",
    },
    {
      key: "SIGNED_REPS",
      title: "SIGNED_REPS",
      label: "SIGNED_REPS",
      dbColName: "SIGNED_REPS",
    },
    { key: "RWS", title: "RWS", label: "RWS", dbColName: "RWS" },
    {
      key: "MAX_POSITION",
      title: "MAX_POSITION",
      label: "MAX_POSITION",
      dbColName: "MAX_POSITION",
    },
    { key: "TENURE", title: "TENURE", label: "TENURE", dbColName: "TENURE" },
    { key: "TEAM", title: "TEAM", label: "TEAM", dbColName: "TEAM" },
    {
      key: "MANAGEMENT_GROUP",
      title: "MANAGEMENT_GROUP",
      label: "MANAGEMENT_GROUP",
      dbColName: "MANAGEMENT_GROUP",
    },
    {
      key: "SENIOR_MANAGEMENT_GROUP",
      title: "SENIOR_MANAGEMENT_GROUP",
      label: "SENIOR_MANAGEMENT_GROUP",
      dbColName: "SENIOR_MANAGEMENT_GROUP",
    },
    { key: "REGION", title: "REGION", label: "REGION", dbColName: "REGION" },
    {
      key: "SENIOR_REGION",
      title: "SENIOR_REGION",
      label: "SENIOR_REGION",
      dbColName: "SENIOR_REGION",
    },
    {
      key: "PARTNERSHIP",
      title: "PARTNERSHIP",
      label: "PARTNERSHIP",
      dbColName: "PARTNERSHIP",
    },
    {
      key: "DIVISION",
      title: "DIVISION",
      label: "DIVISION",
      dbColName: "DIVISION",
    },
    {
      key: "RECRUITER_ID",
      title: "RECRUITER_ID",
      label: "RECRUITER_ID",
      dbColName: "RECRUITER_ID",
    },
    {
      key: "RECRUITER_NAME",
      title: "RECRUITER_NAME",
      label: "RECRUITER_NAME",
      dbColName: "RECRUITER_NAME",
    },
    {
      key: "RECRUITER_PHONE_NUMBER",
      title: "RECRUITER_PHONE_NUMBER",
      label: "RECRUITER_PHONE_NUMBER",
      dbColName: "RECRUITER_PHONE_NUMBER",
    },
    {
      key: "RECRUITER_EMAIL",
      title: "RECRUITER_EMAIL",
      label: "RECRUITER_EMAIL",
      dbColName: "RECRUITER_EMAIL",
    },
    {
      key: "TEAM_LEADER_ID",
      title: "TEAM_LEADER_ID",
      label: "TEAM_LEADER_ID",
      dbColName: "TEAM_LEADER_ID",
    },
    {
      key: "TEAM_LEADER_NAME",
      title: "TEAM_LEADER_NAME",
      label: "TEAM_LEADER_NAME",
      dbColName: "TEAM_LEADER_NAME",
    },
    {
      key: "TEAM_LEADER_PHONE_NUMBER",
      title: "TEAM_LEADER_PHONE_NUMBER",
      label: "TEAM_LEADER_PHONE_NUMBER",
      dbColName: "TEAM_LEADER_PHONE_NUMBER",
    },
    {
      key: "TEAM_LEADER_EMAIL",
      title: "TEAM_LEADER_EMAIL",
      label: "TEAM_LEADER_EMAIL",
      dbColName: "TEAM_LEADER_EMAIL",
    },
    {
      key: "MANAGEMENT_GROUP_LEADER_ID",
      title: "MANAGEMENT_GROUP_LEADER_ID",
      label: "MANAGEMENT_GROUP_LEADER_ID",
      dbColName: "MANAGEMENT_GROUP_LEADER_ID",
    },
    {
      key: "MANAGEMENT_GROUP_LEADER_NAME",
      title: "MANAGEMENT_GROUP_LEADER_NAME",
      label: "MANAGEMENT_GROUP_LEADER_NAME",
      dbColName: "MANAGEMENT_GROUP_LEADER_NAME",
    },
    {
      key: "MANAGEMENT_GROUP_LEADER_PHONE_NUMBER",
      title: "MANAGEMENT_GROUP_LEADER_PHONE_NUMBER",
      label: "MANAGEMENT_GROUP_LEADER_PHONE_NUMBER",
      dbColName: "MANAGEMENT_GROUP_LEADER_PHONE_NUMBER",
    },
    {
      key: "MANAGEMENT_GROUP_LEADER_EMAIL",
      title: "MANAGEMENT_GROUP_LEADER_EMAIL",
      label: "MANAGEMENT_GROUP_LEADER_EMAIL",
      dbColName: "MANAGEMENT_GROUP_LEADER_EMAIL",
    },
    {
      key: "SENIOR_MANAGEMENT_GROUP_LEADER_ID",
      title: "SENIOR_MANAGEMENT_GROUP_LEADER_ID",
      label: "SENIOR_MANAGEMENT_GROUP_LEADER_ID",
      dbColName: "SENIOR_MANAGEMENT_GROUP_LEADER_ID",
    },
    {
      key: "SENIOR_MANAGEMENT_GROUP_LEADER",
      title: "SENIOR_MANAGEMENT_GROUP_LEADER",
      label: "SENIOR_MANAGEMENT_GROUP_LEADER",
      dbColName: "SENIOR_MANAGEMENT_GROUP_LEADER",
    },
    {
      key: "SENIOR_MANAGEMENT_GROUP_LEADER_PHONE_NUMBER",
      title: "SENIOR_MANAGEMENT_GROUP_LEADER_PHONE_NUMBER",
      label: "SENIOR_MANAGEMENT_GROUP_LEADER_PHONE_NUMBER",
      dbColName: "SENIOR_MANAGEMENT_GROUP_LEADER_PHONE_NUMBER",
    },
    {
      key: "SENIOR_MANAGEMENT_GROUP_LEADER_EMAIL",
      title: "SENIOR_MANAGEMENT_GROUP_LEADER_EMAIL",
      label: "SENIOR_MANAGEMENT_GROUP_LEADER_EMAIL",
      dbColName: "SENIOR_MANAGEMENT_GROUP_LEADER_EMAIL",
    },
    {
      key: "REGION_LEADER_ID",
      title: "REGION_LEADER_ID",
      label: "REGION_LEADER_ID",
      dbColName: "REGION_LEADER_ID",
    },
    {
      key: "REGION_LEADER_NAME",
      title: "REGION_LEADER_NAME",
      label: "REGION_LEADER_NAME",
      dbColName: "REGION_LEADER_NAME",
    },
    {
      key: "REGION_LEADER_PHONE_NUMBER",
      title: "REGION_LEADER_PHONE_NUMBER",
      label: "REGION_LEADER_PHONE_NUMBER",
      dbColName: "REGION_LEADER_PHONE_NUMBER",
    },
    {
      key: "REGION_LEADER_EMAIL",
      title: "REGION_LEADER_EMAIL",
      label: "REGION_LEADER_EMAIL",
      dbColName: "REGION_LEADER_EMAIL",
    },
    {
      key: "SENIOR_REGION_ID",
      title: "SENIOR_REGION_ID",
      label: "SENIOR_REGION_ID",
      dbColName: "SENIOR_REGION_ID",
    },
    {
      key: "SENIOR_REGION_NAME",
      title: "SENIOR_REGION_NAME",
      label: "SENIOR_REGION_NAME",
      dbColName: "SENIOR_REGION_NAME",
    },
    {
      key: "SENIOR_REGION_LEADER_PHONE_NUMBER",
      title: "SENIOR_REGION_LEADER_PHONE_NUMBER",
      label: "SENIOR_REGION_LEADER_PHONE_NUMBER",
      dbColName: "SENIOR_REGION_LEADER_PHONE_NUMBER",
    },
    {
      key: "SENIOR_REGION_LEADER_EMAIL",
      title: "SENIOR_REGION_LEADER_EMAIL",
      label: "SENIOR_REGION_LEADER_EMAIL",
      dbColName: "SENIOR_REGION_LEADER_EMAIL",
    },
    {
      key: "PARTNER_LEADER_ID",
      title: "PARTNER_LEADER_ID",
      label: "PARTNER_LEADER_ID",
      dbColName: "PARTNER_LEADER_ID",
    },
    {
      key: "PARTNER_LEADER_NAME",
      title: "PARTNER_LEADER_NAME",
      label: "PARTNER_LEADER_NAME",
      dbColName: "PARTNER_LEADER_NAME",
    },
    {
      key: "PARTNER_LEADER_PHONE_NUMBER",
      title: "PARTNER_LEADER_PHONE_NUMBER",
      label: "PARTNER_LEADER_PHONE_NUMBER",
      dbColName: "PARTNER_LEADER_PHONE_NUMBER",
    },
    {
      key: "DIVISION_LEADER_ID",
      title: "DIVISION_LEADER_ID",
      label: "DIVISION_LEADER_ID",
      dbColName: "DIVISION_LEADER_ID",
    },
    {
      key: "DIVISION_LEADER_NAME",
      title: "DIVISION_LEADER_NAME",
      label: "DIVISION_LEADER_NAME",
      dbColName: "DIVISION_LEADER_NAME",
    },
    {
      key: "DIVISION_LEADER_PHONE_NUMBER",
      title: "DIVISION_LEADER_PHONE_NUMBER",
      label: "DIVISION_LEADER_PHONE_NUMBER",
      dbColName: "DIVISION_LEADER_PHONE_NUMBER",
    },
    {
      key: "CONTACT_LIST_TRANSFER",
      title: "CONTACT_LIST_TRANSFER",
      label: "CONTACT_LIST_TRANSFER",
      dbColName: "CONTACT_LIST_TRANSFER",
    },
  ];

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    limit: 100,
  });

  useEffect(() => {
    setLoading(true);
    service
      .getInfoSessions(pagination)
      .then((resp) => {
        setData(resp.data.data);
        setTotalItems(resp.data.total_docs);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [pagination]);

  return (
    <Table
      data={data}
      loadingData={loading}
      setPagination={setPagination}
      pagination={pagination}
      totalItems={totalItems}
      columns={columns}
    />
  );
};
