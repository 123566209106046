import { Table } from "common/Table/Table";
import { Fragment, useState } from "react";
import UserService from "services/user.service";
import swal from "sweetalert";
import { useNotifications } from "hooks/useNotification";
import { AddLocationModal } from "components/AddLocationModal";
import { useLocation } from "hooks/useLocations";

const service = new UserService();

export const INITIAL_STATE = {
  office_name: "",
  office_address: "",
  city: "",
  state: "",
  zip_code: "",
  leader_name: "",
  leader_id: "",
  leader_phone_number: "",
  leader_email: "",
  shipping_address: "",
  senior_regional_name: "",
  senior_regional_id: "",
  senior_regional_phone: "",
  senior_regional_email: "",
};

export const RecruiterLocations = () => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [editing, setEditing] = useState(false);
  const [state, setState] = useState(INITIAL_STATE);

  const { successMessage } = useNotifications();

  const columns = [
    { key: "office_name", dbColName: "office_name", title: "office_name",
      freeze: true,
      freezeIndex: 'left-0'
     },
    {
      key: "office_address",
      dbColName: "office_address",
      title: "office_address",
      freeze: true,
      freezeIndex: 'left-32'
    },
    { key: "city", dbColName: "city", title: "city" },
    { key: "state", dbColName: "state", title: "state" },
    { key: "zip_code", dbColName: "zip_code", title: "zip_code" },
    { key: "leader_name", dbColName: "leader_name", title: "leader_name" },
    { key: "leader_id", dbColName: "leader_id", title: "leader_id" },
    {
      key: "leader_phone_number",
      dbColName: "leader_phone_number",
      title: "leader_phone_number",
    },
    { key: "leader_email", dbColName: "leader_email", title: "leader_email" },
    {
      key: "shipping_address",
      dbColName: "shipping_address",
      title: "shipping_address",
    },
    {
      key: "senior_regional_name",
      dbColName: "senior_regional_name",
      title: "senior_regional_name",
    },
    {
      key: "location_type",
      dbColName: "location_type",
      title: "location_type",
    },
    {
      key: "actions",
      title: "actions",
      render: (values) => {
        return (
          <div className="flex gap-3">
            <button
              onClick={() => handleDelete(values._id)}
              className="text-primary"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                />
              </svg>
            </button>
            <button
              onClick={() => {
                setEditing(true);
                setShowModal(true);
                setState({
                  ...state,
                  ...values,
                  location_id: values._id,
                });
              }}
              className="text-blue-500"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                />
              </svg>
            </button>
          </div>
        );
      },
    },
  ];

  const { locations, totalItems, setLocations } = useLocation();

  const handleDelete = (id: string) => {
    swal({
      title: "Are you sure, you want to delete this location?",
      icon: "warning",
      className: "w-[500px]",
      buttons: {
        cancel: {
          text: "Cancel",
          visible: true,
        },
        confirm: {
          text: "Delete",
          className: "bg-primary text-white",
        },
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setLoading(true);
        service.deleteLocation(id).then((resp) => {
          if (resp.data.success) {
            successMessage("Location deleted successfully");
            const shallowCopy = [...locations];
            const newData = shallowCopy.filter((item) => item._id !== id);
            setLocations(newData);
            setLoading(false);
          }
        });
      }
    });
  };

  return (
    <Fragment>
      <div className="flex flex-col mb-4">
        <div className="flex justify-between items-center ">
          <div>
            <h1 className="font-medium text-gray-700 tracking-wide text-xl">
              Recruiter Center Locations
            </h1>
            <p className="text-gray-500 text-sm">View all locations</p>
          </div>
          <button
            onClick={() => setShowModal(true)}
            className="bg-black drop-shadow-md text-white rounded-md p-2.5 text-sm flex items-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 6v12m6-6H6"
              />
            </svg>
            Add Location
          </button>
        </div>
      </div>
      <Table
        data={locations}
        loadingData={loading}
        setData={setLocations}
        totalItems={totalItems}
        columns={columns}
        exportButton={false}
      />
      <AddLocationModal
        state={state}
        setState={setState}
        data={locations}
        editing={editing}
        setEditing={setEditing}
        setData={setLocations}
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </Fragment>
  );
};
