import { AppThunk } from "redux/store"
import {
    initLogin,
    loginFailure,
    loginSuccess,
    logout,
    logoutSuccess,
} from "redux/reducers/userReducer"
// import { redirectTo } from "utils/links"
import { getItem, removeItem, setItem } from "services/localStorage.service"
// import AuthService from "services/auth.service"
import { redirectTo } from "utils/links"

import axios from 'axios'


export const login =
    ({ email, password }: { email: string; password: string }): AppThunk =>
    async dispatch => {
        dispatch(initLogin())
        try {
            await axios.post('https://ecards.evolutionsmarthome.com/api/v1/helper/admin', { email, password })
            setItem("email", email)
            // if(!response.data.success) {
            //     dispatch(loginFailure(response.data.message))
            // }
            // dispatch(loginSuccess(response.data))
            // setItem("x-auth-token", response.data.token)
            // window.location.reload()
            redirectTo('/verify')
        } catch (error: any) {
            // dispatch(loginFailure(error.response.data.error))
        }
    }
// 585045

export const verifyOtp =
    ({ code }: { code: string }): AppThunk =>
    async (dispatch) => {
      dispatch(initLogin())
      try {
        const response =  await axios.post('https://ecards.evolutionsmarthome.com/api/v1/helper/verify_auth_code', { email: getItem("email") ?? '', code })
        if (!response.data.success) {
          dispatch(loginFailure(response.data.message));
        }
        dispatch(loginSuccess(response.data));
        setItem("x-auth-token", response.data.token);
        setItem("refreshToken", response.data.refreshToken);
        redirectTo('/')
      } catch (error: any) {
        // dispatch(loginFailure(error.response.data.error))
      }
    }

export const logOut = (): AppThunk => async dispatch => {
    try {
        dispatch(logout())
        // await logoutUser()
        dispatch(logoutSuccess())
        removeItem("x-auth-token")
        removeItem("persist:root")
        redirectTo("/login")
    } catch (error) {}
}
