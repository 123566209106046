export const CANDIDATE_DETAILS = [
  "ORIGINATION DATE",
  "CANDIDATE TYPE",
  "FIRST NAME",
  "LAST NAME",
  "BIRTHDAY",
];

export const SOURCE_DETAILS = [
  "SOURCE CATEGORY",
  "SOURCE NOTE",
  "SOURCE ID",
  "CAMPAIGN ID",
  "RECRUITING LOCATION",
];

export const HOME_DETAILS = [
  "HOME COUNTRY",
  "HOME ADDRESS",
  "HOME CITY",
  "HOME STATE",
  "HOME ZIP CODE",
  "HOME TIMEZONE",
];

export const INTERVIEW = [
  "Scheduled",
  "Rescheduled",
  "Completed",
  "No-show",
  "Cancelled",
];

export const TIMEZONES = [
  "America/Detroit",
  "America/Indiana/Indianapolis",
  "America/Indiana/Marengo",
  "America/Indiana/Petersburg",
  "America/Indiana/Vevay",
  "America/Indiana/Vincennes",
  "America/Indiana/Winamac",
  "America/Kentucky/Louisville",
  "America/Kentucky/Monticello",
  "America/New_York",
  "America/Chicago",
  "America/Indiana/Knox",
  "America/Indiana/Tell_City",
  "America/Menominee",
  "America/North_Dakota/Beulah",
  "America/North_Dakota/Center",
  "America/North_Dakota/New_Salem",
  "America/Boise",
  "America/Denver",
  "America/Phoenix",
  "America/Los_Angeles",
  "America/Anchorage",
  "America/Juneau",
  "America/Metlakatla",
  "America/Nome",
  "America/Sitka",
  "America/Yakutat",
  "America/Adak",
  "Pacific/Honolulu",
];

export const CANDIDATE_STAGES = {
  Bootcamp: "/Bootcamp.png",
  "New Candidate": "/New Candidate.png",
  INTERVIEW: "/Interview.png",
  Onboarding: "Onboarding",
  "Lead Closed": "/Lead Closed.png",
  "Lead Set": "/Lead Set.png",
  "Screening": "/Screening.png",
};
