import { Link } from "react-router-dom";
import { Dashboard2Layout } from "components/dashboard2/Dashboard2Layout";
import { useEffect, useState } from "react";
import UserService from "services/user.service";

const service = new UserService();

export const Stats = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([]);

  useEffect(() => {
    setLoading(true)
    service
      .getOffices()
      .then((resp) => {
        setData(resp.data.data);
        setLoading(false)
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <Dashboard2Layout>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-7 p-5">
        {data?.length === 0 && !loading && <div>No Data Found</div> }
        {loading && <p>Loading..</p>}
        {data.map((item, index) => (
          <Link
            key={index}
            to={`/dashboard2/stats/${item}`}
            className="bg-white rounded-full w-full p-4 flex items-center transform transition duration-500 hover:scale-110 cursor-pointer"
          >
            <div className="bg-orange-300 rounded-full h-9 w-9" />
            <p className="text-center text-gray-800 w-full text-3xl font-semibold">
              {item}
            </p>
          </Link>
        ))}
      </div>
    </Dashboard2Layout>
  );
}
