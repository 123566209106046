import { useSelector } from "react-redux"
import {
    authSelector,
    userSelector,
} from "redux/selectors/user"

const useAccount = () => {
    const user = useSelector(userSelector)
    const {isLoggedIn} = useSelector(authSelector)

    return { user, isLoggedIn }
}

export default useAccount
