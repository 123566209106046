import { Table } from "common/Table/Table";
import { useDebounce } from "hooks/useDebounce";
import { Fragment, useCallback, useEffect, useState } from "react";
import UserService from "services/user.service";
import { exportToCSV } from "utils/funcs";

const service = new UserService();

export const SignedReps = () => {
  const columns = [
    { key: "rep_id", dbColName: "rep_id", title: "rep id", freeze: true, freezeIndex: 'left-0' },
    { key: "rep_name", dbColName: "rep_name", title: "rep name", freeze: true, freezeIndex: 'left-20' },
   
    { key: "phone", dbColName: "phone", title: "phone" },
    { key: "email", dbColName: "email", title: "email" },
    { key: "max_position", dbColName: "max_position", title: "max position" },
    { key: "tenure", dbColName: "tenure", title: "tenure" },
    { key: "region", dbColName: "region", title: "region" },
    {
      key: "rep_first_name",
      dbColName: "rep_first_name",
      title: "rep first name",
    },
    {
      key: "rep_last_name",
      dbColName: "rep_last_name",
      title: "rep last name",
    },
    {
      key: "management_group",
      dbColName: "management_group",
      title: "management group",
    },
    {
      key: "fp_preseason_previous_year",
      dbColName: "fp_preseason_previous_year",
      title: "fp_preseason_previous_year",
    },
    {
      key: "fp_summer_previous_year",
      dbColName: "fp_summer_previous_year",
      title: "fp_summer_previous_year",
    },
    {
      key: "fp_extended_previous_year",
      dbColName: "fp_extended_previous_year",
      title: "fp_extended_previous_year",
    },
    {
      key: "fp_ytd_previous_year",
      dbColName: "fp_ytd_previous_year",
      title: "fp_ytd_previous_year",
    },
    {
      key: "fp_total_previous_year",
      dbColName: "fp_total_previous_year",
      title: "fp_total_previous_year",
    },
    {
      key: "prmr_preseason_previous_year",
      dbColName: "prmr_preseason_previous_year",
      title: "prmr_preseason_previous_year",
    },
    {
      key: "prmr_summer_previous_year",
      dbColName: "prmr_summer_previous_year",
      title: "prmr_summer_previous_year",
    },
    {
      key: "prmr_extended_previous_year",
      dbColName: "prmr_extended_previous_year",
      title: "prmr_extended_previous_year",
    },
    {
      key: "prmr_ytd_previous_year",
      dbColName: "prmr_ytd_previous_year",
      title: "prmr_ytd_previous_year",
    },
    {
      key: "prmr_total_previous_year",
      dbColName: "prmr_total_previous_year",
      title: "prmr_total_previous_year",
    },
    {
      key: "upgrades_preseason_previous_year",
      dbColName: "upgrades_preseason_previous_year",
      title: "upgrades_preseason_previous_year",
    },
    {
      key: "upgrades_summer_previous_year",
      dbColName: "upgrades_summer_previous_year",
      title: "upgrades_summer_previous_year",
    },
    {
      key: "upgrades_extended_previous_year",
      dbColName: "upgrades_extended_previous_year",
      title: "upgrades_extended_previous_year",
    },
    {
      key: "fp_preseason_",
      dbColName: "fp_preseason_",
      title: "fp_preseason_",
    },
    { key: "fp_summer_", dbColName: "fp_summer_", title: "fp_summer_" },
    { key: "fp_extended_", dbColName: "fp_extended_", title: "fp_extended_" },
    { key: "fp_ytd_", dbColName: "fp_ytd_", title: "fp_ytd_" },
    {
      key: "prmr_preseason_",
      dbColName: "prmr_preseason_",
      title: "prmr_preseason_",
    },
    { key: "prmr_summer_", dbColName: "prmr_summer_", title: "prmr_summer_" },
    {
      key: "prmr_extended_",
      dbColName: "prmr_extended_",
      title: "prmr_extended_",
    },
    { key: "prmr_ytd_", dbColName: "prmr_ytd_", title: "prmr_ytd_" },
    {
      key: "upgrades_preseason_",
      dbColName: "upgrades_preseason_",
      title: "upgrades_preseason_",
    },
    {
      key: "upgrades_summer_",
      dbColName: "upgrades_summer_",
      title: "upgrades_summer_",
    },
    {
      key: "upgrades_extended_",
      dbColName: "upgrades_extended_",
      title: "upgrades_extended_",
    },
    {
      key: "upgrades_ytd_",
      dbColName: "upgrades_ytd_",
      title: "upgrades_ytd_",
    },
    {
      key: "tenure_number",
      dbColName: "tenure_number",
      title: "tenure_number",
    },
    {
      key: "days_since_last_genie_login",
      dbColName: "days_since_last_genie_login",
      title: "days_since_last_genie_login",
    },
    {
      key: "last_genie_login_date_",
      dbColName: "last_genie_login_date_",
      title: "last_genie_login_date_",
    },
    {
      key: "days_since_last_install",
      dbColName: "days_since_last_install",
      title: "days_since_last_install",
    },
    {
      key: "last_install_date_",
      dbColName: "last_install_date_",
      title: "last_install_date_",
    },
    {
      key: "days_since_last_credit",
      dbColName: "days_since_last_credit",
      title: "days_since_last_credit",
    },
    {
      key: "last_credit_run_date_",
      dbColName: "last_credit_run_date_",
      title: "last_credit_run_date_",
    },
    {
      key: "agreement_sent_date",
      dbColName: "agreement_sent_date",
      title: "agreement_sent_date",
    },
    {
      key: "agreement_signed_date",
      dbColName: "agreement_signed_date",
      title: "agreement_signed_date",
    },
    {
      key: "onboarded_date",
      dbColName: "onboarded_date",
      title: "onboarded_date",
    },
    {
      key: "completed_sales_training_date",
      dbColName: "completed_sales_training_date",
      title: "completed_sales_training_date",
    },
    { key: "team", dbColName: "team", title: "team" },

    {
      key: "senior_management_group",
      dbColName: "senior_management_group",
      title: "senior_management_group",
    },
    {
      key: "senior_region",
      dbColName: "senior_region",
      title: "senior_region",
    },
    { key: "partnership", dbColName: "partnership", title: "partnership" },
    { key: "division", dbColName: "division", title: "division" },
    { key: "recruiter_id", dbColName: "recruiter_id", title: "recruiter_id" },
    {
      key: "recruiter_name",
      dbColName: "recruiter_name",
      title: "recruiter_name",
    },
    {
      key: "recruiter_phone_number",
      dbColName: "recruiter_phone_number",
      title: "recruiter_phone_number",
    },
    {
      key: "recruiter_email",
      dbColName: "recruiter_email",
      title: "recruiter_email",
    },
    {
      key: "team_leader_id",
      dbColName: "team_leader_id",
      title: "team_leader_id",
    },
    {
      key: "team_leader_name",
      dbColName: "team_leader_name",
      title: "team_leader_name",
    },
    {
      key: "team_leader_phone_number",
      dbColName: "team_leader_phone_number",
      title: "team_leader_phone_number",
    },
    {
      key: "team_leader_email",
      dbColName: "team_leader_email",
      title: "team_leader_email",
    },
    {
      key: "management_group_leader_id",
      dbColName: "management_group_leader_id",
      title: "management_group_leader_id",
    },
    {
      key: "management_group_leader_name",
      dbColName: "management_group_leader_name",
      title: "management_group_leader_name",
    },
    {
      key: "management_group_leader_phone_number",
      dbColName: "management_group_leader_phone_number",
      title: "management_group_leader_phone_number",
    },
    {
      key: "management_group_leader_email",
      dbColName: "management_group_leader_email",
      title: "management_group_leader_email",
    },
    {
      key: "senior_management_group_leader_id",
      dbColName: "senior_management_group_leader_id",
      title: "senior_management_group_leader_id",
    },
    {
      key: "senior_management_group_leader",
      dbColName: "senior_management_group_leader",
      title: "senior_management_group_leader",
    },
    {
      key: "senior_management_group_leader_phone_number",
      dbColName: "senior_management_group_leader_phone_number",
      title: "senior_management_group_leader_phone_number",
    },
    {
      key: "senior_management_group_leader_email",
      dbColName: "senior_management_group_leader_email",
      title: "senior_management_group_leader_email",
    },
    {
      key: "region_leader_id",
      dbColName: "region_leader_id",
      title: "region_leader_id",
    },
    {
      key: "region_leader_name",
      dbColName: "region_leader_name",
      title: "region_leader_name",
    },
    {
      key: "region_leader_phone_number",
      dbColName: "region_leader_phone_number",
      title: "region_leader_phone_number",
    },
    {
      key: "region_leader_email",
      dbColName: "region_leader_email",
      title: "region_leader_email",
    },
    {
      key: "senior_region_id",
      dbColName: "senior_region_id",
      title: "senior_region_id",
    },
    {
      key: "senior_region_name",
      dbColName: "senior_region_name",
      title: "senior_region_name",
    },
    {
      key: "senior_region_leader_phone_number",
      dbColName: "senior_region_leader_phone_number",
      title: "senior_region_leader_phone_number",
    },
    {
      key: "senior_region_leader_email",
      dbColName: "senior_region_leader_email",
      title: "senior_region_leader_email",
    },
    {
      key: "pertner_leader_id",
      dbColName: "pertner_leader_id",
      title: "pertner_leader_id",
    },
    {
      key: "partner_leader_name",
      dbColName: "partner_leader_name",
      title: "partner_leader_name",
    },
    {
      key: "partner_leader_phone_number",
      dbColName: "partner_leader_phone_number",
      title: "partner_leader_phone_number",
    },
    {
      key: "partner_leader_email",
      dbColName: "partner_leader_email",
      title: "partner_leader_email",
    },
    {
      key: "last_solar_lead_created",
      dbColName: "last_solar_lead_created",
      title: "last_solar_lead_created",
    },
    {
      key: "last_solar_lead_closed",
      dbColName: "last_solar_lead_closed",
      title: "last_solar_lead_closed",
    },
    {
      key: "last_solar_lead_installed",
      dbColName: "last_solar_lead_installed",
      title: "last_solar_lead_installed",
    },
    {
      key: "last_solar_lead_funded",
      dbColName: "last_solar_lead_funded",
      title: "last_solar_lead_funded",
    },
    { key: "year", dbColName: "year", title: "year" },
  ];

  const [data, setData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 500,
    year: 2023,
    repName: "",
    // agreement_signed_date: "",
    onboarded_date: "",
    managementGroup: "",
    region: "",
    email: "",
    maxPosition: ""
  });

  const debounced = useDebounce(pagination, 500);

  const fetchData = useCallback(() => {
    setLoading(true);
    service
      .getSignedReps(debounced)
      .then((resp) => {
        const respData = resp.data.data;
        setData(
          respData.map((item) => ({
            ...item,
            "Completed Sales Training Date": new Date(
              item["Completed Sales Training Date"]
            ).toLocaleString(),
            "Onboarded Date": new Date(item["Onboarded Date"]).toLocaleString(),
            "Agreement Signed Date": new Date(
              item["Agreement Signed Date"]
            ).toLocaleString(),
            "Agreement Sent Date": new Date(
              item["Agreement Sent Date"]
            ).toLocaleString(),
            "Last Credit Run (DATE)": new Date(
              item["Last Credit Run (DATE)"]
            ).toLocaleString(),
            "Last Install (DATE)": new Date(
              item["Last Install (DATE)"]
            ).toLocaleString(),
            "Last Genie Login (DATE)": new Date(
              item["Last Genie Login (DATE)"]
            ).toLocaleString(),
          }))
        );
        setTotalItems(resp.data.total);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [debounced]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleExport = () => {
    setExporting(true);
    service
      .downloadSignedReps(pagination)
      .then((resp) => {
        exportToCSV(
          resp.data.data.map((item) => ({ ...item._source })),
          "reps"
        );
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setExporting(false);
      });
  };

  return (
    <Fragment>
      <div className="flex flex-col mb-4">
        <h1 className="font-medium text-gray-700 tracking-wide text-xl">
          Signed Reps
        </h1>
        <p className="text-gray-500 text-sm">View all</p>
      </div>
      <Table
        data={data}
        handleExport={handleExport}
        pagination={pagination}
        setData={setData}
        exporting={exporting}
        setPagination={setPagination}
        loadingData={loading}
        filters={
          <Filters pagination={pagination} setPagination={setPagination} />
        }
        columnFilters={
          <ColumnFilters
            pagination={pagination}
            setPagination={setPagination}
          />
        }
        totalItems={totalItems}
        columns={columns}
      />
    </Fragment>
  );
};

const ColumnFilters = ({ pagination, setPagination }) => {
  return (
    <div className="flex gap-4 mt-3">
      <input
        className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        type="text"
        placeholder="Search by Rep Name"
        onChange={(e) => {
          setPagination({
            ...pagination,
            repName: e.target.value,
          });
        }}
      />
       <input
        className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        type="text"
        placeholder="Search by Email"
        onChange={(e) => {
          setPagination({
            ...pagination,
            email: e.target.value,
          });
        }}
      />
   
      {/* <input
        className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        type="date"
        onChange={(e) =>
          setPagination({
            ...pagination,
            agreement_signed_date: e.target.value,
          })
        }
      /> */}
      <input
        className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        type="text"
        placeholder="Region"
        onChange={(e) => {
          setPagination({
            ...pagination,
            region: e.target.value,
          });
        }}
      />
      <input
        placeholder="Management group"
        className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        type="text"
        onChange={(e) => {
          setPagination({
            ...pagination,
            managementGroup: e.target.value,
          });
        }}
      />
         <select
        value={pagination.maxPosition}
        onChange={(e) =>
          setPagination({ ...pagination, maxPosition: e.target.value })
        }
        className="text-gray-800 rounded-md w-full border-gray-200 focus:border-gray-300 focus:ring-0 focus:outline-none text-sm"
      >
        <option value="" selected disabled>Max Position</option>
        <option value="Rep">Rep</option>
        <option value="Team Lead">Team Lead</option>
        <option value="Manager">Manager</option>
        <option value="Senior Manager">Senior Manager</option>
        <option value="Regional">Regional</option>
        <option value="Senior Regional">Senior Regional</option>
        <option value="Partner">Partner</option>
      </select>
      {/* <input
        className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        type="date"
        onChange={(e) =>
          setPagination({
            ...pagination,
            onboarded_date: e.target.value,
          })
        }
      /> */}
    </div>
  );
};

export const Filters = ({ pagination, setPagination }: any) => {
  return (
    <div className="flex items-center gap-2">
      <label className="text-sm text-gray-800">Filter by: </label>
      <select
        onChange={(eve) =>
          setPagination({
            ...pagination,
            year: eve.target.value,
          })
        }
        defaultValue={pagination.year}
        className="rounded-md border-gray-200 focus:border-gray-300 focus:ring-0 focus:outline-none text-sm"
      >
        <option>Year</option>
        <option>1999</option>
        <option>2000</option>
        <option>2001</option>
        <option>2002</option>
        <option>2003</option>
        <option>2004</option>
        <option>2005</option>
        <option>2006</option>
        <option>2007</option>
        <option>2008</option>
        <option>2009</option>
        <option>2010</option>
        <option>2011</option>
        <option>2012</option>
        <option>2013</option>
        <option>2015</option>
        <option>2016</option>
        <option>2017</option>
        <option>2018</option>
        <option>2019</option>
        <option>2020</option>
        <option>2021</option>
        <option>2022</option>
        <option>2023</option>
      </select>
    </div>
  );
};
