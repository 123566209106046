import classNames from "classnames";
import { CollegeCampuses } from "components/GeneralData/CollegeCampuses";
import { ZipCodes } from "components/GeneralData/ZipCodes";
import { Fragment, useState } from "react";

// import DatePicker from "react-datepicker";

export const GeneralData = () => {
  const [tab, setTab] = useState("College Campuses");

  return (
    <Fragment>
      <div className="flex flex-col mb-4">
        <h1 className="font-medium text-gray-800 tracking-wide text-xl">
          {tab}
        </h1>
        <p className="text-gray-500 text-sm">View all {tab}</p>
      </div>

      {/* Tables */}
      {tab === "College Campuses" ? <CollegeCampuses /> : <ZipCodes />}

      {/* Tabs */}
      <div className="text-sm mt-4 p-2 text-center text-gray-500 rounded-md bg-gray-200">
        <div className="flex flex-wrap -mb-px gap-3">
          <button
            type="button"
            onClick={() => setTab("College Campuses")}
            className={classNames(tabStyles.default, {
              [tabStyles.active]: tab === "College Campuses",
            })}
          >
            College Campuses
          </button>
          <button
            type="button"
            onClick={() => setTab("Zip Codes")}
            className={classNames(tabStyles.default, {
              [tabStyles.active]: tab === "Zip Codes",
            })}
          >
            Zip Codes
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export const tabStyles = {
  default:
    "px-5 py-3 flex-1 hover:text-gray-700 hover:drop-shadow-md transition ease-in-out hover:bg-gray-50 hover:rounded-md",
  active: "font-medium text-gray-700 drop-shadow-md bg-gray-50 rounded-md",
  select:
    "focus:border-gray-300 focus:ring-0 focus:outline-none rounded-md border border-gray-300 text-gray-700 text-xs",
};
