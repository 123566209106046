import axios from "axios";

import { setAuthorizationHeader } from "utils/auth";
import { getItem, removeItem } from "services/localStorage.service";
import { redirectTo } from "./links";

const API_DOMAIN = process.env.REACT_APP_API_DOMAIN || "";

const axiosInstance = axios.create({
  baseURL: API_DOMAIN,
});

axiosInstance.interceptors.response.use(
  (response) => {
    const token = response.headers["x-auth-token"];
    if (token) {
      setAuthorizationHeader(token);
    }
    return response;
  },
  (error) => {
    // const status = error?.response?.status;
    // const message = error?.response?.data;
    // if (message === "Invalid token." || status === 401) {
    //   removeItem("persist:root");
    //   redirectTo("/login");
    //   removeItem("token");
    // }

    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response, // Return the response if successful
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      // Try refreshing the access token
      try {
        const refreshToken = localStorage.getItem("refreshToken"); // Get the refresh token
        const token = localStorage.getItem("x-auth-token"); // Get the refresh token
        const response = await axios.post(
          "https://ecards.evolutionsmarthome.com/api/v1/dashboard/refreshtoken",
          {
            refreshToken,
            token
          }
        );

        // Save the new access token
        const newAccessToken = response.data.data.token;
        localStorage.setItem("x-auth-token", newAccessToken);
        localStorage.setItem("refreshToken", response.data.data.refreshToken);

        // Update the Authorization header and retry the original request
        axiosInstance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${newAccessToken}`;
        originalRequest.headers["x-auth-token"] = `Bearer ${newAccessToken}`;
        axiosInstance.defaults.headers["x-auth-token"] = newAccessToken;

        // Retry the original request with the new token
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        // Handle refresh token failure (e.g., logout user, clear tokens)
        console.error("Refresh token expired or invalid", refreshError);
        // localStorage.removeItem("x-auth-token");
        // localStorage.removeItem("refreshToken");
        // Redirect to login page or handle unauthorized access
        redirectTo("/login");
      }
    }

    // Return any other errors
    return Promise.reject(error);
  }
);

export default axiosInstance;

// set token from localStorage when application starts
setAuthorizationHeader(getItem("x-auth-token"));
