import React from 'react'

export const EditableCell = ({ value, onBlur }) => {
    return (
        <div
          onBlur={(event) => {
            if (event.target.innerText === value) {
              return
            } else {
              onBlur(event.target.innerText)
            }
          }}
          className="italic p-2 rounded-md border focus:outline-none ring-0  border-gray-300"
          contentEditable
          suppressContentEditableWarning
        >
          {value}
        </div>
    )
}