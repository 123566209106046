export const CandidateStage = ({ stage, firstName, lastName }) => {
  const firstInitial = firstName?.trim().charAt(0).toUpperCase();
  const lastInitial = lastName?.trim().charAt(0).toUpperCase();
  const initials = `${firstInitial}${lastInitial}`;

  const image = () => {
    switch (stage) {
      case 'NEW CANDIDATE':
       return '/new.png';
      case 'FOLLOW UP':
        return '';
      case 'OUTREACH':
        return "/outreach.png";
      case 'SCREENING':
        return "/Screening.png";
      case 'INTERVIEW':
        return "/Interview.png";
      case 'ONBOARDING':
        return "/Onboarding.png";
      case 'SALES LEADER ASSIGNMENT':
        return '/Sales Leader Assignment.png';
      case 'SALES LEADER INTERVIEW':
        return '/sales_leader_interview.png';
      case 'BOOTCAMP':
        return '';
      case 'LEAD SET':
        return '/Lead Set.png';
      case 'LEAD CLOSED':
        return "/Lead Closed.png";
      default:
        return '/new.png';
    }
  }

  return (
    <div className="flex items-center justify-center gap-4">
      <div className="h-12 w-12 relative">
        <img alt="stage" src={image()} className=" w-full h-full object-cover" />
        <p className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          {initials !== 'undefinedundefined' ? initials : '00'}
        </p>
      </div>
    </div>
  );
};
