import { Modal } from "common/Modal";
import { useNotifications } from "hooks/useNotification";
import { INITIAL_STATE } from "pages/RecruiterLocations";
import { useState } from "react";
import UserService from "services/user.service";

const service = new UserService();

export const AddLocationModal = ({ state, setState, showModal, setShowModal, editing, setEditing, data, setData }) => {
  const [saving, setSaving] = useState(false);

  const { successMessage } = useNotifications();

  const handleChange = (e) => {
    const { name, value } = e.target;

    setState({
      ...state,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setSaving(true);

    if(editing) {
      service
      .updateLocation(state)
      .then(() => {
        successMessage("Location Updated successfully")
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setSaving(false);
        setShowModal(false)
        setEditing(false)
        setState(INITIAL_STATE)
      });
    } else {
      service
      .addLocation(state)
      .then(() => {
        const newData = [...data]
        newData.push(state)
        setData(newData)
        successMessage("Location added successfully")
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setSaving(false);
        setShowModal(false)
        setState(INITIAL_STATE)
      });
    }
    
  };

  return (
    <Modal
      width="w-full md:w-1/2"
      showModal={showModal}
      setIsEditing={setEditing}
      setShowModal={setShowModal}
      title={editing ? "Update Location" : "Add New Location"}
    >
      <form onSubmit={handleSubmit} className="flex flex-col gap-4">
        <div className="grid grid-cols-2 gap-4">
          <input
            type="text"
            placeholder="Office Name"
            name="office_name"
            value={state.office_name}
            onChange={(e) => handleChange(e)}
            className={styles.input}
          />
          <input
            type="text"
            name="office_address"
            placeholder="Office Address"
            value={state.office_address}
            onChange={(e) => handleChange(e)}
            className={styles.input}
          />
          <input
            type="text"
            name="city"
            placeholder="City"
            value={state.city}
            onChange={(e) => handleChange(e)}
            className={styles.input}
          />
          <input
            type="text"
            placeholder="State"
            value={state.state}
            name="state"
            onChange={(e) => handleChange(e)}
            className={styles.input}
          />
          <input
            type="text"
            placeholder="Zip Code"
            name="zip_code"
            value={state.zip_code}
            onChange={(e) => handleChange(e)}
            className={styles.input}
          />
          <input
            type="text"
            placeholder="Leader ID"
            name="leader_id"
            value={state.leader_id}
            onChange={(e) => handleChange(e)}
            className={styles.input}
          />
          <input
            type="text"
            placeholder="Leader Name"
            name="leader_name"
            value={state.leader_name}
            onChange={(e) => handleChange(e)}
            className={styles.input}
          />
          <input
            type="text"
            placeholder="Leader Phone Number"
            name="leader_phone_number"
            value={state.leader_phone_number}
            onChange={(e) => handleChange(e)}
            className={styles.input}
          />
          <input
            type="email"
            placeholder="Leader Email"
            name="leader_email"
            value={state.leader_email}
            onChange={(e) => handleChange(e)}
            className={styles.input}
          />
          <input
            type="text"
            placeholder="Shipping Address"
            name="shipping_address"
            value={state.shipping_address}
            onChange={(e) => handleChange(e)}
            className={styles.input}
          />
          <input
            type="text"
            placeholder="Senior Regional ID"
            name="senior_regional_id"
            value={state.senior_regional_id}
            onChange={(e) => handleChange(e)}
            className={styles.input}
          />
          <input
            type="text"
            placeholder="Senior Regional Name"
            name="senior_regional_name"
            value={state.senior_regional_name}
            onChange={(e) => handleChange(e)}
            className={styles.input}
          />
          <input
            type="text"
            placeholder="Senior Regional Phone"
            name="senior_regional_phone"
            value={state.senior_regional_phone}
            onChange={(e) => handleChange(e)}
            className={styles.input}
          />
          <input
            type="email"
            placeholder="Senior Regional Email"
            name="senior_regional_email"
            value={state.senior_regional_email}
            onChange={(e) => handleChange(e)}
            className={styles.input}
          />
        </div>

        <button
          type="submit"
          disabled={saving}
          className="bg-green-400 disabled:cursor-not-allowed rounded-md font-medium text-sm disabled:bg-green-300 text-white p-2.5"
        >
          {saving ? "Saving..." : editing ? "Update" : "Save"}
        </button>
      </form>
    </Modal>
  );
};

const styles = {
  input:
    "rounded-md focus:border-gray-400 focus:outline-none w-full focus:ring-0 border-gray-300 px-4 py-2.5 text-sm text-gray-800",
};
