import { Modal } from "common/Modal";
import { Table } from "common/Table/Table";
import { useNotifications } from "hooks/useNotification";
import { useReimbursement } from "hooks/useReimbursement";
import { Fragment, useState } from "react";
import UserService from "services/user.service";
import { exportToCSV, getLastWeekDateRange } from "utils/funcs";

const service = new UserService();

export const RegionalReimbursement = () => {
  const columns = [
    {
      key: "badge_id",
      dbColName: "badge_id",
      title: "badge id",
      freeze: true,
      freezeIndex: "left-0",
    },
    {
      key: "first_name",
      dbColName: "first_name",
      title: "first name",
      freeze: true,
      freezeIndex: "left-20",
    },
    {
      key: "last_name",
      dbColName: "last_name",
      title: "last name",
      freeze: true,
      freezeIndex: "left-48",
    },
    // { key: "regional", dbColName: "regional", title: "regional" },
    // {
    //   key: "receipt_reimbursement_amount",
    //   dbColName: "receipt_reimbursement_amount",
    //   title: "receipt reimbursement amount",
    // },
    // { key: "description", dbColName: "description", title: "description" },
    {
      key: "travel",
      dbColName: "is_this_travel_operation",
      title: "is this travel operation",
    },
    // { key: "file", dbColName: "type", title: "file type" },
    { key: "trip_name", dbColName: "trip_name", title: "trip name" },
    {
      key: "start_date",
      dbColName: "start_date",
      title: "start date",
      render: (date) => new Date(date).toLocaleDateString(),
    },
    {
      key: "end_date",
      dbColName: "end_date",
      title: "end date",
      render: (date) => new Date(date).toLocaleDateString(),
    },
    {
      key: "filepath",
      dbColName: "filepath",
      title: "filepath",
      render: (image) => (
        <p
          className="text-blue-400 cursor-pointer"
          onClick={() => {
            setImage(image);
            setShowModal(true);
          }}
        >
          {image}
        </p>
      ),
    },
    // { key: "status", dbColName: "status", title: "status" },
    // {
    //   key: "date_of_transaction",
    //   dbColName: "date_of_transaction",
    //   title: "date of transaction",
    // },

    {
      key: "status",
      title: "Status",
      freeze: true,
      freezeIndex: "right-16",
      render: ({ status, _id }) => (
        <div>
          <select
            defaultValue={status}
            className="border border-zinc-200 rounded-md text-sm "
            onChange={(e) => changeStatus(_id, e.target.value)}
          >
            <option value="Approved">Approved</option>
            <option value="Denied">Denied</option>
            <option value="Pending Approval">Pending Approval</option>
          </select>
        </div>
      ),
    },

    {
      key: "action",
      title: "action",
      dbColName: "_id",
      freeze: true,
      freezeIndex: "right-0",
      render: (id) => (
        <div className="flex items-center justify-center">
          <button
            type="button"
            className="text-red-500 "
            onClick={() => handleDelete(id)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="h-6 w-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
              />
            </svg>
          </button>
          <span />
        </div>
      ),
    },
  ];

  const [showModal, setShowModal] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [image, setImage] = useState("");
  const [pagination, setPagination] = useState({
    startDate: "",
    endDate: "",
  });

  const { successMessage } = useNotifications();
  const { reimbursements, setReimbursements, loading } = useReimbursement(
    "Regional",
    pagination
  );

  const changeStatus = (id: string, status: string) => {
    service
      .changeRepStatus(id, status)
      .then((resp) => {
        successMessage("Status updated successfully");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleExport = () => {
    setExporting(true);
    exportToCSV(reimbursements, "reimbursements");
    setExporting(false);
  };

  const handleDelete = (id) => {
    service
      .deleteReimbursement(id)
      .then((resp) => {
        if (resp.data.success) {
          const shallowCopy = [...reimbursements];
          const index = shallowCopy.findIndex((item) => item._id === id);
          shallowCopy.splice(index, 1);
          setReimbursements(shallowCopy);
          successMessage("Deleted successfully");
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <Fragment>
      <div className="flex flex-col mb-4 ">
        <h1 className="font-medium text-gray-700 tracking-wide text-xl">
          Regional Reimbursement
        </h1>
        <p className="text-gray-500 text-sm">View all reimbursements</p>
      </div>
      <Table
        data={reimbursements}
        loadingData={loading}
        exporting={exporting}
        handleExport={handleExport}
        totalItems={reimbursements.length}
        columns={columns}
        columnFilters={
          <ColumnFilters
            pagination={pagination}
            setPagination={setPagination}
          />
        }
      />
      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        title="Image Preview"
      >
        <div>
          <img src={image} alt="receipt" />
        </div>
      </Modal>
    </Fragment>
  );
};

const ColumnFilters = ({ pagination, setPagination }) => {
  return (
    <div className="flex gap-4 mt-2">
      <div className="flex-1">
        <label className="text-xs text-gray-700">Start Date</label>
        <input
          className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
          type="date"
          value={pagination.startDate}
          onChange={(e) =>
            setPagination({
              ...pagination,
              startDate: e.target.value,
            })
          }
        />
      </div>

      <div className="flex-1">
        <label className="text-xs text-gray-700">End Date</label>
        <input
          placeholder="Start date"
          className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
          type="date"
          value={pagination.endDate}
          onChange={(e) => {
            setPagination({
              ...pagination,
              endDate: e.target.value,
            });
          }}
        />
      </div>
    </div>
  );
};
