import { Table } from "common/Table/Table";
import { Fragment, useEffect, useState } from "react";
import UserService from "services/user.service";
import { getLastWeekDateRange } from "utils/funcs";

const service = new UserService();

export const Attended = () => {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);

  const columns = [
    { key: "first_name", dbColName: "first_name", title: "first name" },
    { key: "last_name", dbColName: "last_name", title: "last name" },
    { key: "phone", dbColName: "phone", title: "phone" },
    { key: "email", dbColName: "email", title: "email" },
    {
      key: "recruiter_badge_id",
      dbColName: "recruiter_badge_id",
      title: "recruiter badge id",
    },
    { key: "cell_phone", dbColName: "cell_phone", title: "cell phone" },
    {
      key: "contact_first_name",
      dbColName: "contact_first_name",
      title: "contact first name",
    },
    {
      key: "contact_last_name",
      dbColName: "contact_last_name",
      title: "contact last name",
    },
    {
      key: "contact_full_name",
      dbColName: "contact_full_name",
      title: "contact full name",
    },
    {
      key: "contact_email",
      dbColName: "contact_email",
      title: "contac _email",
    },
    {
      key: "web_occurance",
      dbColName: "web_occurance",
      title: "web occurance",
    },
    { key: "date_as_text", dbColName: "date_as_text", title: "date_as_text" },
    {
      key: "createdAt",
      dbColName: "createdAt",
      title: "created At",
      render: (date) => new Date(date).toLocaleDateString(),
    },
  ];

  const [pagination, setPagination] = useState({
    pageNumber: 1,
    limit: 100,
    endDate: getLastWeekDateRange().end,
    startDate: getLastWeekDateRange().start,
  });

  useEffect(() => {
    setLoading(true);
    service
      .getInfoSessionsAttended(pagination)
      .then((resp) => {
        setData(resp.data.attended);
        setTotalItems(resp?.data?.total_docs);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [pagination]);

  return (
    <Table
      data={data}
      loadingData={loading}
      pagination={pagination}
      setPagination={setPagination}
      setData={setData}
      totalItems={totalItems}
      columns={columns}
      columnFilters={
        <ColumnFilters pagination={pagination} setPagination={setPagination} />
      }
      exportButton={false}
    />
  );
};

const ColumnFilters = ({ pagination, setPagination }) => {
  return (
    <div className="flex gap-4 mt-2">
      <div className="flex-1">
        <label className="text-xs text-gray-700">Start Date</label>
        <input
          className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
          type="date"
          value={pagination.startDate}
          onChange={(e) =>
            setPagination({
              ...pagination,
              startDate: e.target.value,
            })
          }
        />
      </div>

      <div className="flex-1">
        <label className="text-xs text-gray-700">End Date</label>
        <input
          placeholder="Start date"
          className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
          type="date"
          value={pagination.endDate}
          onChange={(e) => {
            setPagination({
              ...pagination,
              endDate: e.target.value,
            });
          }}
        />
      </div>
    </div>
  );
};
