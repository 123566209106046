import * as Yup from "yup";

const LoginValidation = Yup.object().shape({
  email: Yup.string()
    .email("Enter a valid email address")
    .required("Please enter email address"),
  password: Yup.string().required("Please enter password")
});

export { LoginValidation };
