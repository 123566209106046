import { Table } from "common/Table/Table";
import { useEffect, useState } from "react";
import UserService from "services/user.service";
import { exportToCSV } from "utils/funcs";

const service = new UserService();

export const DigitalHireSocial = () => {
  const columns = [
    {
      key: "FIRST_NAME",
      title: "FIRST NAME",
      label: "FIRST NAME",
      dbColName: "FIRST_NAME",
    },
    {
      key: "LAST_NAME",
      title: "LAST NAME",
      label: "LAST NAME",
      dbColName: "LAST_NAME",
    },
    {
      key: "FULL_NAME",
      title: "FULL NAME",
      label: "FUL _NAME",
      dbColName: "FULL_NAME",
    },
    { key: "PHONE", title: "PHONE", label: "PHONE", dbColName: "PHONE" },
    { key: "EMAIL", title: "EMAIL", label: "EMAIL", dbColName: "EMAIL" },
    {
      key: "RECRUITER_BADGE_ID",
      title: "RECRUITER BADGE ID",
      label: "RECRUITER BADGE ID",
      dbColName: "RECRUITER_BADGE_ID",
    },
    {
      key: "RECRUITER_FIRST_NAME",
      title: "RECRUITER FIRST NAME",
      label: "RECRUITER FIRST NAME",
      dbColName: "RECRUITER_FIRST_NAME",
    },
    {
      key: "RECRUITER_LAST_NAME",
      title: "RECRUITER LAST NAME",
      label: "RECRUITER LAST NAME",
      dbColName: "RECRUITER_LAST_NAME",
    },
    {
      key: "RECRUITER_FULL_NAME",
      title: "RECRUITER FULL NAME",
      label: "RECRUITER FULL NAME",
      dbColName: "RECRUITER_FULL_NAME",
    },
    {
      key: "NEXT_LEAD",
      title: "NEXT LEAD",
      label: "NEXT LEAD",
      dbColName: "NEXT_LEAD",
    },
    {
      key: "RECRUITER_POSITION",
      title: "RECRUITER POSITION",
      label: "RECRUITER POSITION",
      dbColName: "RECRUITER_POSITION",
    },
    {
      key: "SOCIAL_MEDIA_2",
      title: "SOCIAL MEDIA 2",
      label: "SOCIAL MEDIA 2",
      dbColName: "SOCIAL_MEDIA_2",
    },
    {
      key: "SOCIAL_MEDIA_3",
      title: "SOCIAL MEDIA 3",
      label: "SOCIAL MEDIA 3",
      dbColName: "SOCIAL_MEDIA_3",
    },
    {
      key: "LAST_LEAD_DATE",
      title: "LAST LEAD DATE",
      label: "LAST LEAD DATE",
      dbColName: "LAST_LEAD_DATE",
    },
    {
      key: "RECRUITER_PHONE",
      title: "RECRUITER PHONE",
      label: "RECRUITER PHONE",
      dbColName: "RECRUITER_PHONE",
    },
    {
      key: "RECRUITER_EMAIL",
      title: "RECRUITER EMAIL",
      label: "RECRUITER EMAIL",
      dbColName: "RECRUITER_EMAIL",
    },
    { key: "STATUS", title: "STATUS", label: "STATUS", dbColName: "STATUS" },
    {
      key: "EVO_SOCIAL_MEDIA",
      title: "EVO SOCIAL MEDIA",
      label: "EVO SOCIAL MEDIA",
      dbColName: "EVO_SOCIAL_MEDIA",
    },
    {
      key: "EVO_WEBSITE",
      title: "EVO WEBSITE",
      label: "EVO WEBSITE",
      dbColName: "EVO_WEBSITE",
    },
    {
      key: "EVO_MEDIA",
      title: "EVO MEDIA",
      label: "EVO MEDIA",
      dbColName: "EVO_MEDIA",
    },
    {
      key: "CHANGE_TIMESTAMP",
      title: "CHANGE TIMESTAMP",
      label: "CHANGE TIMESTAMP",
      dbColName: "CHANGE_TIMESTAMP",
    },
    {
      key: "LEAD_SHEET_LINK",
      title: "LEAD SHEET LINK",
      label: "LEAD SHEET LINK",
      dbColName: "LEAD_SHEET_LINK",
    },
    {
      key: "DATE_APPLIED",
      title: "DATE APPLIED",
      label: "DATE APPLIED",
      dbColName: "DATE_APPLIED",
    },
    {
      key: "COUNTRY",
      title: "COUNTRY",
      label: "COUNTRY",
      dbColName: "COUNTRY",
    },
    {
      key: "HOME_ADDRESS",
      title: "HOME ADDRESS",
      label: "HOME ADDRESS",
      dbColName: "HOME_ADDRESS",
    },
    {
      key: "HOME_CITY",
      title: "HOME CITY",
      label: "HOME CITY",
      dbColName: "HOME_CITY",
    },
    {
      key: "HOME_STATE",
      title: "HOME STATE",
      label: "HOME TATE",
      dbColName: "HOME_STATE",
    },
    {
      key: "HOME_ZIP_CODE",
      title: "HOME ZIP CODE",
      label: "HOME ZIP CODE",
      dbColName: "HOME_ZIP_CODE",
    },
    {
      key: "APPLICANT_TYPE",
      title: "APPLICANT TYPE",
      label: "APPLICANT TYPE",
      dbColName: "APPLICANT_TYPE",
    },
    {
      key: "DEGREE_TYPE",
      title: "DEGREE TYPE",
      label: "DEGREE TYPE",
      dbColName: "DEGREE_TYPE",
    },
    {
      key: "ENROLLED_DATE",
      title: "ENROLLED DATE",
      label: "ENROLLED DATE",
      dbColName: "ENROLLED_DATE",
    },
    {
      key: "GRADUATION_YEAR",
      title: "GRADUATION YEAR",
      label: "GRADUATION YEAR",
      dbColName: "GRADUATION_YEAR",
    },
    { key: "MAJORS", title: "MAJORS", label: "MAJORS", dbColName: "MAJORS" },
    {
      key: "COLLEGE_NAME",
      title: "COLLEGE NAME",
      label: "COLLEGE NAME",
      dbColName: "COLLEGE_NAME",
    },
    {
      key: "COLLEGE_ADDRESS",
      title: "COLLEGE ADDRESS",
      label: "COLLEGE ADDRESS",
      dbColName: "COLLEGE_ADDRESS",
    },
    {
      key: "COLLEGE_CITY",
      title: "COLLEGE CITY",
      label: "COLLEGE CITY",
      dbColName: "COLLEGE_CITY",
    },
    {
      key: "COLLEGE_STATE",
      title: "COLLEGE STATE",
      label: "COLLEGE STATE",
      dbColName: "COLLEGE_STATE",
    },
    {
      key: "COLLEGE_ZIP_CODE",
      title: "COLLEGE ZIP CODE",
      label: "COLLEGE ZIP CODE",
      dbColName: "COLLEGE_ZIP_CODE",
    },
    {
      key: "BIRTHDAY",
      title: "BIRTHDAY",
      label: "BIRTHDAY",
      dbColName: "BIRTHDAY",
    },
    {
      key: "TIMESTAMP",
      title: "TIMESTAMP",
      label: "TIMESTAMP",
      dbColName: "TIMESTAMP",
    },
  ];

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 100,
  });

  useEffect(() => {
    setLoading(true);
    service
      .getDigitalHireSocial(pagination)
      .then((resp) => {
        setData(resp.data.data);
        setTotalItems(resp.data.total_docs);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [pagination]);

  const handleExport = () => {
    exportToCSV(data, "hiresocial")
  }

  return (
    <Table
      data={data}
      loadingData={loading}
      handleExport={handleExport}
      setPagination={setPagination}
      pagination={pagination}
      totalItems={totalItems}
      columns={columns}
    />
  );
};
