import classNames from "classnames";
import { useRouter } from "hooks/useRouter";
import { useEffect, useState } from "react";
import UserService from "services/user.service";

const service = new UserService();

export const SolarDetails = () => {
  const [allLeads, setAllLeads] = useState([]);
  const [officeId, setOfficeId] = useState("");
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState([]);
  const [loadingStats, setLoadingStats] = useState(false);
  const { params } = useRouter();
  const [id, setId] = useState(params.id);
  const [data, setData] = useState<any>({});

  useEffect(() => {
    service
      .getSolarLead()
      .then((resp) => {
        setAllLeads(resp.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    // setId(params.id)
    setLoadingStats(true);
    service
      .solarDetails(id ?? "")
      .then((resp) => {
        setData(resp.data.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });

    service
      .getSolarStats(officeId ?? "")
      .then((resp) => {
        console.log(resp);
        setStats(resp.data.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingStats(false);
      });
  }, [id, params.id, officeId]);

  if (loading) {
    return (
      <div className="h-full w-full flex flex-col items-center justify-center">
        <p>Loading...</p>
      </div>
    );
  }
  const handleChangeSelect = (e) => {
    const { value } = e.target;
    setId(value);
    const find: any = allLeads.find((item: any) => item._id === value);
    if (find) {
      setOfficeId(find.id);
    }
  };

  return (
    <div>
      <select
        onChange={(e) => handleChangeSelect(e)}
        defaultValue={id}
        className="rounded-md border-gray-200 w-72 focus:border-gray-300 focus:ring-0 focus:outline-none"
      >
        {allLeads?.map((item: any) => (
          <option key={item?._id} value={item?._id}>
            {item?.office_name}
          </option>
        ))}
      </select>

      <div className="flex gap-4">
        <div className="w-full md:w-1/3">
          <div>
            <p className="mt-4 text-lg font-semibold">Lead Rules</p>

            <div className="bg-white mt-1.5 shadow-sm border border-zinc-200 flex flex-col  p-4  rounded-lg divide-y divide-zinc-200">
              <div className="flex justify-between gap-4 py-3">
                <span>Office Name</span>
                <span>{data?.office_name}</span>
              </div>
              <div className="flex justify-between gap-4 py-3">
                <span>Order Type</span>
                <span>{data?.order_type}</span>
              </div>
              <div className="flex justify-between gap-4 py-3">
                <span>Order KPI</span>
                <span>{data?.orderKpi}</span>
              </div>
              <div className="flex justify-between gap-4 py-3">
                <span>KPI Duration</span>
                <span>
                  {data?.kpiDuration === "weekly"
                    ? "Previous 7 Days"
                    : data?.kpiDuration === "bimonthly"
                    ? "Previous 14 Days"
                    : "Previous 30 Days"}
                </span>
              </div>
            </div>
          </div>
          <div className="flex-1">
            <p className="text-lg mt-4 font-semibold">
              Current Solar Pro Order
            </p>

            <div className="bg-white w-full mt-1.5 shadow-sm border overflow-hidden border-zinc-200 flex flex-col   rounded-lg divide-y divide-zinc-200">
              {data?.solar_closure?.map((item, index) => (
                <div key={item.badge_id} className="flex gap-4 ">
                  <div
                    className={classNames(
                      "w-36 text-center font-semibold p-3 text-white bg-blue-600"
                    )}
                  >
                    {index === 0
                      ? "NEXT UP"
                      : index === 1
                      ? "ON DECK"
                      : index === 2
                      ? "IN THE WHOLE"
                      : null}
                  </div>
                  <span className="flex-1 py-3">{item.closure_name}</span>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="flex-1">
          <p className="mt-4 text-lg font-semibold">Stats</p>

          <div className="bg-white mt-1.5 shadow-sm border border-zinc-200 flex flex-col  p-4 flex-1 rounded-lg divide-y divide-zinc-200">
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Rank
                    </th>

                    <th scope="col" className="px-6 py-3">
                      Solar Pro
                    </th>
                    <th scope="col" className="px-6 py-3">
                      CS
                    </th>
                    <th scope="col" className="px-6 py-3">
                      P%
                    </th>
                    <th scope="col" className="px-6 py-3">
                      CS%
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {stats.map((item: any, index) => (
                    <tr
                      key={index}
                      className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                    >
                      <td className="px-6 py-4">{++index}</td>

                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {item?.name}
                      </th>
                      <td className="px-6 py-4">{item?.close_percentage}</td>
                      <td className="px-6 py-4">{item?.pitch_percentage}</td>
                      <td className="px-6 py-4">{item?.contract_signed}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
