import { Table } from "common/Table/Table";
import { useEffect, useState } from "react";
import UserService from "services/user.service";

const service = new UserService();

export const Scholarships = () => {
  const columns = [
    {
      key: "RECRUITER_BADGE_ID",
      title: "RECRUITER BADGE ID",
      label: "RECRUITER BADGE ID",
      dbColName: "RECRUITER_BADGE_ID",
    },
    {
      key: "FIRST_NAME",
      title: "FIRST NAME",
      label: "FIRST NAME",
      dbColName: "FIRST_NAME",
    },
    {
      key: "LAST_NAME",
      title: "LAST NAME",
      label: "LAST NAME",
      dbColName: "LAST_NAME",
    },
    { key: "PHONE", title: "PHONE", label: "PHONE", dbColName: "PHONE" },
    { key: "EMAIL", title: "EMAIL", label: "EMAIL", dbColName: "EMAIL" },
    { key: "SOURCE", title: "SOURCE", label: "SOURCE", dbColName: "SOURCE" },
    {
      key: "D2D_EXP",
      title: "D2D EXP",
      label: "D2D EXP",
      dbColName: "D2D_EXP",
    },
    { key: "OTHER", title: "OTHER", label: "OTHER", dbColName: "OTHER" },
    { key: "NOTES", title: "NOTES", label: "NOTES", dbColName: "NOTES" },
    {
      key: "DATE_SUBMITTED",
      title: "DATE SUBMITTED",
      label: "DATE SUBMITTED",
      dbColName: "DATE_SUBMITTED",
    },
    {
      key: "REMINDER_TEXT_DATE",
      title: "REMINDER TEXT DATE",
      label: "REMINDER TEXT DATE",
      dbColName: "REMINDER_TEXT_DATE",
    },
    {
      key: "REMINDER_TEXT_TIME",
      title: "REMINDER TEXT TIME",
      label: "REMINDER TEXT TIME",
      dbColName: "REMINDER_TEXT_TIME",
    },
    {
      key: "REMINDER_TEXT_TIMEZONE",
      title: "REMINDER TEXT TIMEZONE",
      label: "REMINDER TEXT TIMEZONE",
      dbColName: "REMINDER_TEXT_TIMEZONE",
    },
    {
      key: "CELL_PHONE",
      title: "CELL PHONE",
      label: "CELL PHONE",
      dbColName: "CELL_PHONE",
    },
    {
      key: "CONTACT_FULL_NAME",
      title: "CONTACT FULL NAME",
      label: "CONTACT FULL NAME",
      dbColName: "CONTACT_FULL_NAME",
    },
    {
      key: "SIGNED_BADGE_ID",
      title: "SIGNED BADGE ID",
      label: "SIGNED BADGE ID",
      dbColName: "SIGNED_BADGE_ID",
    },
    {
      key: "CONTACT_CREATED",
      title: "CONTACT CREATED",
      label: "CONTACT CREATED",
      dbColName: "CONTACT_CREATED",
    },
    {
      key: "ONLINE_INFO_SESSION_REGISTERED",
      title: "ONLINE INFO SESSION REGISTERED",
      label: "ONLINE INFO SESSION REGISTERED",
      dbColName: "ONLINE_INFO_SESSION_REGISTERED",
    },
    {
      key: "ONLINE_INFO_SESSION_ATTENDED",
      title: "ONLINE INFO SESSION ATTENDED",
      label: "ONLINE INFO SESSION ATTENDED",
      dbColName: "ONLINE_INFO_SESSION_ATTENDED",
    },
    {
      key: "WEEKLY_TRAINING_ATTENDED",
      title: "WEEKLY TRAINING ATTENDED",
      label: "WEEKLY TRAINING ATTENDED",
      dbColName: "WEEKLY_TRAINING_ATTENDED",
    },
    {
      key: "APPLICATION_SUBMITTED",
      title: "APPLICATION SUBMITTED",
      label: "APPLICATION SUBMITTED",
      dbColName: "APPLICATION_SUBMITTED",
    },
    {
      key: "MANAGER_INTERVIEW",
      title: "MANAGER INTERVIEW",
      label: "MANAGER INTERVIEW",
      dbColName: "MANAGER_INTERVIEW",
    },
    {
      key: "AGREEMENT_SENT",
      title: "AGREEMENT SENT",
      label: "AGREEMENT SENT",
      dbColName: "AGREEMENT_SENT",
    },
    {
      key: "AGREEMENT_SIGNED",
      title: "AGREEMENT SIGNED",
      label: "AGREEMENT SIGNED",
      dbColName: "AGREEMENT_SIGNED",
    },
    {
      key: "ONBOARD_DATE",
      title: "ONBOARD DATE",
      label: "ONBOARD DATE",
      dbColName: "ONBOARD_DATE",
    },
    {
      key: "LAST_STREET_GENIE_LOGIN",
      title: "LAST STREET GENIE LOGIN",
      label: "LAST STREET GENIE LOGIN",
      dbColName: "LAST_STREET_GENIE_LOGIN",
    },
    {
      key: "COMPLETED_ONBOARD_TRAINING",
      title: "COMPLETED ONBOARD TRAINING",
      label: "COMPLETED ONBOARD TRAINING",
      dbColName: "COMPLETED_ONBOARD_TRAINING",
    },
    {
      key: "PRESEASON_BLITZ_REGISTERED",
      title: "PRESEASON BLITZ REGISTERED",
      label: "PRESEASON BLITZ REGISTERED",
      dbColName: "PRESEASON_BLITZ_REGISTERED",
    },
    {
      key: "PRESEASON_BLITZ_ATTENDED",
      title: "PRESEASON BLITZ ATTENDED",
      label: "PRESEASON BLITZ ATTENDED",
      dbColName: "PRESEASON_BLITZ_ATTENDED",
    },
    {
      key: "LAST_SIGNED_CONTACT_DATE",
      title: "LAST SIGNED CONTACT DATE",
      label: "LAST SIGNED CONTACT DATE",
      dbColName: "LAST_SIGNED_CONTACT_DATE",
    },
    {
      key: "LIST_STARTED_100",
      title: "LIST STARTED 100",
      label: "LIST STARTED 100",
      dbColName: "LIST_STARTED_100",
    },
    {
      key: "LIST_COMPLETED_100",
      title: "LIST COMPLETED 100",
      label: "LIST COMPLETED 100",
      dbColName: "LIST_COMPLETED_100",
    },
    {
      key: "FIRST_INSTALL_DATE",
      title: "FIRST INSTALL DATE",
      label: "FIRST INSTALL DATE",
      dbColName: "FIRST_INSTALL_DATE",
    },
    {
      key: "LAST_INSTALL_DATE",
      title: "LAST INSTALL DATE",
      label: "LAST INSTALL DATE",
      dbColName: "LAST_INSTALL_DATE",
    },
    {
      key: "LAST_SOLAR_LEAD_DATE",
      title: "LAST SOLAR LEAD DATE",
      label: "LAST SOLAR LEAD DATE",
      dbColName: "LAST_SOLAR_LEAD_DATE",
    },
    {
      key: "LAST_SOLAR_CLOSED_DATE",
      title: "LAST_SOLAR_CLOSED_DATE",
      label: "LAST_SOLAR_CLOSED_DATE",
      dbColName: "LAST_SOLAR_CLOSED_DATE",
    },
    {
      key: "LAST_SOLAR_INSTALL_DATE",
      title: "LAST_SOLAR_INSTALL_DATE",
      label: "LAST_SOLAR_INSTALL_DATE",
      dbColName: "LAST_SOLAR_INSTALL_DATE",
    },
    {
      key: "LAST_RECRUIT_WITH_SALE_DATE",
      title: "LAST RECRUIT WITH SALE DATE",
      label: "LAST RECRUIT WITH SALE DATE",
      dbColName: "LAST_RECRUIT_WITH_SALE_DATE",
    },
    { key: "STAGE", title: "STAGE", label: "STAGE", dbColName: "STAGE" },
    { key: "FP", title: "FP", label: "FP", dbColName: "FP" },
    { key: "EFP", title: "EFP", label: "EFP", dbColName: "EFP" },
    {
      key: "CONTACTS",
      title: "CONTACTS",
      label: "CONTACTS",
      dbColName: "CONTACTS",
    },
    {
      key: "SIGNED_REPS",
      title: "SIGNED_REPS",
      label: "SIGNED_REPS",
      dbColName: "SIGNED_REPS",
    },
    { key: "RWS", title: "RWS", label: "RWS", dbColName: "RWS" },
    {
      key: "MAX_POSITION",
      title: "MAX_POSITION",
      label: "MAX_POSITION",
      dbColName: "MAX_POSITION",
    },
    { key: "TENURE", title: "TENURE", label: "TENURE", dbColName: "TENURE" },
    { key: "TEAM", title: "TEAM", label: "TEAM", dbColName: "TEAM" },
    {
      key: "MANAGEMENT_GROUP",
      title: "MANAGEMENT GROUP",
      label: "MANAGEMENT GROUP",
      dbColName: "MANAGEMENT_GROUP",
    },
    {
      key: "SENIOR_MANAGEMENT_GROUP",
      title: "SENIOR MANAGEMENT GROUP",
      label: "SENIOR MANAGEMENT GROUP",
      dbColName: "SENIOR_MANAGEMENT_GROUP",
    },
    { key: "REGION", title: "REGION", label: "REGION", dbColName: "REGION" },
    {
      key: "SENIOR_REGION",
      title: "SENIOR REGION",
      label: "SENIOR REGION",
      dbColName: "SENIOR_REGION",
    },
    {
      key: "PARTNERSHIP",
      title: "PARTNERSHIP",
      label: "PARTNERSHIP",
      dbColName: "PARTNERSHIP",
    },
    {
      key: "DIVISION",
      title: "DIVISION",
      label: "DIVISION",
      dbColName: "DIVISION",
    },
    {
      key: "RECRUITER_ID",
      title: "RECRUITER ID",
      label: "RECRUITER ID",
      dbColName: "RECRUITER_ID",
    },
    {
      key: "RECRUITER_NAME",
      title: "RECRUITER NAME",
      label: "RECRUITER NAME",
      dbColName: "RECRUITER_NAME",
    },
    {
      key: "RECRUITER_PHONE_NUMBER",
      title: "RECRUITER PHONE NUMBER",
      label: "RECRUITER PHONE NUMBER",
      dbColName: "RECRUITER_PHONE_NUMBER",
    },
    {
      key: "RECRUITER_EMAIL",
      title: "RECRUITER EMAIL",
      label: "RECRUITER EMAIL",
      dbColName: "RECRUITER_EMAIL",
    },
    {
      key: "TEAM_LEADER_ID",
      title: "TEAM LEADER ID",
      label: "TEAM LEADER ID",
      dbColName: "TEAM_LEADER_ID",
    },
    {
      key: "TEAM_LEADER_NAME",
      title: "TEAM LEADER NAME",
      label: "TEAM LEADER NAME",
      dbColName: "TEAM_LEADER_NAME",
    },
    {
      key: "TEAM_LEADER_PHONE_NUMBER",
      title: "TEAM LEADER PHONE NUMBER",
      label: "TEAM LEADER PHONE NUMBER",
      dbColName: "TEAM_LEADER_PHONE_NUMBER",
    },
    {
      key: "TEAM_LEADER_EMAIL",
      title: "TEAM LEADER EMAIL",
      label: "TEAM LEADER EMAIL",
      dbColName: "TEAM_LEADER_EMAIL",
    },
    {
      key: "MANAGEMENT_GROUP_LEADER_ID",
      title: "MANAGEMENT_GROUP_LEADER_ID",
      label: "MANAGEMENT_GROUP_LEADER_ID",
      dbColName: "MANAGEMENT_GROUP_LEADER_ID",
    },
    {
      key: "MANAGEMENT_GROUP_LEADER_NAME",
      title: "MANAGEMENT GROUP LEADER NAME",
      label: "MANAGEMENT GROUP LEADER NAME",
      dbColName: "MANAGEMENT_GROUP_LEADER_NAME",
    },
    {
      key: "MANAGEMENT_GROUP_LEADER_PHONE_NUMBER",
      title: "MANAGEMENT GROUP LEADER PHONE NUMBER",
      label: "MANAGEMENT GROUP LEADER PHONE NUMBER",
      dbColName: "MANAGEMENT_GROUP_LEADER_PHONE_NUMBER",
    },
    {
      key: "MANAGEMENT_GROUP_LEADER_EMAIL",
      title: "MANAGEMENT GROUP LEADER EMAIL",
      label: "MANAGEMENT GROUP LEADER EMAIL",
      dbColName: "MANAGEMENT_GROUP_LEADER_EMAIL",
    },
    {
      key: "SENIOR_MANAGEMENT_GROUP_LEADER_ID",
      title: "SENIOR MANAGEMENT GROUP LEADER ID",
      label: "SENIOR MANAGEMENT GROUP LEADER ID",
      dbColName: "SENIOR_MANAGEMENT_GROUP_LEADER_ID",
    },
    {
      key: "SENIOR_MANAGEMENT_GROUP_LEADER",
      title: "SENIOR MANAGEMENT GROUP LEADER",
      label: "SENIOR MANAGEMENT GROUP LEADER",
      dbColName: "SENIOR_MANAGEMENT_GROUP_LEADER",
    },
    {
      key: "SENIOR_MANAGEMENT_GROUP_LEADER_PHONE_NUMBER",
      title: "SENIOR MANAGEMENT GROUP LEADER PHONE NUMBER",
      label: "SENIOR MANAGEMENT GROUP LEADER PHONE NUMBER",
      dbColName: "SENIOR_MANAGEMENT_GROUP_LEADER_PHONE_NUMBER",
    },
    {
      key: "SENIOR_MANAGEMENT_GROUP_LEADER_EMAIL",
      title: "SENIOR MANAGEMENT GROUP LEADER EMAIL",
      label: "SENIOR MANAGEMENT GROUP LEADER EMAIL",
      dbColName: "SENIOR_MANAGEMENT_GROUP_LEADER_EMAIL",
    },
    {
      key: "REGION_LEADER_ID",
      title: "REGION_LEADER_ID",
      label: "REGION_LEADER_ID",
      dbColName: "REGION_LEADER_ID",
    },
    {
      key: "REGION_LEADER_NAME",
      title: "REGION LEADER NAME",
      label: "REGION LEADER NAME",
      dbColName: "REGION_LEADER_NAME",
    },
    {
      key: "REGION_LEADER_PHONE_NUMBER",
      title: "REGION LEADER PHONE NUMBER",
      label: "REGION LEADER PHONE NUMBER",
      dbColName: "REGION_LEADER_PHONE_NUMBER",
    },
    {
      key: "REGION_LEADER_EMAIL",
      title: "REGION LEADER EMAIL",
      label: "REGION LEADER EMAIL",
      dbColName: "REGION_LEADER_EMAIL",
    },
    {
      key: "SENIOR_REGION_ID",
      title: "SENIOR REGION ID",
      label: "SENIOR REGION ID",
      dbColName: "SENIOR_REGION_ID",
    },
    {
      key: "SENIOR_REGION_NAME",
      title: "SENIOR REGION NAME",
      label: "SENIOR REGION NAME",
      dbColName: "SENIOR_REGION_NAME",
    },
    {
      key: "SENIOR_REGION_LEADER_PHONE_NUMBER",
      title: "SENIOR REGION LEADER PHONE NUMBER",
      label: "SENIOR REGION LEADER PHONE NUMBER",
      dbColName: "SENIOR_REGION_LEADER_PHONE_NUMBER",
    },
    {
      key: "SENIOR_REGION_LEADER_EMAIL",
      title: "SENIOR_REGION_LEADER_EMAIL",
      label: "SENIOR_REGION_LEADER_EMAIL",
      dbColName: "SENIOR_REGION_LEADER_EMAIL",
    },
    {
      key: "PERTNER_LEADER_ID",
      title: "PERTNER_LEADER_ID",
      label: "PERTNER_LEADER_ID",
      dbColName: "PERTNER_LEADER_ID",
    },
    {
      key: "PARTNER_LEADER_NAME",
      title: "PARTNER_LEADER_NAME",
      label: "PARTNER_LEADER_NAME",
      dbColName: "PARTNER_LEADER_NAME",
    },
    {
      key: "PARTNER_LEADER_PHONE_NUMBER",
      title: "PARTNER_LEADER_PHONE_NUMBER",
      label: "PARTNER_LEADER_PHONE_NUMBER",
      dbColName: "PARTNER_LEADER_PHONE_NUMBER",
    },
    {
      key: "DIVISION_LEADER_ID",
      title: "DIVISION LEADER ID",
      label: "DIVISION LEADER ID",
      dbColName: "DIVISION_LEADER_ID",
    },
    {
      key: "DIVISION_LEADER_NAME",
      title: "DIVISION LEADER NAME",
      label: "DIVISION LEADER NAME",
      dbColName: "DIVISION_LEADER_NAME",
    },
    {
      key: "DIVISION_LEADER_PHONE_NUMBER",
      title: "DIVISION LEADER PHONE NUMBER",
      label: "DIVISION LEADER PHONE NUMBER",
      dbColName: "DIVISION_LEADER_PHONE_NUMBER",
    },
    {
      key: "CONTACT_LIST_TRANSFER",
      title: "CONTACT LIST TRANSFER",
      label: "CONTACT LIST TRANSFER",
      dbColName: "CONTACT_LIST_TRANSFER",
    },
    {
      key: "APPLICANTID",
      title: "APPLICANT ID",
      label: "APPLICANT ID",
      dbColName: "APPLICANTID",
    },
  ];

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 100,
  });

  useEffect(() => {
    setLoading(true);
    service
      .getScholarships(pagination)
      .then((resp) => {
        setData(resp.data.data);
        console.log(resp.data);
        setTotalItems(resp.data.total_docs);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [pagination]);

  return (
    <Table
      data={data}
      loadingData={loading}
      setPagination={setPagination}
      pagination={pagination}
      totalItems={totalItems}
      columns={columns}
    />
  );
};
