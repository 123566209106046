import React, { FC } from "react";
import { Sidebar2 } from "./Sidebar2";

interface IProps {
  children: React.ReactNode;
}

export const Dashboard2Layout: FC<IProps> = ({ children }) => {
  return (
    <div className="min-h-screen font-poppins">
      <div className="h-screen flex overflow-hidden bg-gray-100">
        <div className="hidden lg:block">
          <Sidebar2 />
        </div>
        <div className="flex flex-col flex-1 overflow-hidden">
          
          

          <main className="focus:outline-none overflow-auto h-screen">
            {children}
          </main>
        </div>
      </div>
    </div>
  );
};
