import React, { FC, useCallback, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ILoginForm } from "types/index";
import { LoginValidation } from "utils/validation";
import { useThunkDispatch } from "hooks/useThunkDispatch";
import { InputField } from "common/form/InputField";
import { login } from "redux/actions/user";
import { useSelector } from "react-redux";
import { authSelector } from "redux/selectors/user";
import { loginFailure } from "redux/reducers/userReducer";

export const LoginPage: FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ILoginForm>({
    resolver: yupResolver(LoginValidation),
  });

  const { loader, error } = useSelector(authSelector);
  const dispatch = useThunkDispatch();

  useEffect(() => {
    dispatch(loginFailure(null));
  }, [dispatch]);

  const onSubmit: SubmitHandler<ILoginForm> = useCallback(
    (data) => {
      dispatch(login(data));
    },
    [dispatch]
  );

  return (
    <div className="flex justify-center bg-black text-white items-center p-6 md:p-0 min-h-screen">
      <div className="w-full max-w-md ">
          <div className="w-56 md:w-72 mx-auto">
            <img
              alt="logo"
              src="/images/logo.png"
              className="h-full w-full mx-auto object-contain"
            />
          </div>
        {error && <p className="text-center text-red-500 mt-4">{error}</p>}
        <form onSubmit={handleSubmit(onSubmit)} className="p-6 flex flex-col">
          <InputField
            placeholder="Enter your email address"
            label="Email"
            register={register}
            layout="mt-2"
            errorMessage={errors.email?.message}
            type="email"
            required
            name="email"
          />
          <InputField
            type="password"
            placeholder="Enter your password"
            label="Password"
            layout="mt-4"
            required
            errorMessage={errors.password?.message}
            register={register}
            name="password"
          />
          <button
            type="submit"
            className={styles.button}
            disabled={
              !!errors?.email?.message || !!errors?.password?.message || loader
            }
          >
            {loader ? "Please wait..." : "Login"}
          </button>
        </form>
      </div>
    </div>
  );
};

const styles = {
  input:
    "mt-1 bg-white placeholder:text-sm rounded-md text-gray-800 appearance-none block w-full focus:ring-0 p-4 border border-gray-300 placeholder-gray-400 focus:outline-none sm:text-sm ",
  button:
    " mb-2 mt-5 w-full drop-shadow-md disabled:bg-red-400 bg-primary disabled:cursor-not-allowed duration-500 py-3 hover:bg-red-600 rounded-md font-semibold text-white text-center",
  label: "block text-sm font-semibold tracking-wide",
};
