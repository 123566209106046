import { Table } from "common/Table/Table";
import { Fragment, useEffect, useState } from "react";
import UserService from "services/user.service";
import { InfoSessions } from "components/contacts/InfoSessions";
import { Scholarships } from "components/contacts/Scholarships";
import { useDebounce } from "hooks/useDebounce";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { exportToCSV, formatDate, formatDateY } from "utils/funcs";
import { useNotifications } from "hooks/useNotification";

const service = new UserService();

export const Contacts = () => {
  const columns = [
    {
      key: "recruiter_badge_id",
      dbColName: "recruiter_badge_id",
      title: "recruiter badge id",
      freeze: true,
      freezeIndex: "left-0",
    },
    {
      key: "first_name",
      dbColName: "first_name",
      title: "first name",
      freeze: true,
      freezeIndex: "left-20",
    },
    {
      key: "last_name",
      dbColName: "last_name",
      title: "last name",
      freeze: true,
      freezeIndex: "left-60",
    },
    { key: "phone", dbColName: "phone", title: "phone" },
    { key: "email", dbColName: "email", title: "email" },
    { key: "source", dbColName: "source", title: "source" },
    { key: "location", dbColName: "location", title: "location" },
    { key: "d2d_exp", dbColName: "d2d_exp", title: "d2d exp" },
    { key: "other", dbColName: "other", title: "other" },
    { key: "notes", dbColName: "notes", title: "notes" },
    {
      key: "date_submitted",
      dbColName: "date_submitted",
      title: "date_submitted",
    },
    {
      key: "reminder_text_date",
      dbColName: "reminder_text_date",
      title: "reminder text date",
    },
    {
      key: "reminder_text_time",
      dbColName: "reminder_text_time",
      title: "reminder text time",
    },
    {
      key: "reminder_text_timezone",
      dbColName: "reminder_text_timezone",
      title: "reminder_text_timezone",
    },
    { key: "cell_phone", dbColName: "cell_phone", title: "cell_phone" },
    {
      key: "contact_full_name",
      dbColName: "contact_full_name",
      title: "contact_full_name",
    },
    {
      key: "signed_badge_id",
      dbColName: "signed_badge_id",
      title: "signed_badge_id",
    },
    {
      key: "contact_created",
      dbColName: "contact_created",
      title: "contact_created",
    },
    {
      key: "online_info_session_registered",
      dbColName: "online_info_session_registered",
      title: "online_info_session_registered",
    },
    {
      key: "online_info_session_attended",
      dbColName: "online_info_session_attended",
      title: "online_info_session_attended",
    },
    {
      key: "weekly_training_attended",
      dbColName: "weekly_training_attended",
      title: "weekly_training_attended",
    },
    {
      key: "application_submitted",
      dbColName: "application_submitted",
      title: "application_submitted",
    },
    {
      key: "manager_interview",
      dbColName: "manager_interview",
      title: "manager_interview",
    },
    {
      key: "agreement_sent",
      dbColName: "agreement_sent",
      title: "agreement_sent",
    },
    {
      key: "agreement_signed",
      dbColName: "agreement_signed",
      title: "agreement_signed",
    },
    { key: "onboard_date", dbColName: "onboard_date", title: "onboard_date" },
    {
      key: "last_street_genie_login",
      dbColName: "last_street_genie_login",
      title: "last_street_genie_login",
    },
    {
      key: "completed_onboard_training",
      dbColName: "completed_onboard_training",
      title: "completed_onboard_training",
    },
    {
      key: "preseason_blitz_registered",
      dbColName: "preseason_blitz_registered",
      title: "preseason_blitz_registered",
    },
    {
      key: "preseason_blitz_attended",
      dbColName: "preseason_blitz_attended",
      title: "preseason_blitz_attended",
    },
    {
      key: "last_signed_contact_date",
      dbColName: "last_signed_contact_date",
      title: "last_signed_contact_date",
    },
    {
      key: "list_started_100",
      dbColName: "list_started_100",
      title: "list_started_100",
    },
    {
      key: "list_completed_100",
      dbColName: "list_completed_100",
      title: "list_completed_100",
    },
    {
      key: "first_install_date",
      dbColName: "first_install_date",
      title: "first_install_date",
    },
    {
      key: "last_install_date",
      dbColName: "last_install_date",
      title: "last_install_date",
    },
    {
      key: "last_solar_lead_date",
      dbColName: "last_solar_lead_date",
      title: "last_solar_lead_date",
    },
    {
      key: "last_solar_closed_date",
      dbColName: "last_solar_closed_date",
      title: "last_solar_closed_date",
    },
    {
      key: "last_solar_install_date",
      dbColName: "last_solar_install_date",
      title: "last_solar_install_date",
    },
    {
      key: "last_recruit_with_sale_date",
      dbColName: "last_recruit_with_sale_date",
      title: "last_recruit_with_sale_date",
    },
    { key: "stage", dbColName: "stage", title: "stage" },
    { key: "fp", dbColName: "fp", title: "fp" },
    { key: "efp", dbColName: "efp", title: "efp" },
    { key: "contacts", dbColName: "contacts", title: "contacts" },
    { key: "signed_reps", dbColName: "signed_reps", title: "signed_reps" },
    { key: "rws", dbColName: "rws", title: "rws" },
    { key: "max_position", dbColName: "max_position", title: "max_position" },
    { key: "tenure", dbColName: "tenure", title: "tenure" },
    { key: "team", dbColName: "team", title: "team" },
    {
      key: "management_group",
      dbColName: "management_group",
      title: "management_group",
    },
    {
      key: "senior_management_group",
      dbColName: "senior_management_group",
      title: "senior_management_group",
    },
    { key: "region", dbColName: "region", title: "region" },
    {
      key: "senior_region",
      dbColName: "senior_region",
      title: "senior_region",
    },
    { key: "partnership", dbColName: "partnership", title: "partnership" },
    { key: "division", dbColName: "division", title: "division" },
    { key: "recruiter_id", dbColName: "recruiter_id", title: "recruiter_id" },
    {
      key: "recruiter_name",
      dbColName: "recruiter_name",
      title: "recruiter_name",
    },
    {
      key: "recruiter_phone_number",
      dbColName: "recruiter_phone_number",
      title: "recruiter_phone_number",
    },
    {
      key: "recruiter_email",
      dbColName: "recruiter_email",
      title: "recruiter_email",
    },
    {
      key: "team_leader_id",
      dbColName: "team_leader_id",
      title: "team_leader_id",
    },
    {
      key: "team_leader_name",
      dbColName: "team_leader_name",
      title: "team_leader_name",
    },
    {
      key: "team_leader_phone_number",
      dbColName: "team_leader_phone_number",
      title: "team_leader_phone_number",
    },
    {
      key: "team_leader_email",
      dbColName: "team_leader_email",
      title: "team_leader_email",
    },
    {
      key: "management_group_leader_id",
      dbColName: "management_group_leader_id",
      title: "management_group_leader_id",
    },
    {
      key: "management_group_leader_name",
      dbColName: "management_group_leader_name",
      title: "management_group_leader_name",
    },
    {
      key: "management_group_leader_phone_number",
      dbColName: "management_group_leader_phone_number",
      title: "management_group_leader_phone_number",
    },
    {
      key: "management_group_leader_email",
      dbColName: "management_group_leader_email",
      title: "management_group_leader_email",
    },
    {
      key: "senior_management_group_leader_id",
      dbColName: "senior_management_group_leader_id",
      title: "senior_management_group_leader_id",
    },
    {
      key: "senior_management_group_leader",
      dbColName: "senior_management_group_leader",
      title: "senior_management_group_leader",
    },
    {
      key: "senior_management_group_leader_phone_number",
      dbColName: "senior_management_group_leader_phone_number",
      title: "senior_management_group_leader_phone_number",
    },
    {
      key: "senior_management_group_leader_email",
      dbColName: "senior_management_group_leader_email",
      title: "senior_management_group_leader_email",
    },
    {
      key: "region_leader_id",
      dbColName: "region_leader_id",
      title: "region_leader_id",
    },
    {
      key: "region_leader_name",
      dbColName: "region_leader_name",
      title: "region_leader_name",
    },
    {
      key: "region_leader_phone_number",
      dbColName: "region_leader_phone_number",
      title: "region_leader_phone_number",
    },
    {
      key: "region_leader_email",
      dbColName: "region_leader_email",
      title: "region_leader_email",
    },
    {
      key: "senior_region_id",
      dbColName: "senior_region_id",
      title: "senior_region_id",
    },
    {
      key: "senior_region_name",
      dbColName: "senior_region_name",
      title: "senior_region_name",
    },
    {
      key: "senior_region_leader_phone_number",
      dbColName: "senior_region_leader_phone_number",
      title: "senior_region_leader_phone_number",
    },
    {
      key: "senior_region_leader_email",
      dbColName: "senior_region_leader_email",
      title: "senior_region_leader_email",
    },
    {
      key: "partner_leader_id",
      dbColName: "partner_leader_id",
      title: "partner_leader_id",
    },
    {
      key: "partner_leader_name",
      dbColName: "partner_leader_name",
      title: "partner_leader_name",
    },
    {
      key: "partner_leader_phone_number",
      dbColName: "partner_leader_phone_number",
      title: "partner_leader_phone_number",
    },
    {
      key: "division_leader_id",
      dbColName: "division_leader_id",
      title: "division_leader_id",
    },
    {
      key: "division_leader_name",
      dbColName: "division_leader_name",
      title: "division_leader_name",
    },
    {
      key: "division_leader_phone_number",
      dbColName: "division_leader_phone_number",
      title: "division_leader_phone_number",
    },
    {
      key: "contact_leader_id",
      dbColName: "contact_leader_id",
      title: "contact_leader_id",
    },
    {
      key: "contact_leader_name",
      dbColName: "contact_leader_name",
      title: "contact_leader_name",
    },
    {
      key: "contact_leader_phone_number",
      dbColName: "contact_leader_phone_number",
      title: "contact_leader_phone_number",
    },
    {
      key: "contact_leader_email",
      dbColName: "contact_leader_email",
      title: "contact_leader_email",
    },
    { key: "ad_source", dbColName: "ad_source", title: "ad_source" },
    { key: "ad_category", dbColName: "ad_category", title: "ad_category" },
    { key: "duplicate_id", dbColName: "duplicate_id", title: "duplicate_id" },
    {
      key: "duplicate_phone",
      dbColName: "duplicate_phone",
      title: "duplicate_phone",
    },
    {
      key: "duplicate_email",
      dbColName: "duplicate_email",
      title: "duplicate_email",
    },
    {
      key: "campusreel_direct_applicants",
      dbColName: "campusreel_direct_applicants",
      title: "campusreel_direct_applicants",
    },
    {
      key: "campusreel_coregistration",
      dbColName: "campusreel_coregistration",
      title: "campusreel_coregistration",
    },
    {
      key: "campusreel_scholarship_email",
      dbColName: "campusreel_scholarship_email",
      title: "campusreel_scholarship_email",
    },
    {
      key: "campusreel_scholarship_ytd",
      dbColName: "campusreel_scholarship_ytd",
      title: "campusreel_scholarship_ytd",
    },
    {
      key: "campusreel_form_completed",
      dbColName: "campusreel_form_completed",
      title: "campusreel_form_completed",
    },
    {
      key: "social_website",
      dbColName: "social_website",
      title: "social_website",
    },
    {
      key: "digitalhire_candidates",
      dbColName: "digitalhire_candidates",
      title: "digitalhire_candidates",
    },
    {
      key: "digitalhire_summer_outreach",
      dbColName: "digitalhire_summer_outreach",
      title: "digitalhire_summer_outreach",
    },
    { key: "evo_website", dbColName: "evo_website", title: "evo_website" },
    {
      key: "evo_scholarship_bonus",
      dbColName: "evo_scholarship_bonus",
      title: "evo_scholarship_bonus",
    },
    {
      key: "digital_recruit",
      dbColName: "digital_recruit",
      title: "digital_recruit",
    },
    { key: "deleted", dbColName: "deleted", title: "deleted" },
    { key: "last_updated", dbColName: "last_updated", title: "last_updated" },
    { key: "timestamp", dbColName: "timestamp", title: "timestamp" },
    {
      key: "last_solar_permit_date",
      dbColName: "last_solar_permit_date",
      title: "last_solar_permit_date",
    },
    { key: "uid", dbColName: "uid", title: "uid" },
    {
      key: "signed_phone_lookup",
      dbColName: "signed_phone_lookup",
      title: "signed_phone_lookup",
    },
    {
      key: "signed_full_name_lookup",
      dbColName: "signed_full_name_lookup",
      title: "signed_full_name_lookup",
    },
    {
      key: "datesubmitted",
      dbColName: "datesubmitted",
      title: "datesubmitted",
    },
    {
      key: "action",
      dbColName: "_id",
      freeze: true,
      freezeIndex: "right-0",
      title: "action",
      render: (id) => {
        return (
          <button
            type="button"
            className="text-red-500 text-right"
            onClick={() => handleDelete(id)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="h-6 w-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
              />
            </svg>
          </button>
        );
      },
    },
    {
      key: "createdAt",
      dbColName: "createdAt",
      title: "createdAt",
      render: (date) => new Date(date).toLocaleDateString(),
    },
  ];

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [exporting, setExporting] = useState(false);

  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 100,
    source: "",
    location: "",
    contact_submitted_start_date: "",
    contact_submitted_end_date: "",
    contact_created_start_date: "",
    contact_created_end_date: "",
    fullName: "",
    stage: "",
    team: "",
  });
  const [tab, setTab] = useState("Contacts");
  const debounced = useDebounce(pagination, 500);

  const { successMessage } = useNotifications();

  useEffect(() => {
    setLoading(true);
    service
      .getContacts(debounced)
      .then((resp) => {
        setTotalItems(resp.data.totalDocument);
        setData(resp.data.data);
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setLoading(false);
      });
  }, [debounced]);

  const handleExport = () => {
    setExporting(true);
    const pagin: any = { ...pagination };
    delete pagin.pageNumber;
    delete pagin.pageSize;
    service
      .downloadContacts(pagin)
      .then((resp) => {
        exportToCSV(
          resp.data.data.map((item) => ({ ...item._source })),
          "contacts"
        );
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setExporting(false);
      });
  };

  const handleDelete = (id) => {
    service
      .deleteContact(id)
      .then((resp) => {
        if(resp.data.success) {
          successMessage("Contact deleted successfully")
          const index = data.findIndex((item: { _id: string }) => item._id === id);
          const shallowCopy = [...data]
          shallowCopy.splice(index,1)
          setData(shallowCopy)
        } 
      })
      .catch((error) => console.log(error));
  };

  return (
    <Fragment>
      <div className="flex flex-col mb-4">
        <h1 className="font-medium text-gray-700 tracking-wide text-xl">
          {tab}
        </h1>
        <p className="text-gray-500 text-sm">View all {tab}</p>
      </div>

      {/* Tables */}
      {tab === "Contacts" ? (
        <Table
          data={data}
          loadingData={loading}
          pagination={pagination}
          setPagination={setPagination}
          handleExport={handleExport}
          showSearch={false}
          totalItems={totalItems}
          exporting={exporting}
          setData={setData}
          filters={
            <Filters pagination={pagination} setPagination={setPagination} />
          }
          columnFilters={
            <ColumnFilters
              pagination={pagination}
              setPagination={setPagination}
            />
          }
          columns={columns}
        />
      ) : tab === "Info Sessions" ? (
        <InfoSessions />
      ) : (
        <Scholarships />
      )}

      {/* Tabs */}
      {/* <div className="text-sm mt-4 p-2 text-center text-gray-500 rounded-md bg-gray-200">
        <div className="flex flex-wrap -mb-px gap-3">
          <button
            type="button"
            onClick={() => setTab("Contacts")}
            className={classNames(tabStyles.default, {
              [tabStyles.active]: tab === "Contacts",
            })}
          >
            Contacts
          </button>
          <button
            type="button"
            onClick={() => setTab("Info Sessions")}
            className={classNames(tabStyles.default, {
              [tabStyles.active]: tab === "Info Sessions",
            })}
          >
            Info Sessions
          </button>
          <button
            type="button"
            onClick={() => setTab("Scholarships")}
            className={classNames(tabStyles.default, {
              [tabStyles.active]: tab === "Scholarships",
            })}
          >
            Scholarships
          </button>
        </div>
      </div> */}
    </Fragment>
  );
};

const Filters = ({ pagination, setPagination }) => {
  return (
    <div className="flex flex-1 items-center gap-4">
      <input
        className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        type="text"
        placeholder="Search by Name"
        onChange={(e) => {
          setPagination({
            ...pagination,
            fullName: e.target.value,
          });
        }}
      />
      <input
        className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        type="text"
        placeholder="Search by Source"
        onChange={(e) => {
          setPagination({
            ...pagination,
            source: e.target.value,
          });
        }}
      />
      <input
        className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        type="text"
        placeholder="Location"
        onChange={(e) => {
          setPagination({
            ...pagination,
            location: e.target.value,
          });
        }}
      />
    </div>
  );
};

const ColumnFilters = ({ pagination, setPagination }) => {
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [submittedStartDate, setSubmittedStartDate] = useState<any>(null);
  const [submittedEndDate, setSubmittedEndDate] = useState<any>(null);

  const onChangeCreated = (dates) => {
    const [start, end] = dates;

    setStartDate(start);
    setEndDate(end);

    if (start && end) {
      setPagination({
        ...pagination,
        contact_created_start_date: start && formatDateY(start),
        contact_created_end_date: end && formatDateY(end),
      });
    }
  };

  const onChangeSubmitted = (dates) => {
    const [start, end] = dates;

    if (start && end) {
      setPagination({
        ...pagination,
        contact_submitted_start_date: start && formatDateY(start),
        contact_submitted_end_date: end && formatDateY(end),
      });
    }

    setSubmittedStartDate(start);
    setSubmittedEndDate(end);
  };

  return (
    <div className="flex items-center gap-4 mt-3">
      {/* <input
        className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        type="text"
        placeholder="Search by Name"
        onChange={(e) => {
          setPagination({
            ...pagination,
            fullName: e.target.value,
          });
        }}
      /> */}

      <input
        placeholder="Team"
        className="rounded-md flex-1 focus:border-gray-300 font-light focus:outline-none w-72 focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        type="text"
        onChange={(e) => {
          setPagination({
            ...pagination,
            team: e.target.value,
          });
        }}
      />
      <select
        placeholder="Stage"
        className="rounded-md flex-1 focus:border-gray-300 font-light focus:outline-none w-72 focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        onChange={(e) => {
          setPagination({
            ...pagination,
            stage: e.target.value,
          });
        }}
      >
        <option value="" disabled selected>
          Stage
        </option>
        <option value="Contact">Contact</option>
        <option value="Contact+">Contact+</option>
        <option value="Signed">Signed</option>
        <option value="Signed+">Signed+</option>
        <option value="SH Pro">SH Pro</option>
        <option value="SH Pro+">SH Pro+</option>
      </select>

      {/* <Popup label="Contact Created Date"  />
      <Popup label="Contact Submitted Date"  /> */}
      <DatePicker
        selected={startDate}
        placeholderText="Contact Created Date"
        onChange={onChangeCreated}
        startDate={startDate}
        endDate={endDate}
        // showIcon
        isClearable
        className="z-100 py-2"
        customInput={
          <input
            placeholder="Select date"
            className=" bg-white rounded-md border w-80 border-gray-outline outline-none focus-visible:shadow-none placeholder:text-sm focus:ring-2 ring-brown-10 ring-opacity-50 py-2 text-sm text-zinc-600 pl-4 pr-4 disabled:bg-zinc-50 disabled:text-zinc-500 disabled:cursor-not-allowed text-dark-8 font-TTHoves;"
          />
        }
        selectsRange
        selectsDisabledDaysInRange
      />
      <DatePicker
        selected={submittedStartDate}
        placeholderText="Contact Submitted Date"
        onChange={onChangeSubmitted}
        // showIcon
        startDate={submittedStartDate}
        endDate={submittedEndDate}
        isClearable
        className="z-100 py-2"
        customInput={
          <input
            placeholder="Select date"
            className=" bg-white  rounded-md border w-80 border-gray-outline outline-none focus-visible:shadow-none placeholder:text-sm focus:ring-2 ring-brown-10 ring-opacity-50 py-2 text-sm text-zinc-600 pl-4 pr-4 disabled:bg-zinc-50 disabled:text-zinc-500 disabled:cursor-not-allowed text-dark-8 font-TTHoves;"
          />
        }
        selectsRange
        selectsDisabledDaysInRange
      />

      {/* <input
        className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        type="date"
        onChange={(e) =>
          setPagination({
            ...pagination,
            date_submitted: e.target.value,
          })
        }
      />

      <input
        className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        type="date"
        onChange={(e) =>
          setPagination({
            ...pagination,
            contact_created: e.target.value,
          })
        }
      /> */}
    </div>
  );
};
