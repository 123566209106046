// import { IUser } from "types/index"

export const userSelector = (s: { user: any }) => s.user.user

// export const restaurantSelector = (s: { user: any }) => s.user.restaurant

// export const loadingSelector = (s: { user: { loader: boolean } }) =>
//     s.user.loader

export const authSelector = (s: { user: { isLoggedIn: boolean, loader: boolean, error: string } }) => ({
    isLoggedIn: s.user.isLoggedIn,
    loader: s.user.loader,
    error: s.user.error
})
    
