import axiosInstance from "utils/axiosInstance"

class BaseService {
    async get(url: string, config?: any) {
        return await axiosInstance.get(url, config)
    }

    async post(url: string, data?: any, config?: any) {
        return await axiosInstance.post(url, data, config)
    }

    async patch(url: string, data?: any, config?: any) {
        return await axiosInstance.patch(url, data, config)
    }

    async delete(url: string, config?: any) {
        return await axiosInstance.delete(url, config)
    }
}

export default BaseService
