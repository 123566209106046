import { Table } from "common/Table/Table";
import { useEffect, useState } from "react";
import UserService from "services/user.service";
import { exportToCSV } from "utils/funcs";

const service = new UserService();

export const Positions = () => {
  const columns = [
    {
      key: "position_name",
      title: "position name",
      label: "position_name",
      dbColName: "position_name",
    },
    {
      key: "position_location",
      title: "position location",
      label: "position_location",
      dbColName: "position_location",
    },
    {
      key: "position_type",
      title: "position type",
      label: "position_type",
      dbColName: "position_type",
    },
    {
      key: "position_category",
      title: "position category",
      label: "position_category",
      dbColName: "position_category",
    },
    {
      key: "position_friendly_id",
      title: "position friendly id",
      label: "position_friendly_id",
      dbColName: "position_friendly_id",
    },
    {
      key: "position_creation_date",
      title: "position creation date",
      label: "position creation  date",
      dbColName: "position_creation_date",
    },
    {
      key: "position_id",
      title: "position id",
      label: "position_id",
      dbColName: "position_id",
    },
    {
      key: "position_state",
      title: "position state",
      label: "position_state",
      dbColName: "position_state",
    },
    {
      key: "company_id",
      title: "company id",
      label: "company_id",
      dbColName: "company_id",
    },
    {
      key: "pipeline_id",
      title: "pipeline id",
      label: "pipeline_id",
      dbColName: "pipeline_id",
    },
    {
      key: "createdAt",
      title: "createdAt",
      label: "createdAt",
      dbColName: "createdAt",
    },
  ];

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 100,
  });

  useEffect(() => {
    setLoading(true);
    service
      .getPositions(pagination)
      .then((resp) => {
        setData(resp.data.data);
        setTotalItems(resp.data.total_docs);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [pagination]);

  const handleExport = () => {
    exportToCSV(data, "positions")
  }

  return  (
    <Table
      data={data}
      loadingData={loading}
      handleExport={handleExport}
      setPagination={setPagination}
      pagination={pagination}
      totalItems={totalItems}
      columns={columns}
    />
  )
};
