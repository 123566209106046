import { Table } from "common/Table/Table";
import { useEffect, useState } from "react";
import UserService from "services/user.service";
import { getLastWeekDateRange } from "utils/funcs";
import { tabStyles } from "./GeneralData";

const service = new UserService();

export const Production = () => {
  const columns = [
    {
      key: "badge",
      title: "Badge ID",
      dbColName: "employ_id",
      render: (value) => <span>{value?.employ_id}</span>,
    },
    {
      key: "name",
      title: "Rep Name",
      dbColName: "employ_id",
      render: (value) => <span>{value?.name}</span>,
    },
    {
      key: "efp24",
      title: "EFP 2024",
      dbColName: "EFP",
      render: (value) => <span>{value?.current}</span>,
    },
    {
      key: "efp23",
      title: "EFP 2023",
      dbColName: "EFP",
      render: (value) => <span>{value?.previous}</span>,
    },
    {
      key: "efpgrowth",
      title: "EFP Growth",
      dbColName: "EFP",
      render: (value) => <span>{value?.total}</span>,
    },
    {
      key: "efpupgrade",
      title: "UPGRADE EFP 2024",
      dbColName: "UPGRADE_EFP",
      render: (value) => <span>{value?.current}</span>,
    },
    {
      key: "upgrade23",
      title: "UPGRADE EFP 2023",
      dbColName: "UPGRADE_EFP",
      render: (value) => <span>{value?.previous}</span>,
    },
    {
      key: "upgradegrowth",
      title: "UPGRADE EFP Growth",
      dbColName: "UPGRADE_EFP",
      render: (value) => <span>{value?.total}</span>,
    },
    {
      key: "avgprmr",
      title: "AVG PRMR 2024",
      dbColName: "AVG_PMR",
      render: (value) => <span>{value?.current}</span>,
    },
    {
      key: "avg23",
      title: "AVG PRMR 2023",
      dbColName: "AVG_PMR",
      render: (value) => <span>{value?.previous}</span>,
    },
    {
      key: "avggrowth",
      title: "AVG PRMR Growth",
      dbColName: "AVG_PMR",
      render: (value) => <span>{value?.total}</span>,
    },
    {
      key: "efp24",
      title: "Sl 2024",
      dbColName: "SLs",
      render: (value) => <span>{value?.current}</span>,
    },
    {
      key: "efp23",
      title: "SL 2023",
      dbColName: "SLs",
      render: (value) => <span>{value?.previous}</span>,
    },
    {
      key: "efpgrowth",
      title: "SL Growth",
      dbColName: "SLs",
      render: (value) => <span>{value?.total}</span>,
    },
    {
      key: "efpupgrade",
      title: "SCS 2024",
      dbColName: "SCS",
      render: (value) => <span>{value?.current}</span>,
    },
    {
      key: "upgrade23",
      title: "SCS 2023",
      dbColName: "SCS",
      render: (value) => <span>{value?.previous}</span>,
    },
    {
      key: "upgradegrowth",
      title: "SCS Growth",
      dbColName: "SCS",
      render: (value) => <span>{value?.total}</span>,
    },
    {
      key: "avgprmr",
      title: "SP 2024",
      dbColName: "SP",
      render: (value) => <span>{value?.current}</span>,
    },
    {
      key: "avg23",
      title: "SP 2023",
      dbColName: "SP",
      render: (value) => <span>{value?.previous}</span>,
    },
    {
      key: "avggrowth",
      title: "SP Growth",
      dbColName: "SP",
      render: (value) => <span>{value?.total}</span>,
    },



    {
      key: "avgprmr",
      title: "Signed 2024",
      dbColName: "signed_reps",
      render: (value) => <span>{value?.signed?.current}</span>,
    },
    {
      key: "avg23",
      title: "Signed 2023",
      dbColName: "signed_reps",
      render: (value) => <span>{value?.signed?.previous}</span>,
    },
    {
      key: "avggrowth",
      title: "Signed Growth",
      dbColName: "signed_reps",
      render: (value) => <span>{value?.signed?.total}</span>,
    },

    {
      key: "avgprmr",
      title: "Signed Rookie 2024",
      dbColName: "signed_reps",
      render: (value) => <span>{value?.rookie?.current}</span>,
    },
    {
      key: "avg23",
      title: "Signed Rookie 2023",
      dbColName: "signed_reps",
      render: (value) => <span>{value?.rookie?.previous}</span>,
    },
    {
      key: "avggrowth",
      title: "Signed Rookie Growth",
      dbColName: "signed_reps",
      render: (value) => <span>{value?.rookie?.total}</span>,
    },
    {
      key: "avggrowth",
      title: "EFP 3rd",
      dbColName: "EFP_3RD",
    },

    {
      key: "avggrowth",
      title: "PMR 3rd",
      dbColName: "PMR_3RD",
    },




    {
      key: "avgprmr",
      title: "SIN 2024",
      dbColName: "SIN",
      render: (value) => <span>{value?.current}</span>,
    },
    {
      key: "avg23",
      title: "SIN 2023",
      dbColName: "SIN",
      render: (value) => <span>{value?.previous}</span>,
    },
    {
      key: "avggrowth",
      title: "SIN Growth",
      dbColName: "SIN",
      render: (value) => <span>{value?.total}</span>,
    },
    {
      key: "efp24",
      title: "FP 2024",
      dbColName: "FP",
      render: (value) => <span>{value?.current}</span>,
    },
    {
      key: "efp23",
      title: "FP 2023",
      dbColName: "FP",
      render: (value) => <span>{value?.previous}</span>,
    },
    {
      key: "efpgrowth",
      title: "FP Growth",
      dbColName: "FP",
      render: (value) => <span>{value?.total}</span>,
    },
    {
      key: "efpupgrade",
      title: "UPGRADES 2024",
      dbColName: "UPGRADES",
      render: (value) => <span>{value?.current}</span>,
    },
    {
      key: "upgrade23",
      title: "UPGRADES 2023",
      dbColName: "UPGRADES",
      render: (value) => <span>{value?.previous}</span>,
    },
    {
      key: "upgradegrowth",
      title: "UPGRADE Growth PRMR 2024",
      dbColName: "UPGRADES",
      render: (value) => <span>{value?.total}</span>,
    },
    {
      key: "avgprmr",
      title: "PRMR 2024",
      dbColName: "PMR",
      render: (value) => <span>{value?.current}</span>,
    },
    {
      key: "avg23",
      title: "PRMR 2023",
      dbColName: "PMR",
      render: (value) => <span>{value?.previous}</span>,
    },
    {
      key: "avggrowth",
      title: "PRMR Growth",
      dbColName: "PMR",
      render: (value) => <span>{value?.total}</span>,
    },
    {
      key: "efp24",
      title: "Sl 2024",
      dbColName: "SLs",
      render: (value) => <span>{value?.current}</span>,
    },
    {
      key: "efp23",
      title: "SL 2023",
      dbColName: "SLs",
      render: (value) => <span>{value?.previous}</span>,
    },
    {
      key: "efpgrowth",
      title: "SL Growth",
      dbColName: "SLs",
      render: (value) => <span>{value?.total}</span>,
    },
    {
      key: "efpupgrade",
      title: "SCS 2024",
      dbColName: "SCS",
      render: (value) => <span>{value?.current}</span>,
    },
    {
      key: "upgrade23",
      title: "SCS 2023",
      dbColName: "SCS",
      render: (value) => <span>{value?.previous}</span>,
    },
    {
      key: "upgradegrowth",
      title: "SCS Growth",
      dbColName: "SCS",
      render: (value) => <span>{value?.total}</span>,
    },
    {
      key: "avgprmr",
      title: "SP 2024",
      dbColName: "SP",
      render: (value) => <span>{value?.current}</span>,
    },
    {
      key: "avg23",
      title: "SP 2023",
      dbColName: "SP",
      render: (value) => <span>{value?.previous}</span>,
    },
    {
      key: "avggrowth",
      title: "SP Growth",
      dbColName: "SP",
      render: (value) => <span>{value?.total}</span>,
    },
    {
      key: "avgprmr",
      title: "SIN 2024",
      dbColName: "SIN",
      render: (value) => <span>{value?.current}</span>,
    },
    {
      key: "avg23",
      title: "SIN 2023",
      dbColName: "SIN",
      render: (value) => <span>{value?.previous}</span>,
    },
    {
      key: "avggrowth",
      title: "SIN Growth",
      dbColName: "SIN",
      render: (value) => <span>{value?.total}</span>,
    },
    {
      key: "efp24",
      title: "Sl 2024",
      dbColName: "SLS",
      render: (value) => <span>{value?.current}</span>,
    },
    {
      key: "efp23",
      title: "SL 2023",
      dbColName: "SLS",
      render: (value) => <span>{value?.previous}</span>,
    },
    {
      key: "efpgrowth",
      title: "SL Growth",
      dbColName: "SLS",
      render: (value) => <span>{value?.total}</span>,
    },
    {
      key: "efpupgrade",
      title: "SCS 2024",
      dbColName: "SCS",
      render: (value) => <span>{value?.current}</span>,
    },
    {
      key: "upgrade23",
      title: "SCS 2023",
      dbColName: "SCS",
      render: (value) => <span>{value?.previous}</span>,
    },
    {
      key: "upgradegrowth",
      title: "SCS Growth",
      dbColName: "SCS",
      render: (value) => <span>{value?.total}</span>,
    },
    {
      key: "avgprmr",
      title: "SP 2024",
      dbColName: "SP",
      render: (value) => <span>{value?.current}</span>,
    },
    {
      key: "avg23",
      title: "SP 2023",
      dbColName: "SP",
      render: (value) => <span>{value?.previous}</span>,
    },
    {
      key: "avggrowth",
      title: "SP Growth",
      dbColName: "SP",
      render: (value) => <span>{value?.total}</span>,
    },
    {
      key: "avgprmr",
      title: "SIN 2024",
      dbColName: "SIN",
      render: (value) => <span>{value?.current}</span>,
    },
    {
      key: "avg23",
      title: "SIN 2023",
      dbColName: "SIN",
      render: (value) => <span>{value?.previous}</span>,
    },
    {
      key: "avggrowth",
      title: "CR Growth",
      dbColName: "CR",
      render: (value) => <span>{value?.total}</span>,
    },
    {
      key: "avgprmr",
      title: "CR 2024",
      dbColName: "CR",
      render: (value) => <span>{value?.current}</span>,
    },
    {
      key: "avg23",
      title: "CR 2023",
      dbColName: "CR",
      render: (value) => <span>{value?.previous}</span>,
    },
    {
      key: "avggrowth",
      title: "CR Growth",
      dbColName: "CR",
      render: (value) => <span>{value?.total}</span>,
    },
    {
      key: "avgprmr",
      title: "CWIN 2024",
      dbColName: "CWIN",
      render: (value) => <span>{value?.current}</span>,
    },
    {
      key: "avg23",
      title: "CWIN 2023",
      dbColName: "CWIN",
      render: (value) => <span>{value?.previous}</span>,
    },
    {
      key: "avggrowth",
      title: "CWIN Growth",
      dbColName: "CWIN",
      render: (value) => <span>{value?.total}</span>,
    },
    {
      key: "avgprmr",
      title: "CWP 2024",
      dbColName: "CWP",
      render: (value) => <span>{value?.current}</span>,
    },
    {
      key: "avg23",
      title: "CWP 2023",
      dbColName: "CWP",
      render: (value) => <span>{value?.previous}</span>,
    },
    {
      key: "avggrowth",
      title: "CWP Growth",
      dbColName: "CWP",
      render: (value) => <span>{value?.total}</span>,
    },
    {
      key: "avgprmr",
      title: "CWCS 2024",
      dbColName: "CWCS",
      render: (value) => <span>{value?.current}</span>,
    },
    {
      key: "avg23",
      title: "CWCS 2023",
      dbColName: "CWCS",
      render: (value) => <span>{value?.previous}</span>,
    },
    {
      key: "avggrowth",
      title: "CWCS Growth",
      dbColName: "CWCS",
      render: (value) => <span>{value?.total}</span>,
    },
    {
      key: "avgprmr",
      title: "CWL 2024",
      dbColName: "CWL",
      render: (value) => <span>{value?.current}</span>,
    },
    {
      key: "avg23",
      title: "CWL 2023",
      dbColName: "CWL",
      render: (value) => <span>{value?.previous}</span>,
    },
    {
      key: "avggrowth",
      title: "CWL Growth",
      dbColName: "CWL",
      render: (value) => <span>{value?.total}</span>,
    },
    {
      key: "avgprmr",
      title: "SWIN 2024",
      dbColName: "SWIN",
      render: (value) => <span>{value?.current}</span>,
    },
    {
      key: "avg23",
      title: "SWIN 2023",
      dbColName: "SWIN",
      render: (value) => <span>{value?.previous}</span>,
    },
    {
      key: "avggrowth",
      title: "SWIN Growth",
      dbColName: "SWIN",
      render: (value) => <span>{value?.total}</span>,
    },
    {
      key: "avgprmr",
      title: "SWL 2024",
      dbColName: "SWL",
      render: (value) => <span>{value?.current}</span>,
    },
    {
      key: "avg23",
      title: "SWL 2023",
      dbColName: "SWL",
      render: (value) => <span>{value?.previous}</span>,
    },
    {
      key: "avggrowth",
      title: "SWL Growth",
      dbColName: "SWL",
      render: (value) => <span>{value?.total}</span>,
    },
    {
      key: "avgprmr",
      title: "SWCS 2024",
      dbColName: "SWCS",
      render: (value) => <span>{value?.current}</span>,
    },
    {
      key: "avg23",
      title: "SWCS 2023",
      dbColName: "SWCS",
      render: (value) => <span>{value?.previous}</span>,
    },
    {
      key: "avggrowth",
      title: "SWCS Growth",
      dbColName: "SWCS",
      render: (value) => <span>{value?.total}</span>,
    },
    {
      key: "avgprmr",
      title: "SWP 2024",
      dbColName: "SWP",
      render: (value) => <span>{value?.current}</span>,
    },
    {
      key: "avg23",
      title: "SWP 2023",
      dbColName: "SWP",
      render: (value) => <span>{value?.previous}</span>,
    },
    {
      key: "avggrowth",
      title: "SWP Growth",
      dbColName: "SWP",
      render: (value) => <span>{value?.total}</span>,
    },
    {
      key: "avgprmr",
      title: "CCSs 2024",
      dbColName: "CCSs",
      render: (value) => <span>{value?.current}</span>,
    },
    {
      key: "avg23",
      title: "CCSs 2023",
      dbColName: "CCSs",
      render: (value) => <span>{value?.previous}</span>,
    },
    {
      key: "avggrowth",
      title: "CCSs Growth",
      dbColName: "CCSs",
      render: (value) => <span>{value?.total}</span>,
    },
    {
      key: "avgprmr",
      title: "CWCS 2024",
      dbColName: "CWCS",
      render: (value) => <span>{value?.current}</span>,
    },
    {
      key: "avg23",
      title: "CWCS 2023",
      dbColName: "CWCS",
      render: (value) => <span>{value?.previous}</span>,
    },
    {
      key: "avggrowth",
      title: "CWCS Growth",
      dbColName: "CWCS",
      render: (value) => <span>{value?.total}</span>,
    },
    {
      key: "avgprmr",
      title: "CP 2024",
      dbColName: "CP",
      render: (value) => <span>{value?.current}</span>,
    },
    {
      key: "avg23",
      title: "CP 2023",
      dbColName: "CP",
      render: (value) => <span>{value?.previous}</span>,
    },
    {
      key: "avggrowth",
      title: "CP Growth",
      dbColName: "CP",
      render: (value) => <span>{value?.total}</span>,
    },
    {
      key: "avgprmr",
      title: "CIN 2024",
      dbColName: "CIN",
      render: (value) => <span>{value?.current}</span>,
    },
    {
      key: "avg23",
      title: "CIN 2023",
      dbColName: "CIN",
      render: (value) => <span>{value?.previous}</span>,
    },
    {
      key: "avggrowth",
      title: "CIN Growth",
      dbColName: "CIN",
      render: (value) => <span>{value?.total}</span>,
    },
    {
      key: "avgprmr",
      title: "CL 2024",
      dbColName: "CL",
      render: (value) => <span>{value?.current}</span>,
    },
    {
      key: "avg23",
      title: "CL 2023",
      dbColName: "CL",
      render: (value) => <span>{value?.previous}</span>,
    },
    {
      key: "avggrowth",
      title: "CL Growth",
      dbColName: "CL",
      render: (value) => <span>{value?.total}</span>,
    },
    {
      key: "avgprmr",
      title: "CWCS 2024",
      dbColName: "CWCS",
      render: (value) => <span>{value?.current}</span>,
    },
    {
      key: "avg23",
      title: "CWCS 2023",
      dbColName: "CWCS",
      render: (value) => <span>{value?.previous}</span>,
    },
    {
      key: "avggrowth",
      title: "CWCS Growth",
      dbColName: "CWCS",
      render: (value) => <span>{value?.total}</span>,
    },
    {
      key: "avgprmr",
      title: "RWKS 2024",
      dbColName: "RWKS",
      render: (value) => <span>{value?.current}</span>,
    },
    {
      key: "avg23",
      title: "RWKS 2023",
      dbColName: "RWKS",
      render: (value) => <span>{value?.previous}</span>,
    },
    {
      key: "avggrowth",
      title: "RWKS Growth",
      dbColName: "RWKS",
      render: (value) => <span>{value?.total}</span>,
    },
    {
      key: "avgprmr",
      title: "RWS 2024",
      dbColName: "RWS",
      render: (value) => <span>{value?.current}</span>,
    },
    {
      key: "avg23",
      title: "RWS 2023",
      dbColName: "RWS",
      render: (value) => <span>{value?.previous}</span>,
    },
    {
      key: "avggrowth",
      title: "RWS Growth",
      dbColName: "RWS",
      render: (value) => <span>{value?.total}</span>,
    },
    {
      key: "avgprmr",
      title: "PBR 2024",
      dbColName: "PBR",
      render: (value) => <span>{value?.current}</span>,
    },
    {
      key: "avg23",
      title: "PBR 2023",
      dbColName: "PBR",
      render: (value) => <span>{value?.previous}</span>,
    },
    {
      key: "avggrowth",
      title: "PBR Growth",
      dbColName: "PBR",
      render: (value) => <span>{value?.total}</span>,
    },
    {
      key: "avgprmr",
      title: "CON 2024",
      dbColName: "CON",
      render: (value) => <span>{value?.current}</span>,
    },
    {
      key: "avg23",
      title: "CON 2023",
      dbColName: "CON",
      render: (value) => <span>{value?.previous}</span>,
    },
    {
      key: "avggrowth",
      title: "CON Growth",
      dbColName: "CON",
      render: (value) => <span>{value?.total}</span>,
    },
    {
      key: "avgprmr",
      title: "AWT 2024",
      dbColName: "AWT",
      render: (value) => <span>{value?.current}</span>,
    },
    {
      key: "avg23",
      title: "AWT 2023",
      dbColName: "AWT",
      render: (value) => <span>{value?.previous}</span>,
    },
    {
      key: "avggrowth",
      title: "AWT Growth",
      dbColName: "AWT",
      render: (value) => <span>{value?.total}</span>,
    },
  ];

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [filters, setFilters] = useState({
    from_date: getLastWeekDateRange().start,
    to_date: getLastWeekDateRange().end,
    filter: "Senior Regional",
    group_by: "Senior Regional",
    tenure: "All",
  });
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
  });
  useEffect(() => {
    setLoading(true);
    service
      .getProduction(filters)
      .then((resp) => {
        setData(resp.data.data);
        setTotalItems(resp.data.data.length);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [filters]);

  const last = pagination.page * pagination.limit;
  const first = last - pagination.limit;
  const current = data?.slice(first, last);

  return (
    <Table
      columns={columns}
      loadingData={loading}
      data={current}
      pagination={pagination}
      setPagination={setPagination}
      columnFilters={
        <ColumnFilters filters={filters} setFilters={setFilters} />
      }
      totalItems={totalItems}
    />
  );
};

const ColumnFilters = ({ filters, setFilters }) => {
  const filter = [
    "Rep",
    "Recruiter",
    "Team Lead",
    "Manager",
    "Senior Manager",
    "Regional",
    "Senior Regional",
    "Partner",
    "Division",
    "Evolution",
    "Vivint",
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  return (
    <div className="flex z-50 flex-wrap gap-3 mt-4">
      <div className="flex flex-1 basis-2 flex-col gap-1">
        <label className="text-xs text-gray-800">From</label>
        <input className={tabStyles.select} value={filters.from_date} type="date" name="from" />
      </div>
      <div className=" flex flex-1 basis-2 flex-col gap-1">
        <label className="text-xs text-gray-800">To</label>
        <input type="date" value={filters.to_date} name="to" className={tabStyles.select} />
      </div>

      <div className="flex flex-1 basis-2 flex-col gap-1">
        <label className="text-xs whitespace-nowrap text-gray-800 ">
          Tenure
        </label>
        <select
          name="tenure"
          onChange={handleChange}
          defaultValue={filters.tenure}
          className={tabStyles.select}
        >
          <option value="">Select</option>
          <option value="All">All</option>
          <option value="Rookie">Rookie</option>
          <option value="Sophomore">Sophomore</option>
          <option value="Veteran">Veteran</option>
        </select>
      </div>
      <div className="flex flex-1 basis-2 flex-col gap-1">
        <label className="text-xs whitespace-nowrap text-gray-800">
          Filter
        </label>
        <select
          name="filter"
          onChange={handleChange}
          defaultValue={filters.filter}
          className={tabStyles.select}
        >
          <option value="">Select</option>
          {filter.map((item) => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </select>
      </div>
      <div className="flex flex-1 basis-2 flex-col gap-1">
        <label className="text-xs whitespace-nowrap text-gray-800">
          Group By
        </label>
        <select
          name="group_by"
          onChange={handleChange}
          defaultValue={filters.group_by}
          className={tabStyles.select}
        >
          <option value="">Select</option>
          {filter.map((item) => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
