// @ts-ignore
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Modal } from "common/Modal";
import { Table } from "common/Table/Table";
import { BarChart } from "components/charts/BarChart";
import { Dashboard2Layout } from "components/dashboard2/Dashboard2Layout";
import { useRouter } from "hooks/useRouter";
import { useCallback, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import UserService from "services/user.service";

const service = new UserService();
const dateOptions = { month: "short", day: "numeric", year: "numeric" };

const defaultColumns = [
  {
    key: "name",
    title: "Name",
    dbColName: "master_closer_name",
  },
  {
    key: "customer",
    title: "Customer",
    dbColName: "master_customer",
  },
  {
    key: "lead",
    title: "Lead Source",
    dbColName: "master_lead_source",
  },
  {
    key: "enerflo_office",
    title: "Enerflo Office",
    dbColName: "master_enerflo_office",
  },
];


export const SpecificStats = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [modalData, setModalData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalDataLoading, setModalDataLoading] = useState(false)
  const [filterDate, setFilterDate] = useState({
    from_date: "Jan 1, 2020",
    to_date: new Date().toLocaleDateString("en-US", dateOptions),
  });
  const { params } = useRouter();

  const fetchData = useCallback(() => {
    setLoading(true);
    service
      .getSpecificOffice({ ...filterDate, office: params.name })
      .then((resp) => {
        setData(resp.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [filterDate, params]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleClick = (type) => {
    setShowModal(true);
    setModalDataLoading(true);
    if (type === "signed") {
      defaultColumns.push({
        key: "agreement",
        title: "Agreement",
        dbColName: "master_first_agreement_signed",
      });
    } else {
      const index = defaultColumns.findIndex(item => item.key === "agreement");
      defaultColumns.splice(index, 1)
    }
    service
      .getOfficeList({ ...filterDate, type, office: params.name })
      .then((resp) => {
        setModalDataLoading(false)
        setModalData(resp.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const series2 = {
    series: [44, 55],
    options: {
      labels: ["Active", "Cancel"],
      chart: {
        width: 100,
        type: "donut",
      },
      dataLabels: {
        enabled: true,
      },

      colors: ["#FFBB70", "#FC4100"],
      responsive: [
        {
          breakpoint: 280,
          options: {
            chart: {
              width: 100,
            },
            legend: {
              show: false,
            },
          },
        },
      ],
      width: 20,

      legend: {
        show: false,
      },
    },
  };

  const treemap = {
    series: [
      {
        data: [
          {
            x: "New Delhi",
            y: 218,
          },

          {
            x: "Mumbai",
            y: 184,
          },

          {
            x: "Surat",
            y: 44,
          },
          {
            x: "Hyderabad",
            y: 68,
          },
          {
            x: "Lucknow",
            y: 28,
          },
          {
            x: "Indore",
            y: 19,
          },
          {
            x: "Kanpur",
            y: 29,
          },
        ],
      },
    ],
    options: {
      legend: {
        show: false,
      },
      chart: {
        height: 350,
        type: "treemap",
      },
      title: {
        text: "Active TCR",
      },
    },
  };

  return (
    <Dashboard2Layout>
      <div className=" w-full bg-white shadow-sm  z-10 p-4">
        <p className="text-center text-5xl text-gray-800 font-semibold">
          {params?.name}
        </p>
        <button className="pr-4 text-gray-500 focus:outline-none lg:hidden">
          <span className="sr-only">Open sidebar</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            />
          </svg>
        </button>
        <div className="flex items-center justify-between w-full gap-3">
          {/* Profile dropdown */}
          <div />
        </div>
      </div>
      <div className="border-t shadow-sm flex items-center justify-between bg-white border-t-gray-200">
        <div className="flex items-center  p-3 gap-4">
          <p className="text-sm text-gray-800">Select Period:</p>
          <input
            type="date"
            onChange={(e) =>
              setFilterDate({
                ...filterDate,
                from_date: new Date(e.target.value).toLocaleDateString(
                  "en-US",
                  dateOptions
                ),
              })
            }
            className="text-sm px-3 py-2 rounded-md border border-gray-200"
          />
          <input
            type="date"
            onChange={(e) =>
              setFilterDate({
                ...filterDate,
                to_date: new Date(e.target.value).toLocaleDateString(
                  "en-US",
                  dateOptions
                ),
              })
            }
            className="text-sm px-3 py-2 rounded-md border border-gray-200"
          />
        </div>
        <button
          type="button"
          className="mr-4 bg-green-400 text-white rounded-lg px-4 py-2 text-sm"
          onClick={fetchData}
        >
          Filter
        </button>
      </div>
      {loading ? (
        <p className="p-5">Loading...</p>
      ) : (
        <div className="p-5">
          <div className="flex flex-wrap gap-4">
            <div
              className="flex-1 bg-white rounded-lg p-4 shadow-sm cursor-pointer"
              onClick={() => handleClick("leads")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-10 h-10"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"
                />
              </svg>

              <p className="text-gray-800 font-medium mt-1">Leads</p>
              <h2 className="font-semibold text-3xl text-gray-800 mt-1">
                {data?.leadsCount}
              </h2>
            </div>
            <div className="flex-1 bg-white rounded-lg p-4 shadow-sm">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-10 h-10"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 3v11.25A2.25 2.25 0 0 0 6 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0 1 18 16.5h-2.25m-7.5 0h7.5m-7.5 0-1 3m8.5-3 1 3m0 0 .5 1.5m-.5-1.5h-9.5m0 0-.5 1.5M9 11.25v1.5M12 9v3.75m3-6v6"
                />
              </svg>

              <p className="text-gray-800 font-medium mt-1">Pitches</p>
              <h2 className="font-semibold text-3xl text-gray-800 mt-1">
                {data?.Pitches}
              </h2>
            </div>
            <div
              className="flex-1 bg-white rounded-lg p-4 shadow-sm cursor-pointer"
              onClick={() => handleClick("signed")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-10 h-10"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0 0 20.25 18V6A2.25 2.25 0 0 0 18 3.75H6A2.25 2.25 0 0 0 3.75 6v12A2.25 2.25 0 0 0 6 20.25Z"
                />
              </svg>

              <p className="text-gray-800 font-medium mt-1">Contracts Signed</p>
              <h2 className="font-semibold text-3xl text-gray-800 mt-1">
                {data?.Signed}
              </h2>
            </div>
            <div className="flex-1 bg-white rounded-lg p-4 shadow-sm">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-10 h-10"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0 1 18 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3 1.5 1.5 3-3.75"
                />
              </svg>

              <p className="text-gray-800 font-medium mt-1">
                Permits Submitted
              </p>
              <h2 className="font-semibold text-3xl text-gray-800 mt-1">
                {data?.Permits}
              </h2>
            </div>
            <div className="flex-1 bg-white rounded-lg p-4 shadow-sm">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-10 h-10"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 0 1-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0 1 12 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5"
                />
              </svg>

              <p className="text-gray-800 font-medium mt-1">Installs</p>
              <h2 className="font-semibold text-3xl text-gray-800 mt-1">
                {data?.Installs}
              </h2>
            </div>
            <div className="flex-1 bg-white rounded-lg p-4 shadow-sm">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-10 h-10"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.25 21v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21m0 0h4.5V3.545M12.75 21h7.5V10.75M2.25 21h1.5m18 0h-18M2.25 9l4.5-1.636M18.75 3l-1.5.545m0 6.205 3 1m1.5.5-1.5-.5M6.75 7.364V3h-3v18m3-13.636 10.5-3.819"
                />
              </svg>

              <p className="text-gray-800 font-medium mt-1">FP</p>
              <h2 className="font-semibold text-3xl text-gray-800 mt-1">
                {data?.fpTotal}
              </h2>
            </div>
          </div>
          <div className="flex flex-col md:flex-row flex-wrap gap-2 mt-4">
            <div className="bg-white w-full mx-auto md:w-80 shadow-sm rounded-lg p-3">
              <p className="text-gray-800 text-sm">Deals Status</p>
              <div className="flex flex-col md:flex-row h-full items-center jusitfy-center">
                <ReactApexChart
                  height={240}
                  options={series2.options}
                  series={[
                    +data?.dealStatus?.active_percent ?? 0,
                    +data?.dealStatus?.cancel_percent ?? 0,
                  ]}
                  type="donut"
                  width={290}
                />
              </div>
            </div>

            <div className="flex-1 p-3 h-80 overflow-auto border border-gray-200 rounded-lg">
              <table className="min-w-full">
                <thead className="bg-gray-300">
                  <tr className="text-gray-800">
                    <th className="p-1.5">Sales Rep</th>
                    <th className="p-1.5">Leads</th>
                    <th className="p-1.5">Pitches</th>
                    <th className="p-1.5">Pitch % </th>
                    <th className="p-1.5">Contracts Signed</th>
                    <th className="p-1.5">Close %</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {data?.office_reps.map((item, index) => (
                    <tr key={index}>
                      <td className="p-1.5 text-center">{item.name}</td>
                      <td className="p-1.5 text-center">{item.leads}</td>
                      <td className="p-1.5 text-center">{item.pitches}</td>
                      <td className="p-1.5 text-center">
                        {item.pitch_percentage} %
                      </td>
                      <td className="p-1.5 text-center">
                        {item.contract_sgned}
                      </td>
                      <td className="p-1.5 text-center">
                        {item.close_percentage}%
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {/* <div className="border w-full rounded-lg md:w-1/2 border-zinc-200 flex-1 p-3">
            <p className="text-gray-800 text-sm">Leads - Previous 6 Weeks</p>
            <BarChart
              labels={
                data?.fixedleadChartData?.map((item) => item.week) ?? []
              }
              values={
                data?.fixedleadChartData?.map((item) => item.value) ?? []
              }
            />
          </div>
          <div className="border w-full rounded-lg md:w-1/2 border-zinc-200 flex-1 p-3">
            <p className="text-gray-800 text-sm">
              Pitches - Previous 6 Weeks
            </p>
            <BarChart
              labels={
                data?.fixedpitchesChartData?.map((item) => item.week) ?? []
              }
              values={
                data?.fixedpitchesChartData?.map((item) => item.value) ?? []
              }
            />
          </div>
          <div className="border w-full rounded-lg md:w-1/2 border-zinc-200 flex-1 p-3">
            <p className="text-gray-800 text-sm">
              Contracts Signed - Previous 6 Weeks
            </p>
            <BarChart
              labels={
                data?.fixedagreementChartData?.map((item) => item.week) ?? []
              }
              values={
                data?.fixedagreementChartData?.map((item) => item.value) ?? []
              }
            />
          </div> */}
          </div>
          <div className="flex flex-col md:flex-row flex-wrap gap-2 mt-4">
            <div className="rounded-lg w-full md:w-80 shadow-sm p-3 bg-white">
              <ReactApexChart
                options={treemap.options}
                series={treemap.series}
                type="treemap"
                height={240}
              />
            </div>
            <div className="border w-full rounded-lg md:w-1/2 border-zinc-200 flex-1 p-3">
              <p className="text-gray-800 text-sm">Leads by Source</p>
              <BarChart
              name="Leads"
                labels={
                  data?.leadChartData
                    ?.filter((item) => item._id && item._id)
                    .map((item) => item._id) ?? []
                }
                values={data?.leadChartData?.map((item) => item.value) ?? []}
              />
            </div>
            <div className="border w-full rounded-lg md:w-1/2 border-zinc-200 flex-1 p-3">
              <p className="text-gray-800 text-sm">Pitches by Source</p>
              <BarChart
              name="Pitches"
                labels={
                  data?.pitchesChartData
                    ?.filter((item) => item._id && item._id)
                    .map((item) => item._id) ?? []
                }
                values={data?.pitchesChartData?.map((item) => item.value) ?? []}
              />
            </div>
            <div className="border w-full rounded-lg md:w-1/2 border-zinc-200 flex-1 p-3">
              <p className="text-gray-800 text-sm">Installs by Source</p>
              <BarChart
              name="Installs"
                labels={
                  data?.agreementChartData
                    ?.filter((item) => item._id && item._id)
                    .map((item) => item._id) ?? []
                }
                values={
                  data?.agreementChartData?.map((item) => item.value) ?? []
                }
              />
            </div>
          </div>
          <div className="bg-white w-full  md:w-80 shadow-sm rounded-lg p-3 mt-3">
            <p className="text-gray-800 text-sm">SH Attachment Rate</p>
            <div className="flex flex-col md:flex-row items-center justify-center">
              <ReactApexChart
                options={series2.options}
                series={series2.series}
                type="donut"
                width={240}
                height={240}
              />
            </div>
          </div>
        </div>
      )}

      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        title="Table Data"
        width="w-2/3"
      >
        <Table
          columns={defaultColumns}
          showPagination={false}
          data={modalData}
          loadingData={modalDataLoading}
          exportButton={false}
          totalItems={modalData}
          setData={setModalData}
        />
      </Modal>
    </Dashboard2Layout>
  )
}
