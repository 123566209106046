import classNames from "classnames";
import { FC } from "react";
import { Link } from "react-router-dom"
import { useLocation } from "react-router-dom";

export const Sidebar2: FC = () => {
  const { pathname } = useLocation();

  return (
    <div className="flex md:flex-shrink-0 h-full ">
      {/*{!user && <SmallLoader />}*/}
      <div className="flex flex-col w-20 bg-white shadow-sm overflow-x-hidden">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex flex-col flex-grow drop-shadow-md  overflow-y-auto">
          <div className="flex-grow flex flex-col">
            <nav className="flex-1 mt-3 overflow-x-hidden">
              <AdminSidebar pathname={pathname} />
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

const AdminSidebar = ({ pathname }) => {
  return (
    <div className="flex flex-col gap-y-8 items-center h-full">
      <div className="h-14 w-14">
        <img alt="logo" className="h-full w-full" src="/images/favicon2.png" />
      </div>
      <Link to="/dashboard2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className={classNames("w-8 h-8", {
            "text-primary": pathname === "/dashboard2"
          })}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
          />
        </svg>
      </Link>
      <Link to="/dashboard2/stats">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className={classNames("w-8 h-8", {
            "text-primary": pathname.includes("/dashboard2/")
          })}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3.75 3v11.25A2.25 2.25 0 0 0 6 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0 1 18 16.5h-2.25m-7.5 0h7.5m-7.5 0-1 3m8.5-3 1 3m0 0 .5 1.5m-.5-1.5h-9.5m0 0-.5 1.5m.75-9 3-3 2.148 2.148A12.061 12.061 0 0 1 16.5 7.605"
          />
        </svg>
      </Link>
      <Link>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-8 h-8"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"
          />
        </svg>
      </Link>
    </div>
  );
};
