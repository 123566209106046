import { useNotifications } from "hooks/useNotification";
import { useThunkDispatch } from "hooks/useThunkDispatch";
import { useState } from "react";
import { verifyOtp } from "redux/actions/user";
import AuthService from "services/auth.service";

const service = new AuthService();

export const Otp = () => {
  const [otp, setOtp] = useState(Array(6).fill(""));

  const dispatch = useThunkDispatch();
  const { successMessage } = useNotifications();

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;

    const newOtp = [...otp];
    newOtp[index] = element.value;
    setOtp(newOtp);

    // Focus on next input field
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key === "Backspace" && otp[index] === "" && index !== 0) {
      event.preventDefault();
      const newOtp = [...otp];
      newOtp[index - 1] = "";
      setOtp(newOtp);

      if (event.target.previousSibling) {
        event.target.previousSibling.focus();
      }
    }
  };

  const handlePaste = (event) => {
    const paste = event.clipboardData.getData("text");
    if (!/^\d{6}$/.test(paste)) {
      return;
    }

    const newOtp = paste.split("");
    setOtp(newOtp);
    event.preventDefault();

    // Automatically submit the form after pasting
    // handleSubmit(event);
  };

  const resendCode = () => {
    service
      .resendOtp({ email: "" })
      .then((resp) => {
        console.log(resp);
        successMessage("New OTP has been sent");
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(verifyOtp({ code: otp.join("") }));
  };

  return (
    <div className="h-screen flex flex-col items-center justify-center bg-black">
      <div className="w-56 md:w-72 mx-auto">
        <img
          alt="logo"
          src="/images/logo.png"
          className="h-full w-full mx-auto object-contain"
        />
      </div>
      <div className="max-w-lg  text-center bg-black px-4 sm:px-8 py-10 rounded-xl shadow">
        <header className="mb-8">
          <h1 className="text-2xl text-white font-bold mb-1">Email Verification</h1>
          <p className="text-[15px] text-slate-400">
            Enter the 6-digit verification code that was sent to your email
          </p>
        </header>
        <form onSubmit={handleSubmit}>
          <div className="flex items-center justify-between gap-3">
            {otp.map((data, index) => (
              <input
                type="text"
                key={index}
                maxLength={1}
                value={data}
                onChange={(e) => handleChange(e.target, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                onPaste={handlePaste}
                className="w-14 h-14 text-center text-2xl font-extrabold border text-zinc-100 bg-black  border-gray-100 hover:border-slate-200 appearance-none rounded p-4 outline-none  focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100"
                pattern="\d*"
              />
            ))}
          </div>
          <div className=" mt-4">
            <button
              type="submit"
              className="w-full  justify-center whitespace-nowrap rounded-lg bg-primary px-4 py-2.5 text-sm font-medium text-white shadow-sm shadow-indigo-950/10  focus:outline-none focus:ring focus:ring-indigo-300 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 transition-colors duration-150"
            >
              Verify Account
            </button>
          </div>
        </form>
        <div className="text-sm text-slate-400 mt-4">
          Didn't receive code?{" "}
          <button onClick={resendCode} className="font-medium text-primary">
            Resend
          </button>
        </div>
      </div>
    </div>
  );
};
