import { Table } from "common/Table/Table";
import { Fragment, useEffect, useState } from "react";
import UserService from "services/user.service";

const service = new UserService();

export const Ecards = () => {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
    const [totalItems, setTotalItems] = useState(0)

  const columns = [
    { key: "first_name", dbColName: "first_name", title: "first name" },
    { key: "last_name", dbColName: "last_name", title: "last name" },
    { key: "email", dbColName: "email", title: "email" },
    { key: "phone", dbColName: "phone", title: "phone" },
    { key: "zip_code", dbColName: "zip_code", title: "zip code" },
    { key: "details", dbColName: "details", title: "details" },
    { key: "to_badgeid", dbColName: "to_badgeid", title: "to badgeid" },
    { key: "createdAt", dbColName: "createdAt", title: "created At", render: date => new Date(date).toLocaleDateString() },
  ];

  const [pagination, setPagination] = useState({
    pageNumber: 1,
    limit: 100,
    startDate: '',
    endDate: '',
  });

  useEffect(() => {
    setLoading(true);
    service
      .getEcards(pagination)
      .then((resp) => {
        if(resp.data.success) {
          
          setData(resp.data.data)
          setTotalItems(resp.data.total_docs)
        } else {
          setData([])
        }
      })
      .catch((err) => {
        console.log(err);
        setData([])
      })
      .finally(() => {
        setLoading(false);
      });
  }, [pagination]);

  return (
    <Fragment>
      <div className="flex flex-col mb-4">
        <h1 className="font-medium text-gray-700 tracking-wide text-xl">
          Ecards
        </h1>
        <p className="text-gray-500 text-sm">View all Ecards</p>
      </div>
      <Table
        data={data}
        loadingData={loading}
        pagination={pagination}
        setPagination={setPagination}
        setData={setData}
        columnFilters={
          <ColumnFilters
            pagination={pagination}
            setPagination={setPagination}
          />
        }
        totalItems={totalItems}
        columns={columns}
        exportButton={false}
      />
    </Fragment>
  );
};

const ColumnFilters = ({ pagination, setPagination }) => {
  return (
    <div className="flex gap-4 mt-2">
      <div className="flex-1">
        <label className="text-xs text-gray-700">Start Date</label>
        <input
          className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
          type="date"
          value={pagination.startDate}
          onChange={(e) =>
            setPagination({
              ...pagination,
              startDate: e.target.value,
            })
          }
        />
      </div>

      <div className="flex-1">
        <label className="text-xs text-gray-700">End Date</label>
        <input
          placeholder="Start date"
          className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
          type="date"
          value={pagination.endDate}
          onChange={(e) => {
            setPagination({
              ...pagination,
              endDate: e.target.value,
            });
          }}
        />
      </div>
    </div>
  );
};
