import { Fragment, useEffect, useState } from "react";
import { Table } from "common/Table/Table";
import UserService from "services/user.service";
import { Filters } from "pages/Opportunities";
import { exportToCSV } from "utils/funcs";

const service = new UserService();

export const Tech = () => {
  const columns = [
    
    {
      key: "OFFICE_NAME",
      title: "OFFICE NAME",
      label: "OFFICE NAME",
      dbColName: "OFFICE_NAME",
      freeze: true,
      freezeIndex: 'left-0'
    },
    {
      key: "OFFICE_TYPE",
      title: "OFFICE type",
      label: "OFFICE type",
      dbColName: "OFFICE_TYPE",
      freeze: true,
      freezeIndex: 'left-40'
    },
    {
      key: "DIVISION_NAME",
      title: "Division",
      label: "Division",
      dbColName: "DIVISION_NAME",
    },
    {
      key: "TECH_MANAGER_NAME",
      title: "TECH MANAGER",
      label: "TECH MANAGER",
      dbColName: "TECH_MANAGER_NAME",
    },
    {
      key: "TECH_MANAGER_PHONE",
      title: "TECH MANAGER PHONE",
      label: "TECH MANAGER PHONE",
      dbColName: "TECH_MANAGER_PHONE",
    },
    {
      key: "TECH_REGIONAL_NAME",
      title: "TECH REGIONAL NAME",
      label: "TECH REGIONAL NAME",
      dbColName: "TECH_REGIONAL_NAME",
    },
    {
      key: "TECH_REGIONAL_PHONE",
      title: "TECH REGIONAL PHONE",
      label: "TECH REGIONAL PHONE",
      dbColName: "TECH_REGIONAL_PHONE",
    },
  ];

  const [data, setData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    year: 2023,
  });

  useEffect(() => {
    setLoading(true);
    service
      .getTechOffices(pagination)
      .then((resp) => {
        setData(resp.data.data);
        setTotalItems(resp.data.total_docs);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [pagination]);

  const handleExport = () => {
    exportToCSV(data, "tech")
  }

  return (
    <Fragment>
      <div className="flex flex-col mb-4">
        <h1 className="font-semibold text-gray-800 tracking-wide text-xl">
          Tech
        </h1>
        <p className="text-gray-500 text-sm">View all Tech offices</p>
      </div>
      <Table
        data={data}
        pagination={pagination}
        setPagination={setPagination}
        handleExport={handleExport}
        loadingData={loading}
        filters={
          <Filters pagination={pagination} setPagination={setPagination} />
        }
        totalItems={totalItems}
        columns={columns}
      />
    </Fragment>
  );
};
