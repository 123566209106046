import { Table } from "common/Table/Table";
import { exportToCSV, formatDate } from "utils/funcs";
import { Fragment, useCallback, useEffect, useState } from "react";
import UserService from "services/user.service";
import QRCode from "react-qr-code";
import { Modal } from "common/Modal";
import { useDebounce } from "hooks/useDebounce";
import { AddTrainingModal } from "components/AddTrainingModal";
import { useLocation } from "hooks/useLocations";
import classNames from "classnames";
import { tabStyles } from "pages/GeneralData";
import { TrainingReports } from "components/weekly/TrainingReports";
import { Attendees } from "components/weekly/Attendees";
import { useRouter } from "hooks/useRouter";
import { useNotifications } from "hooks/useNotification";
import { useSelector } from "react-redux";
import { userSelector } from "redux/selectors/user";
import { Tooltip } from "common/Tooltip";

const service = new UserService();

export const WeeklyTrainings = () => {
  const [qrCode, setQrCode] = useState("");
  const [showModal, setShowModal] = useState(false);

  const user = useSelector(userSelector);
  const router = useRouter()

  const columns = [
    {
      key: "badge_id",
      dbColName: "badge_id",
      title: "badge id",
      freeze: true,
      freezeIndex: "left-0",
    },
    {
      key: "creator_name",
      dbColName: "creator_name",
      title: "creator name",
      freeze: true,
      freezeIndex: "left-20",
    },

    { key: "location", dbColName: "location", title: "location" },
    { key: "manager_id", dbColName: "manager_id", title: "manager id" },
    // { key: "manager_name", dbColName: "manager_name", title: "manager name" },
    { key: "meeting_date", dbColName: "meeting_date", title: "meeting date" },
    { key: "trainer_name", dbColName: "trainer_name", title: "trainer name" },
    { key: "title", dbColName: "title", title: "title" },
    // { key: "address", dbColName: "address", title: "address" },
    // {
    //   key: "manager_id",
    //   title: "Leader Badge id",
    //   dbColName: "manager_id",
    //   label: "manager id",
    // },

    {
      key: "highlights",
      title: "highlights",
      dbColName: "highlights",
      label: "highlights",
      render: (highlight) => (
        <Tooltip message={highlight}>
          {highlight && highlight?.toString()?.slice(0, 20)}
        </Tooltip>
      ),
    },

    // { key: "meet_link", dbColName: "meet_link", title: "meet_link" },
    { key: "type", dbColName: "type", title: "type" },
    {
      key: "meeting_start_time",
      dbColName: "meeting_start_time",
      title: "meeting start time",
    },
    {
      key: "meeting_end_time",
      dbColName: "meeting_end_time",
      title: "meeting end time",
    },
    { key: "zip_code", dbColName: "zip_code", title: "zip_code" },
    { key: "meetid", dbColName: "meetid", title: "meetid" },
    { key: "file_link", dbColName: "file_link", title: "file link" },
    { key: "qr_code", dbColName: "qr_code", title: "qr code" },
    { key: "time_zone", dbColName: "time_zone", title: "time zone" },
    {
      key: "meeting_date_and_time_start",
      dbColName: "meeting_date_and_time_start",
      title: "meeting date and time start",
    },
    {
      key: "meeting_date_and_time_end",
      dbColName: "meeting_date_and_time_end",
      title: "meeting date and time end",
    },
    { key: "creator_id", dbColName: "creator_id", title: "creator id" },
    {
      key: "creator_phone",
      dbColName: "creator_phone",
      title: "creator phone",
    },
    {
      key: "creator_email",
      dbColName: "creator_email",
      title: "creator email",
    },
    {
      key: "team_leader_id",
      dbColName: "team_leader_id",
      title: "team leader id",
    },
    {
      key: "team_leader_name",
      dbColName: "team_leader_name",
      title: "team leader name",
    },
    {
      key: "team_leader_phone_number",
      dbColName: "team_leader_phone_number",
      title: "team leader phone number",
    },
    {
      key: "team_leader_email",
      dbColName: "team_leader_email",
      title: "team_leader_email",
    },
    {
      key: "management_group_leader_id",
      dbColName: "management_group_leader_id",
      title: "management group leader id",
    },
    {
      key: "management_group_leader_name",
      dbColName: "management_group_leader_name",
      title: "management group leader name",
    },
    {
      key: "management_group_leader_phone_number",
      dbColName: "management_group_leader_phone_number",
      title: "management group leader phone number",
    },
    {
      key: "management_group_leader_email",
      dbColName: "management_group_leader_email",
      title: "management group leader email",
    },
    {
      key: "senior_management_group_leader_id",
      dbColName: "senior_management_group_leader_id",
      title: "senior management group leader id",
    },
    {
      key: "senior_management_group_leader",
      dbColName: "senior_management_group_leader",
      title: "senior management group leader",
    },
    {
      key: "senior_management_group_leader_phone_number",
      dbColName: "senior_management_group_leader_phone_number",
      title: "senior management group leader phone number",
    },
    {
      key: "senior_management_group_leader_email",
      dbColName: "senior_management_group_leader_email",
      title: "senior management group leader email",
    },
    {
      key: "region_leader_id",
      dbColName: "region_leader_id",
      title: "region leader id",
    },
    {
      key: "region_leader_name",
      dbColName: "region_leader_name",
      title: "region leader name",
    },
    {
      key: "region_leader_phone_number",
      dbColName: "region_leader_phone_number",
      title: "region leader phone number",
    },
    {
      key: "region_leader_email",
      dbColName: "region_leader_email",
      title: "region leader email",
    },
    {
      key: "senior_region_id",
      dbColName: "senior_region_id",
      title: "senior region id",
    },
    {
      key: "senior_region_name",
      dbColName: "senior_region_name",
      title: "senior region name",
    },
    {
      key: "senior_region_leader_phone_number",
      dbColName: "senior_region_leader_phone_number",
      title: "senior region leader phone number",
    },
    {
      key: "senior_region_leader_email",
      dbColName: "senior_region_leader_email",
      title: "senior region leader email",
    },
    {
      key: "partner_leader_id",
      dbColName: "partner_leader_id",
      title: "partner leader id",
    },
    {
      key: "partner_leader_name",
      dbColName: "partner_leader_name",
      title: "partner leader name",
    },
    {
      key: "partner_leader_phone_number",
      dbColName: "partner_leader_phone_number",
      title: "partner leader phone number",
    },
    {
      key: "partner_leader_email",
      dbColName: "partner_leader_email",
      title: "partner leader email",
    },

    // {
    //   key: "Qrcode",
    //   title: "QR CODE",
    //   label: "QR",
    //   render: (value: { type: string; meet_link: string }) => {
    //     const array = value.meet_link?.split("/");
    //     let link = "";

    //     if (value.type === "Weekly Training") {
    //       link = `https://weeklytrainings.evolutionsmarthome.com/raffle/${
    //         array[array.length - 1]
    //       }`;
    //     } else if (value.type === "Bootcamp | Solar") {
    //       link = `https://solarbootcamp.evolutionsmarthome.com/raffle/${
    //         array[array.length - 1]
    //       }`;
    //     } else if (value.type === "Bootcamp | Smart Home") {
    //       link = `https://shbootcamp.evolutionsmarthome.com/raffle/${
    //         array[array.length - 1]
    //       }`;
    //     }

    //     return (
    //       <button
    //         type="button"
    //         onClick={() => {
    //           setShowModal(true);
    //           setQrCode(link);
    //         }}
    //         className="text-xs rounded-md px-4 py-2 text-white bg-primary"
    //       >
    //         View QR
    //       </button>
    //     );
    //   },
    // },
    {
      key: "action",
      dbColName: "_id",
      freeze: true,
      freezeIndex: "right-0",
      title: "action",
      render: (id) => {
        return (
          user?.admin === "admin" && (
            <div className="flex items-center gap-4 justify-center">
                <button type="button" onClick={() => router.push(`/weekly-trainings/${id}`)} className="text-blue-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
              </button>
              <button
                type="button"
                className="text-red-500"
                onClick={() => handleDelete(id)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                  />
                </svg>
              </button>
            
            </div>
          )
        );
      },
    },
  ];

  const { push, query } = useRouter();

  const [data, setData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [addModal, setAddModal] = useState(false);
  const [state, setState] = useState({
    type: "",
    badge_id: "",
    trainer_name: "",
    manager_id: "",
    title: "",
    highlights: "",
    zip_code: "",
    meeting_start_time: "07:00 PM",
    meeting_end_time: "08:00 PM",
    address: "",
    location: "",
    manager_name: "",
    meeting_date: "",
  });
  const [loading, setLoading] = useState(false);
  const { successMessage } = useNotifications();

  const [pagination, setPagination] = useState({
    pageNumber: 1,
    limit: 100,
    type: "",
    leaderName: "",
    location: "",
    startDate: "",
    endDate: "",
  });

  const debounced = useDebounce(pagination, 500);

  const fetchData = useCallback(() => {
    setLoading(true);
    service
      .getWeeklyTrainings(debounced)
      .then((resp) => {
        setData(
          resp.data.data.map((item) => ({
            ...item,
            meeting_date: formatDate(item.meeting_date),
          }))
        );
        setTotalItems(resp.data.total_docs);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [debounced]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleExport = () => {
    exportToCSV(data, "trainings");
  };

  const handleDelete = (id) => {
    service
      .deleteTraining(id)
      .then((resp) => {
        if (resp.data.success) {
          const shallowCopy = [...data];
          const index = shallowCopy.findIndex(
            (item: { _id: string }) => item._id === id
          );
          shallowCopy.splice(index, 1);
          setData(shallowCopy);
          successMessage("Training deleted successfully");
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <Fragment>
      <div className="text-sm mb-4 p-2 text-center text-gray-500 rounded-md bg-gray-200">
        <div className="flex flex-wrap -mb-px gap-3">
          <button
            type="button"
            onClick={() => push("/weekly-trainings?tab=Attendees")}
            className={classNames(tabStyles.default, {
              [tabStyles.active]: query.tab === "Attendees",
            })}
          >
            Attendees
          </button>
          <button
            type="button"
            onClick={() => push("/weekly-trainings?tab=Reports")}
            className={classNames(tabStyles.default, {
              [tabStyles.active]: query.tab === "Reports",
            })}
          >
            Training Reports
          </button>
          <button
            type="button"
            onClick={() => push("/weekly-trainings")}
            className={classNames(tabStyles.default, {
              [tabStyles.active]: !query.tab,
            })}
          >
            Weekly Trainings
          </button>
        </div>
      </div>
      {!query.tab && (
        <div className="flex flex-col mb-4">
          <div className="flex justify-between items-center ">
            <div>
              <h1 className="font-medium text-gray-700 tracking-wide text-xl">
                Weekly Trainings
              </h1>
              <p className="text-gray-500 text-sm">View all weekly trainings</p>
            </div>
            <button
              onClick={() => setAddModal(true)}
              className="bg-black drop-shadow-md text-white rounded-md p-2.5 text-sm flex items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 6v12m6-6H6"
                />
              </svg>
              Add Training
            </button>
          </div>
        </div>
      )}
      {!query.tab ? (
        <Table
          data={data}
          handleExport={handleExport}
          setData={setData}
          exportButton={false}
          pagination={pagination}
          setPagination={setPagination}
          loadingData={loading}
          totalItems={totalItems}
          columns={columns}
          columnFilters={
            <ColumnFilters
              pagination={pagination}
              setPagination={setPagination}
            />
          }
        />
      ) : query.tab === "Reports" ? (
        <TrainingReports />
      ) : query.tab === "Attendees" ? (
        <Attendees />
      ) : null}

      <Modal
        title="QR Code"
        width="w-full md:w-1/3"
        showModal={showModal}
        setShowModal={setShowModal}
      >
        <QRCode
          size={256}
          className="h-full w-full"
          value={qrCode}
          viewBox={`0 0 256 256`}
        />
      </Modal>
      <AddTrainingModal
        showModal={addModal}
        state={state}
        setState={setState}
        setShowModal={setAddModal}
        data={data}
        setData={setData}
      />
    </Fragment>
  );
};

const ColumnFilters = ({ pagination, setPagination }) => {
  const { locations } = useLocation();

  return (
    <div className="flex gap-4 mt-3">
      <select
        placeholder="Type"
        defaultValue={pagination.type}
        className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        onChange={(e) => {
          setPagination({
            ...pagination,
            type: e.target.value,
          });
        }}
      >
        <option value="">Select Type</option>
        <option value="Weekly Training">Weekly Training</option>
        <option value="Bootcamp | Smart Home">Bootcamp | Smart Home</option>
        <option value="Bootcamp | Solar">Bootcamp | Solar</option>
      </select>
      <input
        className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        type="text"
        placeholder="Search by Leader"
        onChange={(e) => {
          setPagination({
            ...pagination,
            leaderName: e.target.value,
          });
        }}
      />
      <select
        placeholder="Stage"
        className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        onChange={(e) => {
          setPagination({
            ...pagination,
            location: e.target.value,
          });
        }}
      >
        <option value="">Select Location</option>
        {locations.map((item) => (
          <option key={item.leader_id} value={item?.office_name}>
            {item?.office_name}
          </option>
        ))}
      </select>

      <input
        className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        type="date"
        onChange={(e) =>
          setPagination({
            ...pagination,
            startDate: e.target.value,
          })
        }
      />
      <input
        className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        type="date"
        onChange={(e) =>
          setPagination({
            ...pagination,
            endDate: e.target.value,
          })
        }
      />
      <select
        placeholder="Hierarcy Filter"
        className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        onChange={(e) => {
          setPagination({
            ...pagination,
            hierarcyFilter: e.target.value,
          });
        }}
      >
        <option disabled selected value="">
          Hierarchy
        </option>
        <option value="Partner">Partner</option>
        <option value="Manager">Manager</option>
        <option value="Senior Manager">Senior Manager</option>
        <option value="Regional">Regional</option>
      </select>
    </div>
  );
};
