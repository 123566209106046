import BaseService from "./base.service"

class AuthService extends BaseService {
    async login(data: { email: string; password: string }) {
        return await this.post("/helper/admin", data)
    }

    async verifyOtp(data: { email: string; code: string }) {
        return await this.post("/helper/verify_auth_code", data);
      }
    async resendOtp(data: { email: string; }) {
        return await this.post("/helper/resendcode", data);
      }
}

export default AuthService
