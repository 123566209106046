import React, { FC } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";

type Props = {
  active: boolean;
  icon?: React.ReactNode;
  label: string;
  to: string;
  target?: string;
  className?: string;
};

export const NavLink: FC<Props> = ({ active, to, icon, label, className, target }) => {
  return (
    <div
      className={cn("flex items-center group", {
        "hover:bg-gray-300 hover:bg-opacity-20": !active,
      })}
    >
      <div
        className={cn({
          "w-0.5 h-8  bg-primary rounded-md": active,
        })}
      ></div>
      <Link
        to={to}
        target={target}
        className={cn("flex py-1 px-4 items-center w-full " + className)}
      >
        <div
          className={cn(
            "flex text-sm items-center font-light tracking-wide gap-x-6 text-white w-full rounded-lg p-3",
            {
              "bg-gray-300 bg-opacity-20": active,
            }
          )}
        >
          {/* {icon} */}
          {label}
        </div>
      </Link>
    </div>
  );
};
