import classNames from "classnames";
import { useNotifications } from "hooks/useNotification";
import { useState } from "react";
import { useSelector } from "react-redux";
import { userSelector } from "redux/selectors/user";
import UserService from "services/user.service";
import { tabStyles } from "./GeneralData";
import { Users } from "./account/Users";
import { Feedbacks } from "./Feedbacks";

const service = new UserService();

export const Settings = () => {
  const user = useSelector(userSelector);
  const [tab, setTab] = useState('Accounts')

  const [state, setState] = useState<any>({
    userid: user?._id,
    newpassword: "",
    oldpassword: "",
    confirmPassword: "",
  });
  const [error, setError] = useState("");

  const { successMessage, errorMessage } = useNotifications();

  const handleChange = (e) => {
    const { value, name } = e.target;

    setState({
      ...state,
      [name]: value,
    });
    setError("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!state.newpassword || state.newpassword !== state.confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    delete state.confirmPassword;

    service
      .changePassword(state)
      .then((resp) => {
        if (!resp?.data?.status) {
          errorMessage(resp?.data?.message);
        } else {
          successMessage("Password updated successfully");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <div className="text-sm mb-4 p-2 text-center text-gray-500 rounded-md bg-gray-200">
        <div className="flex flex-wrap -mb-px gap-3">
          <button
            type="button"
            onClick={() => setTab("Accounts")}
            className={classNames(tabStyles.default, {
              [tabStyles.active]: tab === "Accounts",
            })}
          >
            Accounts
          </button>
          <button
            type="button"
            onClick={() => setTab("Password")}
            className={classNames(tabStyles.default, {
              [tabStyles.active]: tab === "Password",
            })}
          >
            Change Password
          </button>
          <button
            type="button"
            onClick={() => setTab("Feedback")}
            className={classNames(tabStyles.default, {
              [tabStyles.active]: tab === "Feedback",
            })}
          >
            Feedback
          </button>
        </div>
      </div>
    {tab === "Password" ? <div className="flex flex-col h-[calc(100vh-150px)] items-center justify-center max-w-lg mx-auto">
      <h1 className="font-medium text-2xl text-gray-900">Change Password</h1>
      <form onSubmit={handleSubmit} className="mt-2 flex flex-col gap-3 w-full">
        <div className="flex flex-col gap-1">
          <label>Old Password</label>
          <input
            type="password"
            name="oldpassword"
            onChange={handleChange}
            className="rounded-md p-2 border border-gray-400"
          />
        </div>
        <div className="flex flex-col gap-1">
          <label>New Password</label>
          <input
            type="password"
            name="newpassword"
            onChange={handleChange}
            className="rounded-md p-2 border border-gray-400"
          />
        </div>
        <div className="flex flex-col gap-1">
          <label>Confirm Password</label>
          <input
            type="password"
            name="confirmPassword"
            onChange={handleChange}
            className="rounded-md p-2 border border-gray-400"
          />
        </div>
        <p className="text-red-600 text-xs">{error}</p>
        <button className="rounded-md bg-primary p-2 text-white">Save</button>
      </form>
    </div> : tab === "Accounts" ? <Users /> : <Feedbacks />}
    </div>
  );
};
