import { Table } from "common/Table/Table";
import { useEffect, useState } from "react";
import UserService from "services/user.service";
import { exportToCSV } from "utils/funcs";

const service = new UserService();

export const Acuity = () => {
  const columns = [
    { key: "UID", title: "UID", label: "UID", dbColName: "UID" },
    {
      key: "FIRST_NAME",
      title: "FIRST NAME",
      label: "FIRST NAME",
      dbColName: "FIRST_NAME",
    },
    {
      key: "LAST_NAME",
      title: "LAST NAME",
      label: "LAST NAME",
      dbColName: "LAST_NAME",
    },
    { key: "EMAIL", title: "EMAIL", label: "EMAIL", dbColName: "EMAIL" },
    {
      key: "PHONE_NUMBER",
      title: "PHONE NUMBER",
      label: "PHONE NUMBER",
      dbColName: "PHONE_NUMBER",
    },
    {
      key: "APPOINTMENT_ID",
      title: "APPOINTMENT ID",
      label: "APPOINTMENT ID",
      dbColName: "APPOINTMENT_ID",
    },
    {
      key: "DATE_OF_APPPOINTMENT",
      title: "DATE OF APPPOINTMENT",
      label: "DATE OF APPPOINTMENT",
      dbColName: "DATE_OF_APPPOINTMENT",
    },

    {
      key: "TIME_OF_APPOINTMENT",
      title: "TIME OF APPOINTMENT",
      label: "TIME OF APPOINTMENT",
      dbColName: "TIME_OF_APPOINTMENT",
    },
    {
      key: "APPOINTMENT_TYPE",
      title: "APPOINTMENT TYPE",
      label: "APPOINTMENT TYPE",
      dbColName: "APPOINTMENT_TYPE",
    },

    {
      key: "CALENDAR_NAME",
      title: "CALENDAR NAME",
      label: "CALENDAR NAME",
      dbColName: "CALENDAR_NAME",
    },
    {
      key: "ENDING_TIME_OF_APPOINTMENT",
      title: "ENDING TIME OF APPOINTMENT",
      label: "ENDING TIME OF APPOINTMENT",
      dbColName: "ENDING_TIME_OF_APPOINTMENT",
    },
    {
      key: "LINK_TO_CLIENTS_CONFIRMATION_PAGE",
      title: "LINK TO CLIENTS CONFIRMATION PAGE",
      label: "LINK TO CLIENTS CONFIRMATION PAGE",
      dbColName: "LINK_TO_CLIENTS_CONFIRMATION_PAGE",
    },
    {
      key: "DATE_CREATED",
      title: "DATE CREATED",
      label: "DATE CREATED",
      dbColName: "DATE_CREATED",
    },
    {
      key: "LOCATION",
      title: "LOCATION",
      label: "LOCATION",
      dbColName: "LOCATION",
    },
    {
      key: "PRICE_OF_APPOINTMENT",
      title: "PRICE OF APPOINTMENT",
      label: "PRICE OF APPOINTMENT",
      dbColName: "PRICE_OF_APPOINTMENT",
    },
    {
      key: "DATETIME",
      title: "DATETIME",
      label: "DATETIME",
      dbColName: "DATETIME",
    },
    {
      key: "DURATION_OF_APPOINTMEN",
      title: "DURATION OF APPOINTMEN",
      label: "DURATION OF APPOINTMEN",
      dbColName: "DURATION_OF_APPOINTMEN",
    },
    {
      key: "INTAKE_FORMS_NAME",
      title: "INTAKE FORMS NAME",
      label: "INTAKE FORMS NAME",
      dbColName: "INTAKE_FORMS_NAME",
    },
    { key: "NOTES", title: "NOTES", label: "NOTES", dbColName: "NOTES" },
    {
      key: "CLIENTS_TIMEZONE",
      title: "CLIENTS TIMEZONE",
      label: "CLIENTS TIMEZONE",
      dbColName: "CLIENTS_TIMEZONE",
    },
    {
      key: "DATETIME_CREATED",
      title: "DATETIME CREATED",
      label: "DATETIME CREATED",
      dbColName: "DATETIME_CREATED",
    },
    {
      key: "HAS_BEEN_PAID",
      title: "HAS BEEN PAID",
      label: "HAS BEEN PAID",
      dbColName: "HAS_BEEN_PAID",
    },
    {
      key: "AMOUNT_PAID",
      title: "AMOUNT_PAID",
      label: "AMOUNT_PAID",
      dbColName: "AMOUNT_PAID",
    },
    {
      key: "CATEGORY",
      title: "CATEGORY",
      label: "CATEGORY",
      dbColName: "CATEGORY",
    },
    {
      key: "CERTIFICATE_CODE",
      title: "CERTIFICATE_CODE",
      label: "CERTIFICATE_CODE",
      dbColName: "CERTIFICATE_CODE",
    },
    {
      key: "CANCELED",
      title: "CANCELED",
      label: "CANCELED",
      dbColName: "CANCELED",
    },
    {
      key: "APPOINTMENT_TYPE_ID",
      title: "APPOINTMENT TYPE ID",
      label: "APPOINTMENT TYPE ID",
      dbColName: "APPOINTMENT_TYPE_ID",
    },
    {
      key: "CALENDAR_ID",
      title: "CALENDAR ID",
      label: "CALENDAR ID",
      dbColName: "CALENDAR_ID",
    },
    {
      key: "ADDON_IDS",
      title: "ADDON IDS",
      label: "ADDON IDS",
      dbColName: "ADDON_IDS",
    },
    {
      key: "PRICE_SOLD",
      title: "PRICE SOLD",
      label: "PRICE SOLD",
      dbColName: "PRICE_SOLD",
    },
    { key: "LABELS", title: "LABELS", label: "LABELS", dbColName: "LABELS" },
    {
      key: "CLASS_ID",
      title: "CLASS ID",
      label: "CLASS ID",
      dbColName: "CLASS_ID",
    },
    {
      key: "CONFIRMATION_PAGE_PAYMENT_LINK",
      title: "CONFIRMATION PAGE PAYMENT LINK",
      label: "CONFIRMATION PAGE PAYMENT LINK",
      dbColName: "CONFIRMATION_PAGE_PAYMENT_LINK",
    },
    {
      key: "SCHEDULE_BY_TEXT",
      title: "SCHEDULE BY TEXT",
      label: "SCHEDULE BY TEXT",
      dbColName: "SCHEDULE_BY_TEXT",
    },
    {
      key: "SCHEDULE_BY",
      title: "SCHEDULE BY",
      label: "SCHEDULE BY",
      dbColName: "SCHEDULE_BY",
    },
    {
      key: "CANDIDATE_SHOW_UP",
      title: "CANDIDATE SHOW UP",
      label: "CANDIDATE SHOW UP",
      dbColName: "CANDIDATE_SHOW_UP",
    },
    {
      key: "FORMER_EXPERIENCE",
      title: "FORMER EXPERIENCE",
      label: "FORMER EXPERIENCE",
      dbColName: "FORMER_EXPERIENCE",
    },
    {
      key: "JOB_OFFER_ACCEPTED",
      title: "JOB OFFER ACCEPTED",
      label: "JOB OFFER ACCEPTED",
      dbColName: "JOB_OFFER_ACCEPTED",
    },
    {
      key: "INTERVIEWED_BY",
      title: "INTERVIEWED BY",
      label: "INTERVIEWED BY",
      dbColName: "INTERVIEWED_BY",
    },
    {
      key: "OVERALL_IMPORESSION",
      title: "OVERALL IMPORESSION",
      label: "OVERALL IMPORESSION",
      dbColName: "OVERALL_IMPORESSION",
    },
    {
      key: "REASON_FOR_CHANGE",
      title: "REASON FOR CHANGE",
      label: "REASON FOR CHANGE",
      dbColName: "REASON_FOR_CHANGE",
    },
    {
      key: "TIMESTAMP",
      title: "TIMESTAMP",
      label: "TIMESTAMP",
      dbColName: "TIMESTAMP",
    },
  ];

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 100,
  });

  useEffect(() => {
    setLoading(true);
    service
      .getAquity(pagination)
      .then((resp) => {
        setData(resp.data.data);
        setTotalItems(resp.data.total_docs);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [pagination]);

  const handleExport = () => {
    exportToCSV(data, "acuity")
  }

  return (
    <Table
      data={data}
      handleExport={handleExport}
      loadingData={loading}
      setPagination={setPagination}
      pagination={pagination}
      totalItems={totalItems}
      columns={columns}
    />
  );
};
