// @ts-ignore
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import ReactApexChart from "react-apexcharts";

export const BarChart = ({ labels, values, name }) => {
  return (
    <ReactApexChart
      options={{
        chart: {
          type: "bar",
          height: 250,
          stacked: true,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          foreColor: "#646A71",
          fontFamily: "DM Sans",
        },

        grid: {
          borderColor: "#F4F5F6",
        },

        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "70%",
            borderRadius: 2,
            borderRadiusApplication: "end",
            borderRadiusWhenStacked: "last",
          },
        },
        xaxis: {
          type: "string",
          categories: labels,

          labels: {
            style: {
              fontSize: "12px",
              fontFamily: "inherit",
            },
          },
          axisBorder: {
            show: false,
          },
          title: {
            style: {
              fontSize: "12px",
              fontFamily: "inherit",
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              fontSize: "12px",
              fontFamily: "inherit",
            },
          },
          title: {
            style: {
              fontSize: "12px",
              fontFamily: "inherit",
            },
          },
        },
        legend: {
          position: "top",
          fontSize: "15px",
          labels: {
            colors: "#6f6f6f",
          },
          markers: {
            width: 20,
            height: 20,
            radius: 15,
          },
          itemMargin: {
            vertical: 5,
          },
        },

        colors: ["#ee1c24"],
        fill: {
          opacity: 1,
        },
        dataLabels: {
          enabled: false,
        },
      }}
      series={[
        {
          name,
          data: values,
        },
      ]}
      type="bar"
      height={240}
    />
  );
};
