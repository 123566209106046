import { ConfirmModal } from "common/ConfirmModal";
import { Modal } from "common/Modal";
import { Table } from "common/Table/Table";
import { useNotifications } from "hooks/useNotification";
import { useRouter } from "hooks/useRouter";
import {
  ChangeEvent,
  Fragment,
  SyntheticEvent,
  useEffect,
  useState,
} from "react";
import UserService from "services/user.service";
import swal from "sweetalert";
import { exportToCSV } from "utils/funcs";

const service = new UserService();

export const Categories = () => {
  const { push } = useRouter();

  const columns = [
    {
      key: "category_image",
      title: "Image",
      label: "Image",

      dbColName: "category_image",
      render: (image: string) => {
        return (
          <div className="h-10 w-44">
            <img
              src={image}
              className="w-full h-full object-cover"
              alt="category_image"
            />
          </div>
        );
      },
    },
    {
      key: "title",
      title: "title",
      label: "title",

      dbColName: "title",
    },
    {
      key: "createdAt",
      title: "Date created",
      label: "Date created",

      dbColName: "createdAt",
      render: (date: string) => <p>{new Date(date).toLocaleDateString()}</p>,
    },
    {
      key: "actions",
      title: "actions",
      render: ({ _id }) => {
        return (
          <div className="flex gap-2">
            <button
              type="button"
              onClick={() => push(`/category/${_id}`)}
              className="bg-green-400 text-white p-2 text-xs rounded-md "
            >
              View List
            </button>
            <button
              onClick={() => handleDelete(_id)}
              className="bg-primary text-white rounded-md text-xs p-2 "
            >
              Delete
            </button>
          </div>
        );
      },
    },
  ];

  const [categories, setCategories] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showImage, setShowImage] = useState<string | null>();
  const [image, setImage] = useState<any>();
  const [title, setTitle] = useState("");
  const [saving, setSaving] = useState(false);

  const { successMessage } = useNotifications();

  useEffect(() => {
    setLoading(true);
    service
      .getCategories()
      .then((resp) => {
        setCategories(resp.data.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleDelete = (id: string) => {
    swal({
      title: "Are you sure, you want to delete this category?",
      icon: "warning",
      className: "w-[500px]",
      buttons: {
        cancel: {
          text: "Cancel",
          visible: true,
        },
        confirm: {
          text: "Delete",
          className: "bg-primary text-white",
        },
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setLoading(true);
        service.deleteCategory(id).then((resp) => {
          if (resp.data.success) {
            successMessage("Category deleted successfully");
            const shallowCopy = [...categories];
            const newData = shallowCopy.filter(
              (category) => category._id !== id
            );
            setCategories(newData);
            setLoading(false)
          }
        }).catch((err) => {
          console.log(err)
        }).finally(() => {
          setLoading(false)
        }) 
      }
    });
  };

  const handleUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    const reader = new FileReader();
    setImage(file);

    reader.onload = () => {
      if (reader.readyState === 2) {
        setShowImage(reader.result as string);
      }
    };
    file && reader.readAsDataURL(file);
  };

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    setSaving(true);
    service
      .addCategory({ title, category_image: image })
      .then((resp) => {
        const newData = [...categories];
        newData.unshift({ ...resp.data.data, category_image: showImage });
        setCategories(newData);
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setSaving(false);
        setShowModal(false);
      });
  };

  const handleExport = () => {
    exportToCSV(categories, "categories")
  }

  return (
    <Fragment>
      <div className="flex justify-between items-center mb-4">
        <div>
          <h1 className="font-medium text-gray-700 tracking-wide text-xl">
            Video Categories
          </h1>
          <p className="text-gray-500 text-sm">View all categories</p>
        </div>
        <button
          type="button"
          onClick={() => setShowModal(true)}
          className="bg-green-500 drop-shadow-md text-white rounded-md p-2.5 text-sm flex items-center"
        >
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 6v12m6-6H6"
              />
            </svg>
          </span>
          Add Category
        </button>
      </div>

      <ConfirmModal
        open={false}
        setOpen={() => console.log()}
        message={"Are you sure to delete this category?"}
        onClickConfirm={() => console.log("")}
      />
      <Table
        data={categories}
        loadingData={loading}
        handleExport={handleExport}
        totalItems={categories.length}
        exportButton={false}
        columns={columns}
      />

      <Modal
        width="w-full md:w-1/3"
        showModal={showModal}
        setShowModal={setShowModal}
        title="Add New Category"
      >
        <form onSubmit={handleSubmit} className="flex flex-col gap-4">
          <input
            type="text"
            placeholder="Category title"
            onChange={(e) => setTitle(e.target.value)}
            className="rounded-md focus:border-gray-400 focus:outline-none w-full focus:ring-0 border-gray-300 px-4 py-2.5 text-sm text-gray-800"
          />
          {showImage ? (
            <div className="relative h-full">
              <img
                alt="category"
                className="w-full h-full overflow-hidden object-contain rounded-lg"
                src={showImage}
              />
              <button
                type="button"
                onClick={() => {
                  setShowImage(null);
                  setImage(null);
                }}
                className="bg-gray-300 h-10 w-10 rounded-full absolute top-3 right-3"
              >
                X
              </button>
            </div>
          ) : (
            <div className="z-20 flex items-center justify-center w-full">
              <label
                htmlFor="dropzone-file"
                className="flex flex-col items-center justify-center w-full h-48 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
              >
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <svg
                    className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 16"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                    />
                  </svg>
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                    <span className="font-semibold">Click to upload</span> or
                    drag and drop
                  </p>
                  <p className="text-xs text-gray-500 dark:text-gray-400">
                    SVG, PNG, JPG or GIF (MAX. 800x400px)
                  </p>
                </div>
                <input
                  id="dropzone-file"
                  onChange={handleUpload}
                  type="file"
                  className="hidden"
                />
              </label>
            </div>
          )}
          <button
            type="submit"
            disabled={saving}
            className="bg-green-400 disabled:cursor-not-allowed rounded-md font-medium text-sm disabled:bg-green-300 text-white p-2.5"
          >
            {saving ? "Saving..." : "Save"}
          </button>
        </form>
      </Modal>
    </Fragment>
  );
};
