import classNames from "classnames";
import { Table } from "common/Table/Table";
import { useRouter } from "hooks/useRouter";
import { tabStyles } from "pages/GeneralData";
import { Fragment, useEffect, useState } from "react";
import UserService from "services/user.service";

const service = new UserService();

export const SpecificBlitz = () => {
  const columns = [
    { key: "badge_id", dbColName: "badge_id", title: "badge id" },
    { key: "first_name", dbColName: "first_name", title: "first name" },
    { key: "last_name", dbColName: "last_name", title: "last name" },
    { key: "phone_number", dbColName: "phone_number", title: "phone number" },
    {
      key: "rep_start_date",
      dbColName: "rep_start_date",
      title: "rep start date",
    },
    { key: "rep_end_date", dbColName: "rep_end_date", title: "rep end date" },
    {
      key: "tenure_registered",
      dbColName: "tenure_registered",
      title: "tenure registered",
    },
    { key: "position", dbColName: "position", title: "position" },
    {
      key: "blitz_bonus_opt_in",
      dbColName: "blitz_bonus_opt_in",
      title: "blitz bonus opt in",
    },
    { key: "tenure", dbColName: "tenure", title: "tenure" },
    { key: "team", dbColName: "team", title: "team" },
    {
      key: "management_group",
      dbColName: "management_group",
      title: "management group",
    },
    {
      key: "senior_management_group",
      dbColName: "senior_management_group",
      title: "senior management group",
    },
    { key: "region", dbColName: "region", title: "region" },
    {
      key: "senior_region",
      dbColName: "senior_region",
      title: "senior region",
    },
    { key: "partnership", dbColName: "partnership", title: "partnership" },
  ];

  const uploadColumns = [
    { key: "badge_id", title: "badge_id", dbColName: "badge_id" },
    {
      key: "url",
      title: "url",
      dbColName: "url",
      render: (url) => (
        <a
          className="text-blue-500"
          href={url}
          target="_blank"
          rel="noreferrer"
        >
          {url}
        </a>
      ),
    },
  ];

  const { params, push } = useRouter();

  const [loading, setLoading] = useState(false);
  const [unregUsers, setUnregUsers] = useState([]);
  const [regUsers, setRegUsers] = useState([]);
  const [data, setData] = useState<any>(null);
  const [tab, setTab] = useState("Reg");

  useEffect(() => {
    setLoading(true);
    service
      .getBlitzDetails(params?.id)
      .then((resp) => {
        setData(resp.data.data);
        setUnregUsers(resp.data.data.unregistered_users);
        setRegUsers(resp.data.data.users);
        // setUploads(resp.data.data.uploads);
        // setTotalItems(resp.data.total_docs);
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setLoading(false);
      });
  }, [params?.id]);

  return (
    <Fragment>
      <div className="flex items-center gap-3">
        <button
          onClick={() => push("/blitz")}
          className="rounded-md bg-white border border-gray-200 px-4 py-2.5"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 h-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
            />
          </svg>
        </button>
        <div>
          <h1 className="font-medium text-gray-700 tracking-wide text-xl">
            Blitz Users
          </h1>
          <p className="text-gray-500 text-sm">View all users</p>
        </div>
      </div>

      <div className="flex gap-4">
        <div className="w-1/3">
          <div className="flex gap-3">
            <div className="flex-1 items-center justify-center gap-2 border border-zinc-200 rounded-lg bg-black p-2 flex">
              <div className="h-5 w-5">
                <img
                  alt="person"
                  src="/person_icon.png"
                  className="h-full w-full mx-auto"
                />
              </div>
              <p className="text-lg text-red-500">{data?.sales_counts}</p>
            </div>
            <div className="flex-1 items-center justify-center gap-2 border border-zinc-200 rounded-lg bg-black p-2 flex">
              <div className="h-5 w-5">
                <img
                  alt="person"
                  src="/rep_icon.png"
                  className="h-full w-full mx-auto"
                />
              </div>
              <p className="text-lg text-red-500">{data?.tech_counts}</p>
            </div>
            <div className="flex-1 items-center justify-center gap-2 border border-zinc-200 rounded-lg bg-black p-2 flex">
              <div className="h-5 w-5">
                <img
                  alt="person"
                  src="/rep_person.png"
                  className="h-full w-full mx-auto"
                />
              </div>
              <p className="text-lg text-red-500">{data?.solar_counts}</p>
            </div>
          </div>
          <p className="mt-4 text-lg font-semibold">Blitz Details</p>

          <div className="flex flex-col gap-2">
            <div className="bg-white mt-1.5 shadow-sm border border-zinc-200 flex flex-col  px-4 py-2  rounded-lg divide-y divide-zinc-200">
              <div className="flex justify-between gap-4 py-3">
                <span>Blitz Creator</span>
                <span>{data?.first_name + " " + data?.last_name}</span>
              </div>
              <div className="flex justify-between gap-4 py-3">
                <span>Blitz Key</span>
                <span>{data?.blitz_key}</span>
              </div>
              {/* <div className="flex justify-between gap-4 py-3">
                <span>Blitz Link</span>
                <span>{data?.trainer_name}</span>
              </div> */}
              <div className="flex justify-between gap-4 py-3">
                <span>Blitz Creation Date</span>
                <span>{new Date(data?.createdAt).toLocaleDateString()}</span>
              </div>
            </div>
          </div>
          <div className="bg-white mt-1.5 shadow-sm border border-zinc-200 flex flex-col  px-4 py-2  rounded-lg divide-y divide-zinc-200">
            <div className="flex justify-between gap-4 py-3">
              <span>Sales Office</span>
              <span>{data?.sale_office}</span>
            </div>
            <div className="flex justify-between gap-4 py-3">
              <span>Start Date</span>
              <span>{data?.start_date}</span>
            </div>
            <div className="flex justify-between gap-4 py-3">
              <span>End Date</span>
              <span>{data?.end_date}</span>
            </div>
            <div className="flex justify-between gap-4 py-3">
              <span>State</span>
              <span>{data?.state}</span>
            </div>
            <div className="flex justify-between gap-4 py-3">
              <span>Zip Code</span>
              <span>{data?.zip_code}</span>
            </div>
            <div className="flex justify-between gap-4 py-3">
              <span>Blitz Month</span>
              <span>{data?.month_of_blitz}</span>
            </div>
          </div>
          <div className="bg-white mt-1.5 shadow-sm border border-zinc-200 flex flex-col  px-4 py-2 rounded-lg divide-y divide-zinc-200">
            <div className="flex justify-between gap-4 py-3">
              <span>Estimated Rookies</span>
              <span>{data?.rookies}</span>
            </div>
            <div className="flex justify-between gap-4 py-3">
              <span>Estimate Reps</span>
              <span>{data?.vets}</span>
            </div>
            <div className="flex justify-between gap-4 py-3">
              <span>Estimate FP</span>
              <span>{data?.est_accounts}</span>
            </div>
            <div className="flex justify-between gap-4 py-3">
              <span>Techs Requested</span>
              <span>{data?.techs_needed}</span>
            </div>
            <div className="flex justify-between gap-4 py-3">
              <span>Solar Closer Request</span>
              <span>{data?.request_solar_closer}</span>
            </div>
          </div>
        </div>

        <div className="flex-1">
          <div className="text-sm mb-4 p-2 text-center text-gray-500 rounded-md bg-gray-200">
            <div className="flex flex-wrap -mb-px gap-3">
              <button
                type="button"
                onClick={() => setTab("Reg")}
                className={classNames(
                  `${tabStyles.default} flex items-center justify-center gap-2`,
                  {
                    [tabStyles.active]: tab === "Reg",
                  }
                )}
              >
                Registered Blitz
              </button>
              <button
                type="button"
                onClick={() => setTab("Unreg")}
                className={classNames(
                  `${tabStyles.default} flex items-center justify-center gap-2`,
                  {
                    [tabStyles.active]: tab === "Unreg",
                  }
                )}
              >
                Unregistered Blitz
              </button>
            </div>
          </div>

          {tab === "Reg" ? (
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Name
                    </th>

                    <th scope="col" className="px-6 py-3">
                      Tenure
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Badge ID
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Start Date
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Phone
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {regUsers?.map((item: any, index) => (
                    <tr key={index} className="bg-white border-b ">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                      >
                        {item?.first_name + " " + item?.last_name}
                      </th>

                      <td className="px-6 py-4">{item?.tenure}</td>
                      <td className="px-6 py-4">{item?.badge_id}</td>

                      <td className="px-6 py-4">{item?.rep_start_date}</td>

                      <td className="px-6 py-4">{`(${item?.phone_number.slice(
                        0,
                        3
                      )}) ${item?.phone_number.slice(
                        3,
                        6
                      )}-${item?.phone_number.slice(6, 10)}`}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Name
                    </th>

                    <th scope="col" className="px-6 py-3">
                      Tenure
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Badge ID
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {unregUsers.map((item: any, index) => (
                    <tr
                      key={index}
                      className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                    >
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {item?.first_name + " " + item.last_name}
                      </th>
                      <td className="px-6 py-4">{item?.tenure_registered}</td>
                      <td className="px-6 py-4">{item?.badge_id}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>

      {/* {tab === "Users" ? (
        <Table
          data={data}
          loadingData={loading}
          setPagination={setPagination}
          pagination={pagination}
          totalItems={totalItems}
          columns={columns}
        />
      ) : (
        <Table
          data={uploads}
          loadingData={loading}
          setPagination={setPagination}
          pagination={pagination}
          totalItems={totalItems}
          columns={uploadColumns}
        />
      )}
      <div className="text-sm mt-4 p-2 text-center text-gray-500 rounded-md bg-gray-200">
        <div className="flex flex-wrap -mb-px gap-3">
          <button
            type="button"
            onClick={() => setTab("Users")}
            className={classNames(tabStyles.default, {
              [tabStyles.active]: tab === "Users",
            })}
          >
            Users
          </button>
          <button
            type="button"
            onClick={() => setTab("Uploads")}
            className={classNames(tabStyles.default, {
              [tabStyles.active]: tab === "Uploads",
            })}
          >
            Uploads
          </button>
        </div>
      </div> */}
    </Fragment>
  );
};
