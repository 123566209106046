import { EditableCell } from "common/Table/EditableCell";
import { Table } from "common/Table/Table";
import { ChangeEvent, Fragment, useEffect, useState } from "react";
import UserService from "services/user.service";
import {
  CANDIDATE_DETAILS,
  HOME_DETAILS,
  INTERVIEW,
  SOURCE_DETAILS,
  TIMEZONES,
} from "utils/constants";

import { exportToCSV } from "utils/funcs";

const service = new UserService();

export const RecruitingTools = () => {
  const columns = [
    {
      key: "FULL_NAME",
      title: "FULL NAME",
      label: "FULL NAME",
      dbColName: "full_name",
      freeze: true,
      freezeIndex: "left-0 min-w-[190px]",
      color: "bg-[#ECEEEF] text-black ",
      render: (value: string, id: string) => (
        <EditableCell
          value={value}
          onBlur={(value) => updateData({ id, column: "FULL_NAME", value })}
        />
      ),
    },
    {
      key: "RECRUITING_CATEGORY",
      title: "RECRUITING CATEGORY",
      label: "RECRUITING CATEGORY",
      dbColName: "source",
      render: source => source.recruiting_category,
      color: "bg-[#BDBDBD] text-black",
    },
    {
      key: "CANDIDATE_STAGE",
      title: "CANDIDATE STAGE",
      label: "CANDIDATE STAGE",
      dbColName: "candidate_stage",
      color: "bg-[#BDBDBD] text-black",
    },
    {
      key: "EVO_STAGE",
      title: "EVO STAGE",
      label: "EVO STAGE",
      dbColName: "candidate",
      render: candidate => candidate.evo_stage, 
      color: "bg-[#BDBDBD] text-black",
    },
    // {
    //   key: "PHONE",
    //   title: "PHONE",
    //   label: "PHONE",
    //   dbColName: "PHONE",

    //   color: "bg-gray-600 text-black",
    //   render: (value: string, id: string) => (
    //     <EditableCell
    //       value={value}
    //       onBlur={(value) => updateData({ id, column: "PHONE", value })}
    //     />
    //   ),
    // },
    // {
    //   key: "EMAIL",
    //   title: "EMAIL",
    //   label: "EMAIL",
    //   dbColName: "EMAIL",

    //   color: "bg-gray-600 text-black",
    //   render: (value: string, id: string) => (
    //     <EditableCell
    //       value={value}
    //       onBlur={(value) => updateData({ id, column: "EMAIL", value })}
    //     />
    //   ),
    // },

    // {
    //   key: "MOST_RECENT_ACTION",
    //   title: "MOST RECENT ACTION",
    //   label: "MOST RECENT ACTION",
    //   color: "bg-purple-400 text-black",
    //   dbColName: "MOST_RECENT_ACTION",
    // },
    // {
    //   key: "MOST_RECENT_TS",
    //   title: "MOST RECENT TS",
    //   label: "MOST RECENT TS",
    //   dbColName: "MOST_RECENT_TS",
    //   color: "bg-purple-400 text-black",
    //   header: () => (
    //     <button type="button" onClick={() => setShowTimeStamp(!showTimeStamp)}>
    //       {showTimeStamp ? (
    //         <svg
    //           xmlns="http://www.w3.org/2000/svg"
    //           fill="none"
    //           viewBox="0 0 24 24"
    //           strokeWidth={1.5}
    //           stroke="currentColor"
    //           className="w-4 h-4"
    //         >
    //           <path
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //             d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
    //           />
    //         </svg>
    //       ) : (
    //         <svg
    //           xmlns="http://www.w3.org/2000/svg"
    //           fill="none"
    //           viewBox="0 0 24 24"
    //           strokeWidth={1.5}
    //           stroke="currentColor"
    //           className="w-4 h-4"
    //         >
    //           <path
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //             d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
    //           />
    //         </svg>
    //       )}
    //     </button>
    //   ),
    // },
    // {
    //   key: "ORIGINATION_TS",
    //   title: "ORIGINATION TS",
    //   label: "ORIGINATION TS",
    //   dbColName: "ORIGINATION_TS",
    //   show: false,
    //   color: "bg-purple-400 text-black",
    // },
    // {
    //   key: "OUTREACH_TS",
    //   title: "OUTREACH TS",
    //   label: "OUTREACH TS",
    //   dbColName: "OUTREACH_TS",
    //   show: false,
    //   color: "bg-purple-400 text-black",
    // },
    // {
    //   key: "INTRO_VIDEO_TS",
    //   title: "INTRO VIDEO TS",
    //   label: "INTRO VIDEO TS",
    //   dbColName: "INTRO_VIDEO_TS",
    //   show: false,
    //   color: "bg-purple-400 text-black",
    // },
    // {
    //   key: "FOLLOW_UP_CALL_TS",
    //   title: "FOLLOW UP CALL TS",
    //   label: "FOLLOW UP CALL TS",
    //   dbColName: "FOLLOW_UP_CALL_TS",
    //   show: false,
    //   color: "bg-purple-400 text-black",
    // },
    // {
    //   key: "CANDIDATE_INTEREST_TS",
    //   title: "CANDIDATE INTEREST TS",
    //   label: "CANDIDATE INTEREST TS",
    //   dbColName: "CANDIDATE_INTEREST_TS",
    //   show: false,
    //   color: "bg-purple-400 text-black",
    // },
    // {
    //   key: "RECRUITER_INTEREST_TS",
    //   title: "RECRUITER INTEREST TS",
    //   label: "RECRUITER INTEREST TS",
    //   dbColName: "RECRUITER_INTEREST_TS",
    //   show: false,
    //   color: "bg-purple-400 text-black",
    // },
    // {
    //   key: "SCREENING_RESULT_TS",
    //   title: "SCREENING RESULT TS",
    //   label: "SCREENING RESULT TS",
    //   dbColName: "SCREENING_RESULT_TS",
    //   show: false,
    //   color: "bg-purple-400 text-black",
    // },
    // {
    //   key: "RECRUITER_NOTES_TS",
    //   title: "RECRUITER NOTES TS",
    //   label: "RECRUITER NOTES TS",
    //   dbColName: "RECRUITER_NOTES_TS",
    //   show: false,
    //   color: "bg-purple-400 text-black",
    // },
    // {
    //   key: "INTERVIEW_TS",
    //   title: "INTERVIEW TS",
    //   label: "INTERVIEW TS",
    //   dbColName: "INTERVIEW_TS",
    //   show: false,
    //   color: "bg-purple-400 text-black",
    // },
    // {
    //   key: "INTERVIEW_NOTES_TS",
    //   title: "INTERVIEW NOTES TS",
    //   label: "INTERVIEW NOTES TS",
    //   dbColName: "INTERVIEW_NOTES_TS",
    //   show: false,
    //   color: "bg-purple-400 text-black",
    // },
    // {
    //   key: "INTERVIEW_RESULTS_TS",
    //   title: "INTERVIEW RESULTS TS",
    //   label: "INTERVIEW RESULTS TS",
    //   dbColName: "INTERVIEW_RESULTS_TS",
    //   show: false,
    //   color: "bg-purple-400 text-black",
    // },
    // {
    //   key: "AGREEMENT_SENT_TS",
    //   title: "AGREEMENT SENT TS",
    //   label: "AGREEMENT SENT TS",
    //   dbColName: "AGREEMENT_SENT_TS",
    //   show: false,
    //   color: "bg-purple-400 text-black",
    // },
    // {
    //   key: "AGREEMENT_SIGNED_TS",
    //   title: "AGREEMENT SIGNED TS",
    //   label: "AGREEMENT SIGNED TS",
    //   dbColName: "AGREEMENT_SIGNED_TS",
    //   show: false,
    //   color: "bg-purple-400 text-black",
    // },
    // {
    //   key: "ONBOARDED_TS",
    //   title: "ONBOARDED TS",
    //   label: "ONBOARDED TS",
    //   dbColName: "ONBOARDED_TS",
    //   show: false,
    //   color: "bg-purple-400 text-black",
    // },
    // {
    //   key: "STREET_GEINIE_LOGIN_TS",
    //   title: "STREET GEINIE LOGIN TS",
    //   label: "STREET GEINIE LOGIN TS",
    //   dbColName: "STREET_GEINIE_LOGIN_TS",
    //   show: false,
    //   color: "bg-purple-400 text-black",
    // },
    // {
    //   key: "SALES_TRAINING_TS",
    //   title: "SALES TRAINING TS",
    //   label: "SALES TRAINING TS",
    //   dbColName: "SALES_TRAINING_TS",
    //   show: false,
    //   color: "bg-purple-400 text-black",
    // },
    // {
    //   key: "SALES_LEADER_ASSIGNED_TS",
    //   title: "SALES LEADER ASSIGNED TS",
    //   label: "SALES LEADER ASSIGNED TS",
    //   dbColName: "SALES_LEADER_ASSIGNED_TS",
    //   show: false,
    //   color: "bg-purple-400 text-black",
    // },
    // {
    //   key: "BOOTCAMP_TS",
    //   title: "BOOTCAMP TS",
    //   label: "BOOTCAMP TS",
    //   dbColName: "BOOTCAMP_TS",
    //   show: false,
    //   color: "bg-purple-400 text-black",
    // },
    // {
    //   key: "BOOTCAMP_TYPE_TS",
    //   title: "BOOTCAMP TYPE TS",
    //   label: "BOOTCAMP TYPE TS",
    //   dbColName: "BOOTCAMP_TYPE_TS",
    //   show: false,
    //   color: "bg-purple-400 text-black",
    // },
    // {
    //   key: "BOOTCAMP_SCHEDULED_TS",
    //   title: "BOOTCAMP SCHEDULED TS",
    //   label: "BOOTCAMP SCHEDULED TS",
    //   dbColName: "BOOTCAMP_SCHEDULED_TS",
    //   show: false,
    //   color: "bg-purple-400 text-black",
    // },
    // {
    //   key: "BOOTCAMP_NOTES_TS",
    //   title: "BOOTCAMP NOTES TS",
    //   label: "BOOTCAMP NOTES TS",
    //   dbColName: "BOOTCAMP_NOTES_TS",
    //   show: false,
    //   color: "bg-purple-400 text-black",
    // },
    // {
    //   key: "LEAD_SET_TS",
    //   title: "LEAD SET TS",
    //   label: "LEAD SET TS",
    //   dbColName: "LEAD_SET_TS",
    //   show: false,
    //   color: "bg-purple-400 text-black",
    // },
    // {
    //   key: "LEAD_CLOSED_TS",
    //   title: "LEAD CLOSED TS",
    //   label: "LEAD CLOSED TS",
    //   dbColName: "LEAD_CLOSED_TS",
    //   show: false,
    //   color: "bg-purple-400 text-black",
    // },
    {
      key: "UID",
      title: "UID",
      label: "UID",
      dbColName: "uid",
      freeze: true,
      freezeIndex: "left-[210px] min-w-[150px]",
      color: "bg-[#D9F1F5] text-black",
      header: () => (
        <button type="button" onClick={() => setShowCandidate(!showCandidate)}>
          {showCandidate ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
              />
            </svg>
          )}
        </button>
      ),
    },
    {
      key: "ORIGINATION_DATE",
      title: "ORIGINATION DATE",
      label: "ORIGINATION DATE",
      dbColName: "candidate",
      render: candidate => candidate.origination_date ? new Date(candidate.origination_date).toLocaleDateString() : '',
      color: "bg-[#4DD0E1] text-black",
      show: false,
    },
    {
      key: "CANDIDATE_TYPE",
      title: "CANDIDATE TYPE",
      label: "CANDIDATE TYPE",
      dbColName: "candidate",
      color: "bg-[#4DD0E1] text-black",
      render: candidate => candidate.type,
      // render: (value: string, id: string) => (
      //   <EditableCell
      //     value={value}
      //     onBlur={(value) =>
      //       updateData({ id, column: "CANDIDATE_TYPE", value })
      //     }
      //   />
      // ),
      show: false,
    },
    {
      key: "FIRST_NAME",
      title: "FIRST NAME",
      label: "FIRST NAME",
      dbColName: "first_name",
      // freeze: true,
      // freezeIndex: "left-[440px]",
      show: false,
      color: "bg-[#4DD0E1] text-black",
      render: (value: string, id: string) => (
        <EditableCell
          value={value}
          onBlur={(value) => updateData({ id, column: "FIRST_NAME", value })}
        />
      ),
    },
    {
      key: "LAST_NAME",
      title: "LAST NAME",
      label: "LAST NAME",
      dbColName: "last_name",
      // freeze: true,
      // freezeIndex: "left-[580px]",
      color: "bg-[#4DD0E1] text-black",
      render: (value: string, id: string) => (
        <EditableCell
          value={value}
          onBlur={(value) => updateData({ id, column: "LAST_NAME", value })}
        />
      ),
    },

    {
      key: "BIRTHDAY",
      title: "BIRTHDAY",
      label: "BIRTHDAY",
      dbColName: "birthday",
      color: "bg-[#4DD0E1] text-black",
      render: (value: string, id: string) => (
        <EditableCell
          value={value}
          onBlur={(value) => updateData({ id, column: "BIRTHDAY", value })}
        />
      ),
      show: false,
    },
    {
      key: "SOURCE",
      title: "SOURCE",
      label: "SOURCE",
      dbColName: "source",
      color: "bg-yellow-300 text-black",
      render: source => source.source,
      header: () => (
        <button type="button" onClick={() => setShowSource(!showSource)}>
          {showSource ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
              />
            </svg>
          )}
        </button>
      ),
    },
    {
      key: "SOURCE_CATEGORY",
      title: "SOURCE CATEGORY",
      label: "SOURCE CATEGORY",
      dbColName: "source",
      color: "bg-yellow-300",
      render: (source) => source.source_category,
        // <EditableCell
        //   value={value}
        //   onBlur={(value) =>
        //     updateData({ id, column: "SOURCE_CATEGORY", value })
        //   }
        // />
      // ),
      show: false,
    },
    {
      key: "SOURCE_ID",
      title: "SOURCE ID",
      label: "SOURCE ID",
      dbColName: "source",
      color: "bg-yellow-300",
      render: (source) =>   source.source_id,
      //   (
      //   <EditableCell
      //     value={value}
      //     onBlur={(value) => updateData({ id, column: "SOURCE_ID", value })}
      //   />
      // ),
      show: false,
    },
    {
      key: "SOURCE_NOTE",
      title: "SOURCE NOTE",
      label: "SOURCE NOTE",
      dbColName: "source",
      color: "bg-yellow-300",
      render: source => source?.notes,
      show: false,
    },
    {
      key: "CAMPAIGN_ID",
      title: "CAMPAIGN ID",
      label: "CAMPAIGN ID",
      dbColName: "source",
      color: "bg-yellow-300",
      render: source => source.campaign_id,
      // render: (value: string, id: string) => (
      //   <EditableCell
      //     value={value}
      //     onBlur={(value) => updateData({ id, column: "CAMPAIGN_ID", value })}
      //   />
      // ),
      show: false,
    },
    {
      key: "RECRUITING_LOCATION",
      title: "RECRUITING LOCATION",
      label: "RECRUITING LOCATION",
      dbColName: "source",
      color: "bg-yellow-300",
      render: source => source.recruiting_location,
      // render: (value: string, id: string) => (
      //   <EditableCell
      //     value={value}
      //     onBlur={(value) =>
      //       updateData({ id, column: "RECRUITING_LOCATION", value })
      //     }
      //   />
      // ),
      show: false,
    },

    {
      key: "HOME_CITY",
      title: "HOME CITY & STATE",
      label: "HOME CITY",
      dbColName: 'address',
      color: "bg-[#F36524]",
      header: () => (
        <button type="button" onClick={() => setShowHome(!showHome)}>
          {showHome ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
              />
            </svg>
          )}
        </button>
      ),
      render: (address: { city: string; state }, id: string) => `${address.city}, ${address.state}`,
      //    (
      //   <EditableCell
      //     value={`${address.city}, ${address.state}`}
      //     onBlur={(value) => updateData({ id, column: "HOME_CITY", value })}
      //   />
      // ),
    },
    {
      key: "HOME_COUNTRY",
      title: "HOME COUNTRY",
      label: "HOME COUNTRY",
      dbColName: "address",
      color: "bg-[#F36524]",
      render: address => address.country, 
      // render: (value: string, id: string) => (
      //   <EditableCell
      //     value={value}
      //     onBlur={(value) => updateData({ id, column: "HOME_COUNTRY", value })}
      //   />
      // ),
    },
    {
      key: "HOME_ADDRESS",
      title: "HOME ADDRESS",
      label: "HOME ADDRESS",
      dbColName: "address",
      render: address => address.address,
      color: "bg-[#F36524]",
      // render: (value: string, id: string) => (
      //   <EditableCell
      //     value={value}
      //     onBlur={(value) => updateData({ id, column: "HOME_ADDRESS", value })}
      //   />
      // ),
    },

    {
      key: "HOME_ZIP_CODE",
      title: "HOME ZIP CODE",
      label: "HOME ZIP CODE",
      dbColName: "address",
      color: "bg-[#F36524]",
      render: address => address.zip_code,
      // render: (value: string, id: string) => (
      //   <EditableCell
      //     value={value}
      //     onBlur={(value) => updateData({ id, column: "HOME_ZIP_CODE", value })}
      //   />
      // ),
    },
    {
      key: "HOME_TIMEZONE",
      title: "HOME TIMEZONE",
      label: "HOME TIMEZONE",
      dbColName: "address",
      color: "bg-[#F36524]",
      render: address => address.timezone,
      // render: (value: string, id: string) => (
      //   <EditableCell
      //     value={value}
      //     onBlur={(value) => updateData({ id, column: "HOME_TIMEZONE", value })}
      //   />
      // ),
    },
    {
      key: "COLLEGE_NAME",
      title: "COLLEGE NAME",
      label: "COLLEGE NAME",
      dbColName: "college",
      color: "bg-[#CBA676] text-black",
      render: college => college.name,
      header: () => (
        <button type="button" onClick={() => setShowCollege(!showCollege)}>
          {showCollege ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
              />
            </svg>
          )}
        </button>
      ),
    },
    {
      key: "COLLEGE_DEGREE_TYPE",
      title: "COLLEGE DEGREE TYPE",
      label: "COLLEGE DEGREE TYPE",
      dbColName: "college",
      color: "bg-[#CBA676] text-black",
      render: college => college.degree_type,
      // render: (value: string, id: string) => (
      //   <EditableCell
      //     value={value}
      //     onBlur={(value) =>
      //       updateData({ id, column: "COLLEGE_DEGREE_TYPE", value })
      //     }
      //   />
      // ),
    },

    {
      key: "COLLEGE_ENROLL_DATE",
      title: "COLLEGE ENROLL DATE",
      label: "COLLEGE ENROLL DATE",
      dbColName: "college",
      color: "bg-[#CBA676] text-black",
      render: college => college.enroll_date
      // render: (value: string, id: string) => (
      //   <EditableCell
      //     value={value}
      //     onBlur={(value) =>
      //       updateData({ id, column: "COLLEGE_ENROLL_DATE", value })
      //     }
      //   />
      // ),
    },
    {
      key: "COLLEGE_EXPECTED_GRAUDATION_YEAR",
      title: "COLLEGE EXPECTED GRAUDATION YEAR",
      label: "COLLEGE EXPECTED GRAUDATION YEAR",
      dbColName: "college",
      color: "bg-[#CBA676] text-black",
      render: college => college.expected_graduation_year
      // render: (value: string, id: string) => (
      //   <EditableCell
      //     value={value}
      //     onBlur={(value) =>
      //       updateData({
      //         id,
      //         column: "COLLEGE_EXPECTED_GRAUDATION_YEAR",
      //         value,
      //       })
      //     }
      //   />
      // ),
    },
    {
      key: "COLLEGE_MAJOR",
      title: "COLLEGE MAJOR",
      label: "COLLEGE MAJOR",
      dbColName: "college",
      color: "bg-[#CBA676] text-black",
      render: college => college.major,
      // render: (value: string, id: string) => (
      //   <EditableCell
      //     value={value}
      //     onBlur={(value) => updateData({ id, column: "COLLEGE_MAJOR", value })}
      //   />
      // ),
    },

    {
      key: "COLLEGE_ADDRESS",
      title: "COLLEGE ADDRESS",
      label: "COLLEGE ADDRESS",
      dbColName: "college",
      color: "bg-[#CBA676] text-black",
      render: college => college.address,
      // render: (value: string, id: string) => (
      //   <EditableCell
      //     value={value}
      //     onBlur={(value) =>
      //       updateData({ id, column: "COLLEGE_ADDRESS", value })
      //     }
      //   />
      // ),
    },
    {
      key: "COLLEGE_CITY",
      title: "COLLEGE CITY",
      label: "COLLEGE CITY",
      dbColName: "college",
      color: "bg-[#CBA676] text-black",
      render: college => college.city,
      // render: (value: string, id: string) => (
      //   <EditableCell
      //     value={value}
      //     onBlur={(value) => updateData({ id, column: "COLLEGE_CITY", value })}
      //   />
      // ),
    },
    {
      key: "COLLEGE_STATE",
      title: "COLLEGE STATE",
      label: "COLLEGE STATE",
      dbColName: "college",
      color: "bg-[#CBA676] text-black",
      render: college => college.state
      // render: (value: string, id: string) => (
      //   <EditableCell
      //     value={value}
      //     onBlur={(value) => updateData({ id, column: "COLLEGE_STATE", value })}
      //   />
      // ),
    },
    {
      key: "COLLEGE_ZIP_CODE",
      title: "COLLEGE ZIP CODE",
      label: "COLLEGE ZIP CODE",
      dbColName: "college",
      color: "bg-[#CBA676] text-black",
      render: college => college.zip_code,
      // render: (value: string, id: string) => (
      //   <EditableCell
      //     value={value}
      //     onBlur={(value) =>
      //       updateData({ id, column: "COLLEGE_ZIP_CODE", value })
      //     }
      //   />
      // ),
    },

    {
      key: "TIMESTAMP",
      title: "TIMESTAMP",
      label: "TIMESTAMP",
      isSort: true,
      dbColName: "TIMESTAMP",
    },

    {
      key: "INTERVIEWER_ID",
      title: "INTERVIEWER ID",
      label: "INTERVIEWER ID",
      dbColName: "interviewer",
      color: "bg-[#24A69A] text-black",
      render: interviewer => interviewer.id
    },
    {
      key: "INTERVIEWER_NAME",
      title: "INTERVIEWER NAME",
      label: "INTERVIEWER NAME",
      dbColName: "interviewer",
      color: "bg-[#24A69A] text-black",
      render: interviewer => interviewer.name,
      header: () => (
        <button type="button" onClick={() => setShowInterview(!showInterview)}>
          {showInterview ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
              />
            </svg>
          )}
        </button>
      ),
    },

    {
      key: "INTERVIEWER_PHONE",
      title: "INTERVIEWER PHONE",
      label: "INTERVIEWER PHONE",
      render: interviewer => interviewer.phone,
      dbColName: "interviewer",
      color: "bg-[#24A69A] text-black",
    },
    {
      key: "INTERVIEWER_EMAIL",
      title: "INTERVIEWER EMAIL",
      label: "INTERVIEWER EMAIL",
      dbColName: "interviewer",
      color: "bg-[#24A69A] text-black",
      render: interviewer => interviewer.email
    },
    {
      key: "INTERVIEWER_TIMEZONE",
      title: "INTERVIEWER TIMEZONE",
render: interviewer => interviewer.timezone,
      label: "INTERVIEWER TIMEZONE",
      dbColName: "interviewer",
      color: "bg-[#24A69A] text-black",
    },
    {
      key: "SALES_LEADER_ID",
      title: "SALES LEADER ID",
      label: "SALES LEADER ID",
      dbColName: "sales_leader",
      render: sales_leader => sales_leader.id,
      color: "bg-blue-400 text-black",
    },
    {
      key: "SALES_LEADER_NAME",
      title: "SALES LEADER NAME",
      label: "SALES LEADER NAME",
      dbColName: "sales_leader",
      render: sales_leader => sales_leader.name,
      color: "bg-blue-400 text-black",
      // render: (value: string, id: string) => (
      //   <EditableCell
      //     value={value}
      //     onBlur={(value) =>
      //       updateData({ id, column: "SALES_LEADER_NAME", value })
      //     }
      //   />
      // ),
      header: () => (
        <button type="button" onClick={() => setShowLeader(!showLeader)}>
          {showLeader ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
              />
            </svg>
          )}
        </button>
      ),
    },
    {
      key: "SALES_LEADER_PHONE",
      title: "SALES LEADER PHONE",
      label: "SALES LEADER PHONE",
      dbColName: "sales_leader",
      color: "bg-blue-400 text-black",
      render: sales_leader => sales_leader.phone
      // render: (value: string, id: string) => (
      //   <EditableCell
      //     value={value}
      //     onBlur={(value) =>
      //       updateData({ id, column: "SALES_LEADER_PHONE", value })
      //     }
      //   />
      // ),
    },
    {
      key: "SALES_LEADER_EMAIL",
      title: "SALES LEADER EMAIL",
      label: "SALES LEADER EMAIL",
      color: "bg-blue-400 text-black",
      dbColName: "sales_leader",
      render: sales_leader => sales_leader.email
    },
    {
      key: "SALES_LEADER_TIMEZONE",
      title: "SALES LEADER TIMEZONE",
      label: "SALES LEADER TIMEZONE",
      dbColName: "sales_leader",
      color: "bg-blue-400 text-black",
      render: sales_leader => sales_leader.timezone
      // render: (value: string, id: string) => {
      //   return (
      //     <select
      //       onChange={(e) =>
      //         updateData({
      //           id,
      //           column: "SALES_LEADER_TIMEZONE",
      //           value: e.target.value,
      //         })
      //       }
      //       defaultValue={value}
      //       className={tabStyles.cellSelect}
      //     >
      //       <option value="">Select</option>
      //       {TIMEZONES.map((item) => (
      //         <option key={item} value={item}>
      //           {item}
      //         </option>
      //       ))}
      //     </select>
      //   );
      // },
    },
    {
      key: "OUTREACH",
      title: "OUTREACH",
      label: "OUTREACH",
      dbColName: "outreach",
      render: outreach => outreach.outreach,
      color: "bg-green-300 text-black",
      // render: (value: string, id: string) => {
      //   return (
      //     <select
      //       onChange={(e) =>
      //         updateData({ id, column: "OUTREACH", value: e.target.value })
      //       }
      //       defaultValue={value}
      //       className={tabStyles.cellSelect}
      //     >
      //       <option value="">Select</option>
      //       <option value="Call">Call</option>
      //       <option value="Text">Text</option>
      //       <option value="Email">Email</option>
      //       <option value="Social Media">Social Media</option>
      //     </select>
      //   );
      // },
    },
    {
      key: "RECRUITER_NOTES",
      title: "OUTREACH NOTES",
      label: "OUTREACH NOTES",
      dbColName: "outreach",
      color: "bg-green-300 text-black",
      render: outreach => outreach.notes
      // render: (value: string, id: string) => (
      //   <EditableCell
      //     value={value}
      //     onBlur={(value) =>
      //       updateData({ id, column: "RECRUITER_NOTES", value })
      //     }
      //   />
      // ),
    },
    {
      key: "INTRO_VIDEO",
      title: "INTRO VIDEO",
      label: "INTRO VIDEO",
      dbColName: "INTRO_VIDEO",
      color: "bg-green-300 text-black",
      render: (value: string, id: string) => {
        return (
          <select
            onChange={(e) =>
              updateData({ id, column: "INTRO_VIDEO", value: e.target.value })
            }
            defaultValue={value}
            className={tabStyles.cellSelect}
          >
            <option value="">Select</option>
            <option value="Sent">Sent</option>
            <option value="Started">Started</option>
            <option value="Finished">Finished</option>
          </select>
        );
      },
    },
    {
      key: "FOLLOW_UP",
      title: "FOLLOW UP",
      label: "FOLLOW UP",
      dbColName: "follow_up",
      color: "bg-green-300 text-black",
      render : follow => follow.follow_up
      // render: (value: string, id: string) => {
      //   return (
      //     <select
      //       onChange={(e) =>
      //         updateData({ id, column: "FOLLOW_UP", value: e.target.value })
      //       }
      //       defaultValue={value}
      //       className={tabStyles.cellSelect}
      //     >
      //       <option value="">Select</option>
      //       {INTERVIEW.map((item) => (
      //         <option key={item} value={item}>
      //           {item}
      //         </option>
      //       ))}
      //     </select>
      //   );
      // },
    },
    {
      key: "FOLLOW_UP_CALL_DATE",
      title: "FOLLOW UP CALL DATE",
      label: "FOLLOW UP CALL DATE",
      color: "bg-green-300 text-black",
      dbColName: "follow_up",
render: follow => follow.call_date
      // render: (value: string, id: string) => (
      //   <EditableCell
      //     value={value}
      //     onBlur={(value) =>
      //       updateData({ id, column: "FOLLOW_UP_CALL_DATE", value })
      //     }
      //   />
      // ),
    },
    {
      key: "FOLLOW_UP_CALL_TIME",
      title: "FOLLOW UP CALL TIME",
      label: "FOLLOW UP CALL TIME",
      dbColName: "follow_up",
      render: follow => follow.call_time,
      color: "bg-green-300 text-black",
      // render: (value: string, id: string) => (
      //   <EditableCell
      //     value={value}
      //     onBlur={(value) =>
      //       updateData({ id, column: "FOLLOW_UP_CALL_TIME", value })
      //     }
      //   />
      // ),
    },
    {
      key: "FOLLOW_UP_CANDIDATE_TIME_ZONE",
      title: "FOLLOW UP CANDIDATE TIME ZONE",
      label: "FOLLOW UP CANDIDATE TIME ZONE",
      dbColName: "follow_up",
      color: "bg-green-300 text-black",
      render: follow => follow.follow_timzone
      // render: (value: string, id: string) => {
      //   return (
      //     <select
      //       onChange={(e) =>
      //         updateData({
      //           id,
      //           column: "FOLLOW_UP_CANDIDATE_TIME_ZONE",
      //           value: e.target.value,
      //         })
      //       }
      //       defaultValue={value}
      //       className={tabStyles.cellSelect}
      //     >
      //       <option value="">Select</option>
      //       {TIMEZONES.map((item) => (
      //         <option key={item} value={item}>
      //           {item}
      //         </option>
      //       ))}
      //     </select>
      //   );
      // },
    },
    // {
    //   key: "FOLLOW_UP_RECRUITER_TIMEZONE",
    //   title: "FOLLOW UP RECRUITER TIMEZONE",
    //   label: "FOLLOW UP RECRUITER TIMEZONE",
    //   dbColName: "FOLLOW_UP_RECRUITER_TIMEZONE",
    //   color: "bg-green-300 text-black",
    //   render: (value: string, id: string) => {
    //     return (
    //       <select
    //         onChange={(e) =>
    //           updateData({
    //             id,
    //             column: "FOLLOW_UP_RECRUITER_TIMEZONE",
    //             value: e.target.value,
    //           })
    //         }
    //         defaultValue={value}
    //         className={tabStyles.cellSelect}
    //       >
    //         <option value="">Select</option>
    //         {TIMEZONES.map((item) => (
    //           <option key={item} value={item}>
    //             {item}
    //           </option>
    //         ))}
    //       </select>
    //     );
    //   },
    // },
    {
      key: "FOLLOW_UP_NOTES",
      title: "FOLLOW UP NOTES",
      label: "FOLLOW UP NOTES",
      dbColName: "follow_up",
      color: "bg-green-300 text-black",
      render: follow => follow?.notes
      // render: (value: string, id: string) => (
      //   <EditableCell
      //     value={value}
      //     onBlur={(value) =>
      //       updateData({ id, column: "FOLLOW_UP_NOTES", value })
      //     }
      //   />
      // ),
    },
    {
      key: "CANDIDATE_INTEREST",
      title: "CANDIDATE INTEREST",
      label: "CANDIDATE INTEREST",
      dbColName: "candidate_interest",
      color: "bg-blue-500 text-black",
      render: candidate => candidate.interest
      // render: (value: string, id: string) => (
      //   <select
      //     onChange={(e) =>
      //       updateData({
      //         id,
      //         column: "CANDIDATE_INTEREST",
      //         value: e.target.value,
      //       })
      //     }
      //     defaultValue={value}
      //     className={tabStyles.cellSelect + " w-24"}
      //   >
      //     <option value="">Select</option>
      //     <option value="***">***</option>
      //     <option value="**">**</option>
      //     <option value="*">*</option>
      //   </select>
      // ),
    },
    {
      key: "recuriter_interest",
      label: "Recuriter Interest",
      dbColName: "recruiter_interest",
      title: "Recuriter Interest",
      render: recuriter => recuriter?.interest
    },
    {
      key: "statuss",
      title: "candidates status",
      label: "RECRUITER INTEREST",
      dbColName: "candidate_interest",
      color: "bg-blue-500 text-black",
      // render: (value: string, id: string) => (
      //   <select
      //     onChange={(e) =>
      //       updateData({
      //         id,
      //         column: "RECRUITER_INTEREST",
      //         value: e.target.value,
      //       })
      //     }
      //     defaultValue={value}
      //     className={tabStyles.cellSelect + " w-24"}
      //   >
      //     <option value="">Select</option>
      //     <option value="***">***</option>
      //     <option value="**">**</option>
      //     <option value="*">*</option>
      //   </select>
      // ),
      render: candidate => candidate.candidate_status
    },
    {
      key: "SCREENING_RESULT",
      title: "SCREENING RESULT",
      label: "SCREENING RESULT",
      dbColName: "screening",
      color: "bg-blue-500 text-black",
      render: screening => screening.result
      // render: (value: string, id: string) => (
      //   <select
      //     onChange={(e) =>
      //       updateData({
      //         id,
      //         column: "SCREENING_RESULT",
      //         value: e.target.value,
      //       })
      //     }
      //     defaultValue={value}
      //     className={tabStyles.cellSelect}
      //   >
      //     <option value="">Select</option>
      //     <option value="Accepted">Accepted</option>
      //     <option value="Rejected">Rejected</option>
      //   </select>
      // ),
    },
    {
      key: "SCREENING_DATE",
      title: "SCREENING DATE",
      label: "SCREENING DATE",
      color: "bg-blue-500 text-black",
      dbColName: "screening",
      render: screening => screening.date
    },
    {
      key: "SCREENING_NOTES",
      title: "SCREENING NOTES",
      label: "SCREENING NOTES",
      dbColName: "screening",
      render: screening => screening?.notes,
      color: "bg-blue-500 text-black",
      // render: (value: string, id: string) => (
      //   <EditableCell
      //     value={value}
      //     onBlur={(value) =>
      //       updateData({ id, column: "SCREENING_NOTES", value })
      //     }
      //   />
      // ),
    },
    {
      key: "INTERVIEW",
      title: "INTERVIEW",
      label: "INTERVIEW",
      dbColName: "interview",
      render: interview => interview.interview,
      color: "bg-[#8989EB] text-black",
      // render: (value: string, id: string) => {
      //   return (
      //     <select
      //       onChange={(e) =>
      //         updateData({ id, column: "INTERVIEW", value: e.target.value })
      //       }
      //       defaultValue={value}
      //       className={tabStyles.cellSelect}
      //     >
      //       <option value="">Select</option>
      //       {INTERVIEW.map((item) => (
      //         <option key={item} value={item}>
      //           {item}
      //         </option>
      //       ))}
      //     </select>
      //   );
      // },
    },
    {
      key: "INTERVIEW_SCHEDULED_DATE",
      title: "INTERVIEW SCHEDULED DATE",
      label: "INTERVIEW SCHEDULED DATE",
      dbColName: "interview",
      color: "bg-[#8989EB] text-black",
      render: interview => interview.interview_date
      // render: (value: string, id: string) => (
      //   <EditableCell
      //     value={value}
      //     onBlur={(value) =>
      //       updateData({ id, column: "INTERVIEW_SCHEDULED_DATE", value })
      //     }
      //   />
      // ),
    },
    {
      key: "INTERVIEW_SCHEDULED_TIME",
      title: "INTERVIEW SCHEDULED TIME",
      label: "INTERVIEW SCHEDULED TIME",
      dbColName: "interview",
      color: "bg-[#8989EB] text-black",
      render: interview => interview.interview_time
      // render: (value: string, id: string) => (
      //   <EditableCell
      //     value={value}
      //     onBlur={(value) =>
      //       updateData({ id, column: "INTERVIEW_SCHEDULED_TIME", value })
      //     }
      //   />
      // ),
    },
    {
      key: "INTERVIEW_SCHEDULED_C_TIMEZONE",
      title: "INTERVIEW SCHEDULED C-TIMEZONE",
      label: "INTERVIEW SCHEDULED C-TIMEZONE",
      dbColName: "interview",
      color: "bg-[#8989EB] text-black",
      render: interview => interview.interview_timezone
      // render: (value: string, id: string) => (
      //   <select
      //     onChange={(e) =>
      //       updateData({
      //         id,
      //         column: "INTERVIEW_SCHEDULED_C_TIMEZONE",
      //         value: e.target.value,
      //       })
      //     }
      //     defaultValue={value}
      //     className={tabStyles.cellSelect}
      //   >
      //     <option value="">Select</option>
      //     {TIMEZONES.map((item) => (
      //       <option key={item} value={item}>
      //         {item}
      //       </option>
      //     ))}
      //   </select>
      // ),
    },
    // {
    //   key: "INTERVIEW_SCHEDULED_NOTES",
    //   title: "INTERVIEW SCHEDULED NOTES",
    //   label: "INTERVIEW SCHEDULED NOTES",
    //   dbColName: "interview",
    //   color: "bg-[#8989EB] text-black",
    //   render: interview => interview.interview_notes
      // render: (value: string, id: string) => (
      //   <EditableCell
      //     value={value}
      //     onBlur={(value) =>
      //       updateData({ id, column: "INTERVIEW_SCHEDULED_NOTES", value })
      //     }
      //   />
      // ),
    // },
    {
      key: "INTERVIEW_NOTES",
      title: "INTERVIEW NOTES",
      label: "INTERVIEW NOTES",
      dbColName: "interview",
      color: "bg-[#8989EB] text-black",
      render: interview => interview.interview_notes
      // render: (value: string, id: string) => (
      //   <EditableCell
      //     value={value}
      //     onBlur={(value) =>
      //       updateData({ id, column: "INTERVIEW_NOTES", value })
      //     }
      //   />
      // ),
    },
    {
      key: "INTERVIEW_RESULT",
      title: "INTERVIEW RESULT",
      label: "INTERVIEW RESULT",
      dbColName: "interview",
      color: "bg-[#8989EB] text-black",
      render: interview => interview.result
      // render: (value: string, id: string) => (
      //   <select
      //     onChange={(e) =>
      //       updateData({
      //         id,
      //         column: "INTERVIEW_RESULT",
      //         value: e.target.value,
      //       })
      //     }
      //     defaultValue={value}
      //     className={tabStyles.cellSelect}
      //   >
      //     <option value="">Select</option>
      //     <option value="Accepted">Accepted</option>
      //     <option value="Rejected">Rejected</option>
      //   </select>
      // ),
    },
    {
      key: "AGREEMENT_SENT_DATE",
      title: "AGREEMENT SENT DATE",
      label: "AGREEMENT SENT DATE",
      dbColName: "aggrement",
      color: "bg-[#F36524] text-black",
      render: aggrement =>   aggrement.sent_date
    },
    {
      key: "SIGNED",
      title: "SIGNED",
      label: "SIGNED",
      dbColName: "aggrement",
      color: "bg-[#F36524] text-black",
      render: aggrement => aggrement.signed
    },
    {
      key: "ONBOARDED",
      title: "ONBOARDED",
      label: "ONBOARDED",
      dbColName: "onboarding",
      color: "bg-[#F36524] text-black",
      render: onboarding => onboarding?.onboarded
    },
    {
      key: "STREET_GENIE_LOGIN",
      title: "STREET GENIE LOGIN",
      label: "STREET GENIE LOGIN",
      dbColName: "candidate",
      color: "bg-[#F36524] text-black",
      render: candidate => candidate.street_genie_login
    },
    {
      key: "SALES_TRAINING",
      title: "SALES TRAINING",
      label: "SALES TRAINING",
      dbColName: "candidate",
      color: "bg-[#F36524] text-black",
      render: candidate => candidate.sales_training
    },
    {
      key: "ASSIGN_CANDIDATE_BID",
      title: "ASSIGN CANDIDATE BADGE ID",
      label: "ASSIGN CANDIDATE BADGE ID",
      dbColName: "candidate",
      color: "bg-[#F8CA4D]",
      render: candidate => candidate.badgeid
    },
    {
      key: "BOOTCAMP",
      title: "BOOTCAMP",
      label: "BOOTCAMP",
      dbColName: "bootcamp",
      color: "bg-primary text-black",
      render: bootcamp => bootcamp.bootcamp,
      header: () => (
        <button type="button" onClick={() => setShowBootcamp(!showBootcamp)}>
          {showBootcamp ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
              />
            </svg>
          )}
        </button>
      ),
    },
    {
      key: "BOOTCAMP_TYPE",
      title: "BOOTCAMP TYPE",
      label: "BOOTCAMP TYPE",
      dbColName: "bootcamp",
      color: "bg-primary text-black",
      render: bootcamp => bootcamp.type
    },
    {
      key: "BOOTCAMP_SCHEDULED_DATE",
      title: "BOOTCAMP SCHEDULED DATE",
      label: "BOOTCAMP SCHEDULED DATE",
      dbColName: "bootcamp",
      color: "bg-primary text-black",
      render: bootcamp => bootcamp.scheduled_date
    },
    {
      key: "BOOTCAMP_SCHEDULED_TIME",
      title: "BOOTCAMP SCHEDULED TIME",
      label: "BOOTCAMP SCHEDULED TIME",
      dbColName: "bootcamp",
      color: "bg-primary text-black",
      render: bootcamp => bootcamp.scheduled_time
    },
    {
      key: "BOOTCAMP_TIMEZONE",
      title: "BOOTCAMP TIMEZONE",
      label: "BOOTCAMP TIMEZONE",
      dbColName: "bootcamp",
      color: "bg-primary text-black",
      render: bootcamp => bootcamp.timezone
    },
    {
      key: "BOOTCAMP_ADDRESS",
      title: "BOOTCAMP ADDRESS",
      label: "BOOTCAMP ADDRESS",
      dbColName: "bootcamp",
      color: "bg-primary text-black",
      render: bootcamp => bootcamp.address
    },
    {
      key: "BOOTCAMP_NOTES",
      title: "BOOTCAMP NOTES",
      label: "BOOTCAMP NOTES",
      dbColName: "bootcamp",
      color: "bg-primary text-black",
      render: bootcamp => bootcamp.notes
    },

    {
      key: "EMPLOYEE_ID",
      title: "EMPLOYEE ID",
      label: "EMPLOYEE ID",
      dbColName: "candidate",
      color: "bg-[#46818D] text-black",
      render: candidate => candidate.employee_id
    },
    {
      key: "RECRUITER_NAME",
      title: "RECRUITER NAME",
      label: "RECRUITER NAME",
      dbColName: "candidate",
      color: "bg-[#46818D] text-black",
      render: candidate => candidate.recruiter_name
    },
    {
      key: "TEAM_NAME",
      title: "TEAM NAME",
      label: "TEAM NAME",
      dbColName: "candidate",
      color: "bg-[#46818D] text-black",
      render: candidate => candidate.team_name
    },
    {
      key: "MGMT_GROUP_NAME",
      title: "MGMT GROUP NAME",
      label: "MGMT GROUP NAME",
      dbColName: "candidate",
      color: "bg-[#46818D] text-black",
      render: candidate => candidate.mgmt_group_name
    },
    {
      key: "SR_MGMT_GROUP_NAME",
      title: "SR MGMT GROUP NAME",
      label: "SR MGMT GROUP NAME",
      dbColName: "candidate",
      color: "bg-[#46818D] text-black",
      render: candidate => candidate.sr_mgmt_group_name
    },
    {
      key: "REGION_NAME",
      title: "REGION NAME",
      label: "REGION NAME",
      dbColName: "candidate",
      color: "bg-[#46818D] text-black",
      render: candidate => candidate.region_name
    },
    {
      key: "SENIOR_REGION_NAME",
      title: "SENIOR REGION NAME",
      label: "SENIOR REGION NAME",
      dbColName: "candidate",
      color: "bg-[#46818D] text-black",
      render: candidate => candidate.senior_region_name
    },
    {
      key: "PARTNERSHIP_NAME",
      title: "PARTNERSHIP NAME",
      label: "PARTNERSHIP NAME",
      dbColName: "candidate",
      color: "bg-[#46818D] text-black",
      render: candidate => candidate.partnership_name
    },
    {
      key: "DIVISION_NAME",
      title: "DIVISION NAME",
      label: "DIVISION NAME",
      dbColName: "candidate",
      color: "bg-[#46818D] text-black",
      render: candidate => candidate.division_name
    },
  ];

  const [data, setData] = useState([]);
  const [showCandidate, setShowCandidate] = useState(false);
  const [showTimeStamp, setShowTimeStamp] = useState(false);
  const [showSource, setShowSource] = useState(false);
  const [showHome, setShowHome] = useState(false);
  const [showCollege, setShowCollege] = useState(false);
  const [showInterview, setShowInterview] = useState(false);
  const [showBootcamp, setShowBootcamp] = useState(false);
  const [showLeader, setShowLeader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [exportData, setExportData] = useState([]);
  const [exporting, setExporting] = useState(false);
  const [filterData, setFilterData] = useState<any>(null);
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 500,
    // origination_date: "",
    homeCity: "",
    homeState: "",
    outreach: "",
    recruitingCategory: "",
    recruitingLocation: "",
    startDate: "",
    endDate: "",
    source: "",
    sourceCategory: "",
  });

  const updateData = (data: any) => {
    service
      .updateColumn(data)
      .then((resp) => {
        alert("Data Updated successfully");
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    service
      .getCandidateFilters()
      .then((res) => {
        setFilterData(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    service
      .getCanidates(pagination)
      .then((resp) => {
        setData(resp.data.data);
        setTotalItems(resp.data.total);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [pagination]);

  const handleChange = (
    event: ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    const { name, value } = event.target;

    setPagination({
      ...pagination,
      [name]: value,
    });
  };

  const onClickExport = () => {
    exportToCSV(data, "recruiting");
    // setExporting(true);
    // service
    //   .getCanidates({ ...pagination, limit: totalItems })
    //   .then((resp) => {
    //     setExportData(resp.data.data);
    //     exportToCSV(resp.data.data, "recuriting");
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   })
    //   .finally(() => {
    //     setExporting(false);
    //   });
  };

  const ColumnFilters = () => {
    return (
      <div className="flex z-50 flex-wrap gap-3 mt-4">
        <div className="flex flex-1 basis-2 flex-col gap-1">
          <label className="text-xs whitespace-nowrap text-gray-800">
            From
          </label>
          <input
            className={tabStyles.select}
            type="date"
            onChange={handleChange}
            name="startDate"
            value={pagination.startDate}
          />
        </div>
        <div className=" flex flex-1 basis-2 flex-col gap-1">
          <label className="text-xs whitespace-nowrap text-gray-800">To</label>
          <input
            className={tabStyles.select}
            type="date"
            onChange={handleChange}
            name="endDate"
            value={pagination.endDate}
          />
        </div>

        <div className="flex flex-1 basis-2 flex-col gap-1">
          <label className="text-xs whitespace-nowrap text-gray-800 ">
            Home City
          </label>
          <select
            onChange={handleChange}
            name="homeCity"
            value={pagination.homeCity}
            className={tabStyles.select}
          >
            <option value="">Select</option>
            {filterData?.home_city
              .filter((item) => item.HOME_CITY)
              .map((item) => (
                <option key={item.HOME_CITY} value={item.home_city}>
                  {item.HOME_CITY}
                </option>
              ))}
          </select>
        </div>
        <div className="flex flex-1 basis-2 flex-col gap-1">
          <label className="text-xs whitespace-nowrap text-gray-800">
            Home State
          </label>
          <select
            onChange={handleChange}
            value={pagination.homeState}
            name="homeState"
            className={tabStyles.select}
          >
            <option value="">Select</option>
            {filterData?.home_state
              .filter((item) => item.HOME_STATE)
              .map((item) => (
                <option key={item.HOME_STATE} value={item.HOME_STATE}>
                  {item.HOME_STATE}
                </option>
              ))}
          </select>
        </div>
        <div className="flex flex-1 basis-2 flex-col gap-1">
          <label className="text-xs whitespace-nowrap text-gray-800">
            Outreach
          </label>
          <select
            onChange={handleChange}
            name="outreach"
            value={pagination.outreach}
            className={tabStyles.select}
          >
            <option value="">Select</option>
            {filterData?.outreach
              .filter((item) => item.OUTREACH)
              .map((item) => (
                <option key={item.OUTREACH} value={item.OUTREACH}>
                  {item.OUTREACH}
                </option>
              ))}
          </select>
        </div>
        <div className="flex flex-1 basis-2 flex-col gap-1">
          <label className="text-xs whitespace-nowrap text-gray-800">
            Recuriting Category
          </label>
          <select
            onChange={handleChange}
            name="recruitingCategory"
            value={pagination.recruitingCategory}
            className={tabStyles.select}
          >
            <option value="">Select</option>
            {filterData?.recruiting_category
              .filter((item) => item.RECRUITING_CATEGORY)
              .map((item) => (
                <option
                  key={item.RECRUITING_CATEGORY}
                  value={item.RECRUITING_CATEGORY}
                >
                  {item.RECRUITING_CATEGORY}
                </option>
              ))}
          </select>
        </div>
        <div className="flex flex-1 flex-col gap-1">
          <label className="text-xs whitespace-nowrap text-gray-800 ">
            Recruting Location
          </label>
          <select
            onChange={handleChange}
            name="recruitingLocation"
            value={pagination.recruitingLocation}
            className={tabStyles.select}
          >
            <option value="">Select</option>
            {filterData?.recruiting_location
              .filter((item) => item.RECRUITING_LOCATION)
              .map((item) => (
                <option
                  key={item.RECRUITING_LOCATION}
                  value={item.RECRUITING_LOCATION}
                >
                  {item.RECRUITING_LOCATION}
                </option>
              ))}
          </select>
        </div>
        <div className="flex flex-1 flex-col gap-1">
          <label className="text-xs whitespace-nowrap text-gray-800">
            Source
          </label>
          <select
            onChange={handleChange}
            name="source"
            value={pagination.source}
            className={tabStyles.select}
          >
            <option value="">Select</option>
            {filterData?.source
              .filter((item) => item.SOURCE)
              .map((item) => (
                <option key={item.SOURCE} value={item.SOURCE}>
                  {item.SOURCE}
                </option>
              ))}
          </select>
        </div>
        <div className="flex flex-1 flex-col gap-1">
          <label className="text-xs whitespace-nowrap text-gray-800">
            Source Category
          </label>
          <select
            onChange={handleChange}
            name="sourceCategory"
            value={pagination.sourceCategory}
            className={tabStyles.select}
          >
            <option value="">Select</option>
            {filterData?.source_category
              .filter((item) => item.SOURCE_CATEGORY)
              .map((item) => (
                <option key={item.SOURCE_CATEGORY} value={item.SOURCE_CATEGORY}>
                  {item.SOURCE_CATEGORY}
                </option>
              ))}
          </select>
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      <div className="flex flex-col mb-4">
        <h1 className="font-medium text-gray-800 tracking-wide text-xl">
          Recruiting Tools
        </h1>
        <p className="text-gray-500 text-sm">View all data</p>
      </div>
      {/* Table */}
      <Table
        data={data}
        loadingData={loading}
        setPagination={setPagination}
        pagination={pagination}
        totalItems={totalItems}
        handleExport={onClickExport}
        setData={setData}
        exporting={exporting}
        exportData={exportData}
        columnFilters={<ColumnFilters />}
        columns={columns.map((item) => {
          if (CANDIDATE_DETAILS.includes(item.title)) {
            return {
              ...item,
              show: showCandidate,
            };
          }
          if (SOURCE_DETAILS.includes(item.title)) {
            return {
              ...item,
              show: showSource,
            };
          }
          if (
            item.title.includes("INTERVIEWER") &&
            item.title !== "INTERVIEWER NAME"
          ) {
            return {
              ...item,
              show: showInterview,
            };
          }
          if (item.title.includes("COLLEGE") && item.title !== "COLLEGE NAME") {
            return {
              ...item,
              show: showCollege,
            };
          }

          if (HOME_DETAILS.includes(item.title)) {
            return {
              ...item,
              show: showHome,
            };
          }

          if (item.title.includes("TS") && item.title !== "MOST RECENT TS") {
            return {
              ...item,
              show: showTimeStamp,
            };
          }
          if (item.title.includes("BOOTCAMP") && item.title !== "BOOTCAMP") {
            return {
              ...item,
              show: showBootcamp,
            };
          }

          if (
            item.title.includes("LEADER") &&
            item.title !== "SALES LEADER NAME"
          ) {
            return {
              ...item,
              show: showLeader,
            };
          }
          return item;
        })}
      />
    </Fragment>
  );
};

const tabStyles = {
  default:
    "px-5 py-3 flex-1 hover:text-gray-700 hover:drop-shadow-md transition ease-in-out hover:bg-gray-50 hover:rounded-md",
  active: "font-medium text-gray-700 drop-shadow-md bg-gray-50 rounded-md",
  select:
    "focus:border-gray-300 drop-shadow-sm focus:ring-0 w-full focus:outline-none rounded-md border border-gray-300 text-gray-700 text-xs",
  cellSelect:
    "bg-transparent px-5 text-sm italic rounded-md focus:border-gray-100 border border-gray-300 appearance-none focus:ring-0 focus:outline-none",
};
