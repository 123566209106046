import { Modal } from "common/Modal";
import { useLocation } from "hooks/useLocations";
import { useNotifications } from "hooks/useNotification";
import { useEffect, useState } from "react";
import UserService from "services/user.service";
import { convertTo12HourFormat } from "utils/funcs";

const service = new UserService();

export const AddTrainingModal = ({
  state,
  setState,
  showModal,
  setShowModal,
  data,
  setData,
}) => {
  const [saving, setSaving] = useState(false);
  const [options, setOptions] = useState<any>([]);

  const { successMessage } = useNotifications();
  const { locations } = useLocation();

  useEffect(() => {
    service
      .getList()
      .then((resp) => {
        if (resp.data.success) {
          setOptions(
            resp.data.data
              .filter(
                (item) =>
                  item.max_position === "Manager" ||
                  item.max_position === "Senior Manager" ||
                  item.max_position === "Regional" ||
                  item.max_position === "Senior Regional" ||
                  item.max_position === "Partner"
              )
              .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
          );
        }
        // setManagers(resp.data.data.manager);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setState({
      ...state,
      [name]: value,
    });
  };

  const handleChangeSelect = (event) => {
    const { value } = event.target;
    const find = options.find((item) => item.badgeid === value);
    setState({
      ...state,
      manager_name: find.name,
      badge_id: find.badgeid,
      manager_id: find.badgeid,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSaving(true);

    service
      .addTraining(state)
      .then(() => {
        const newData = [...data];
        newData.unshift(state);
        setData(newData);
        successMessage("Training added successfully");
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setSaving(false);
        setShowModal(false);
      });
  };

  console.log(state);

  return (
    <Modal
      width="w-full md:w-1/2"
      showModal={showModal}
      setShowModal={setShowModal}
      title="Add Weekly Training"
    >
      <form onSubmit={handleSubmit} className="flex flex-col gap-4">
        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col gap-1">
            <label className="text-sm text-gray-800">Title</label>
            <input
              type="text"
              placeholder="Title"
              value={state.title}
              required
              name="title"
              onChange={(e) => handleChange(e)}
              className={styles.input}
            />
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-sm text-gray-800">Type</label>
            <select
              placeholder="Type"
              name="type"
              value={state.type}
              required
              onChange={(e) => handleChange(e)}
              className={styles.input}
            >
              <option value="" selected disabled>
                Select Type
              </option>
              <option value="Weekly Training">Weekly Training</option>
              <option value="Bootcamp | Smart Home">
                Bootcamp | Smart Home
              </option>
              <option value="Bootcamp | Solar">Bootcamp | Solar</option>
            </select>
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-sm text-gray-800">Leader</label>
            <select
              placeholder="Badge"
              name="badge_id"
              required
              value={state.badgeid}
              onChange={(e) => handleChangeSelect(e)}
              className={styles.input}
            >
              <option value="" selected disabled>
                Select Leader
              </option>
              {options?.map((item) => (
                <option key={item.badgeid} value={item.badgeid}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-sm text-gray-800">Address</label>
            <input
              type="text"
              name="address"
              placeholder="Address"
              required
              value={state.address}
              onChange={(e) => handleChange(e)}
              className={styles.input}
            />
          </div>
          {/* <select
            placeholder="Manager Name"
            name="manager_name"
            value={state.manager_name}
            onChange={(e) => {
              setState({
                ...state,
                manager_name: e.target.value.split("-")[1],
                manager_id: e.target.value.split("-")[0],
              });
            }}
            className={styles.input}
          >
            <option value="" selected disabled>
              Select Manager
            </option>
            {managers.map((item) => (
              <option key={item.id} value={`${item.badgeid}-${item.name}`}>
                {item.name}
              </option>
            ))}
          </select> */}
          <div className="flex flex-col gap-1">
            <label className="text-sm text-gray-800">Location</label>
            <select
              placeholder="Location"
              name="location"
              required
              value={state.location}
              onChange={(e) => handleChange(e)}
              className={styles.input}
            >
              <option value="" selected disabled>
                Select Location
              </option>
              {locations
                .filter((item) => item.location_type === "office")
                .map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.office_name}
                  </option>
                ))}
            </select>
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-sm text-gray-800">Highlights</label>
            <input
              type="text"
              placeholder="Highlights"
              value={state.highlights}
              name="highlights"
              onChange={(e) => handleChange(e)}
              className={styles.input}
            />
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-sm text-gray-800">Trainer</label>
            <input
              type="text"
              placeholder="Trainer Name"
              required
              value={state.trainer_name}
              name="trainer_name"
              onChange={(e) => handleChange(e)}
              className={styles.input}
            />
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-sm text-gray-800">Meeting Date </label>
            <input
              type="date"
              placeholder="Meeting Date"
              name="meeting_date"
              min={'2024-10-01'}
              required
              max="2025-04-05"
              value={state.meeting_date}
              onChange={(e) => handleChange(e)}
              className={styles.input}
            />
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-sm text-gray-800">Meeting Start Time </label>
            <input
              type="time"
              placeholder="meting_start_time"
              name="meting_start_time"
              required
              // value={state.meting_start_time}
              onChange={(e) =>
                setState({
                  ...state,
                  meting_start_time: convertTo12HourFormat(e.target.value),
                })
              }
              className={styles.input}
            />
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-sm text-gray-800">Meeting End Time </label>
            <input
              type="time"
              placeholder="meting_end_time"
              name="meting_end_time"
              required
              // value={state.meting_end_time}
              onChange={(e) =>
                setState({
                  ...state,
                  meting_end_time: convertTo12HourFormat(e.target.value),
                })
              }
              className={styles.input}
            />
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-sm text-gray-800">Zip Code</label>

            <input
              type="number"
              placeholder="Zip Code"
              required
              value={state.zip_code}
              name="zip_code"
              onChange={(e) => handleChange(e)}
              className={styles.input}
            />
          </div>
        </div>

        <button
          type="submit"
          disabled={saving}
          className="bg-black disabled:cursor-not-allowed rounded-md font-medium text-sm disabled:bg-opacity-30 text-white p-2.5"
        >
          {saving ? "Saving..." : "Save"}
        </button>
      </form>
    </Modal>
  );
};

const styles = {
  input:
    "rounded-md focus:border-gray-400 focus:outline-none w-full focus:ring-0 border-gray-300 px-4 py-2.5 text-sm text-gray-800",
};
