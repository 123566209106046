import { useEffect, useState } from "react";
import UserService from "services/user.service";

const service = new UserService();

export const useReimbursement = (type, pagination) => {
  const [reimbursements, setReimbursements] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    setLoading(true);
    if (type === "Rep") {
      service
        .getRepReimbursements(pagination)
        .then((resp) => {
          setReimbursements(resp.data.data);
          setTotalItems(resp.data.data.length);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      service
        .getRegionalReimbursements(pagination)
        .then((resp) => {
          console.log(resp.data);
          setReimbursements(resp.data.data);
          setTotalItems(resp.data.data.length);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [type, pagination]);

  return {
    reimbursements,
    setReimbursements,
    loading,
    totalItems,
  };
};
