import { useEffect, useState } from "react";
import UserService from "services/user.service";

const service = new UserService();

export const useLocation = () => {
  const [locations, setLocations] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    setLoading(true);
    service
      .getRecruiterLocations()
      .then((resp) => {
        setLocations(
          resp.data.data
        );
        setTotalItems(resp.data.data.length);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return {
    locations,
    setLocations,
    loading,
    totalItems,
  };
};
