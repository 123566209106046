import { Table } from "common/Table/Table";
import { useRouter } from "hooks/useRouter";
import React, { Fragment, useEffect, useState } from "react";
import UserService from "services/user.service";

const service = new UserService();

export const TrainingUsers = () => {
  const { query } = useRouter();
  const columns = [
    {
      key: "first_name",
      title: "full name",
      render: ({ first_name, last_name }) => `${first_name} ${last_name}`,
    },
    {
      key: "phone",
      dbColName: "phone",
      title: "phone",
      render: (phone) =>
        `(${phone?.slice(0, 3)}) ${phone?.slice(3, 6)}-${phone?.slice(6, 10)}`,
    },
    { key: "tenure", dbColName: "tenure", title: "tenure" },
    // { key: "location", dbColName: "location", title: "location" },
    // { key: "meeting_type", dbColName: "meeting_type", title: "meeting type" },
    // {
    //   key: "date_submission",
    //   dbColName: "date_submission",
    //   title: "date submission",
    // },
    // { key: "cell_phone", dbColName: "cell_phone", title: "cell phone" },
    // { key: "rep_tenure", dbColName: "rep_tenure", title: "rep tenure" },
    // {
    //   key: "date_attended",
    //   dbColName: "date_attended",
    //   title: "date attended",
    // },
    // { key: "meeting_id", dbColName: "meeting_id", title: "meeting id" },
    // { key: "week_number", dbColName: "week_number", title: "week number" },
    // {
    //   key: "attended_last_week",
    //   dbColName: "attended_last_week",
    //   title: "attended last week",
    // },
    // {
    //   key: "sales_year_week_number",
    //   dbColName: "sales_year_week_number",
    //   title: "sales year week number",
    // },
    // { key: "recruiter_id", dbColName: "recruiter_id", title: "recruiter_id" },
    // {
    //   key: "recruiter_name",
    //   dbColName: "recruiter_name",
    //   title: "recruiter_name",
    // },
    // {
    //   key: "recruiter_phone_number",
    //   dbColName: "recruiter_phone_number",
    //   title: "recruiter phone number",
    // },
    // {
    //   key: "recruiter_email",
    //   dbColName: "recruiter_email",
    //   title: "recruiter email",
    // },
    // {
    //   key: "team_leader_id",
    //   dbColName: "team_leader_id",
    //   title: "team leader id",
    // },
    // {
    //   key: "team_leader_name",
    //   dbColName: "team_leader_name",
    //   title: "team leader name",
    // },
    // {
    //   key: "team_leader_phone_number",
    //   dbColName: "team_leader_phone_number",
    //   title: "team leader phone number",
    // },
    // {
    //   key: "team_leader_email",
    //   dbColName: "team_leader_email",
    //   title: "team_leader_email",
    // },
    // {
    //   key: "management_group_leader_id",
    //   dbColName: "management_group_leader_id",
    //   title: "management group leader id",
    // },
    // {
    //   key: "management_group_leader_name",
    //   dbColName: "management_group_leader_name",
    //   title: "management_group_leader_name",
    // },
    // {
    //   key: "management_group_leader_phone_number",
    //   dbColName: "management_group_leader_phone_number",
    //   title: "management_group_leader_phone_number",
    // },
    // {
    //   key: "management_group_leader_email",
    //   dbColName: "management_group_leader_email",
    //   title: "management_group_leader_email",
    // },
    // {
    //   key: "senior_management_group_leader_id",
    //   dbColName: "senior_management_group_leader_id",
    //   title: "senior_management_group_leader_id",
    // },
    // {
    //   key: "senior_management_group_leader",
    //   dbColName: "senior_management_group_leader",
    //   title: "senior_management_group_leader",
    // },
    // {
    //   key: "senior_management_group_leader_phone_number",
    //   dbColName: "senior_management_group_leader_phone_number",
    //   title: "senior_management_group_leader_phone_number",
    // },
    // {
    //   key: "senior_management_group_leader_email",
    //   dbColName: "senior_management_group_leader_email",
    //   title: "senior_management_group_leader_email",
    // },
    // {
    //   key: "region_leader_id",
    //   dbColName: "region_leader_id",
    //   title: "region_leader_id",
    // },
    // {
    //   key: "region_leader_name",
    //   dbColName: "region_leader_name",
    //   title: "region_leader_name",
    // },
    // {
    //   key: "region_leader_phone_number",
    //   dbColName: "region_leader_phone_number",
    //   title: "region_leader_phone_number",
    // },
    // {
    //   key: "region_leader_email",
    //   dbColName: "region_leader_email",
    //   title: "region_leader_email",
    // },
    // {
    //   key: "senior_region_id",
    //   dbColName: "senior_region_id",
    //   title: "senior_region_id",
    // },
    // {
    //   key: "senior_region_name",
    //   dbColName: "senior_region_name",
    //   title: "senior_region_name",
    // },
    // {
    //   key: "senior_region_leader_phone_number",
    //   dbColName: "senior_region_leader_phone_number",
    //   title: "senior_region_leader_phone_number",
    // },
    // {
    //   key: "senior_region_leader_email",
    //   dbColName: "senior_region_leader_email",
    //   title: "senior_region_leader_email",
    // },
    // {
    //   key: "partner_leader_id",
    //   dbColName: "partner_leader_id",
    //   title: "partner_leader_id",
    // },
    // {
    //   key: "partner_leader_name",
    //   dbColName: "partner_leader_name",
    //   title: "partner_leader_name",
    // },
    // {
    //   key: "partner_leader_phone_number",
    //   dbColName: "partner_leader_phone_number",
    //   title: "partner_leader_phone_number",
    // },
    // {
    //   key: "division_leader_id",
    //   dbColName: "division_leader_id",
    //   title: "division_leader_id",
    // },
    // {
    //   key: "division_leader_name",
    //   dbColName: "division_leader_name",
    //   title: "division_leader_name",
    // },
    // {
    //   key: "division_leader_phone_number",
    //   dbColName: "division_leader_phone_number",
    //   title: "division_leader_phone_number",
    // },
    // {
    //   key: "contact_leader_id",
    //   dbColName: "contact_leader_id",
    //   title: "contact_leader_id",
    // },
    // {
    //   key: "contact_leader_name",
    //   dbColName: "contact_leader_name",
    //   title: "contact_leader_name",
    // },
    // {
    //   key: "contact_leader_phone_number",
    //   dbColName: "contact_leader_phone_number",
    //   title: "contact_leader_phone_number",
    // },
    // {
    //   key: "contact_leader_email",
    //   dbColName: "contact_leader_email",
    //   title: "contact_leader_email",
    // },
  ];

  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);

  const { push } = useRouter();

  useEffect(() => {
    setLoading(true);
    service
      .locationRaffles(query.id)
      .then((resp) => {
        setData(resp.data.raffle);
        setTotalItems(resp.data.raffle.length);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [query.id]);

  return (
    <Fragment>
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center gap-3">
          <button
            onClick={() => push("/weekly-trainings?tab=Reports")}
            className="rounded-md bg-white border border-gray-200 px-4 py-2.5"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
              />
            </svg>
          </button>
          <div>
            <h1 className="font-medium text-gray-700 tracking-wide text-xl">
              Users List
            </h1>
            <p className="text-gray-500 text-sm">View users</p>
          </div>
        </div>
      </div>
      <Table
        data={data}
        loadingData={loading}
        setData={setData}
        totalItems={totalItems}
        columns={columns}
        exportButton={false}
      />
    </Fragment>
  );
};
