import { Table } from "common/Table/Table";
import { Filters } from "pages/Opportunities";
import { Fragment, useEffect, useState } from "react";
import UserService from "services/user.service";
import { exportToCSV } from "utils/funcs";

const service = new UserService();

export const Sales = () => {
  const columns = [
    {
      key: "OFFICE_NAME",
      title: "OFFICE NAME",
      label: "OFFICE NAME",
      dbColName: "OFFICE_NAME",
      freeze: true,
      freezeIndex: 'left-0'
    },
    {
      key: "OFFICE_TYPE",
      title: "OFFICE type",
      label: "OFFICE type",
      dbColName: "OFFICE_TYPE",
      freeze: true,
      freezeIndex: 'left-40'
    },
    {
      key: "DIVISION_NAME",
      title: "Division",
      label: "Division",
      dbColName: "DIVISION_NAME",
    },
    {
      key: "MANAGEMENT_GROUP_LEADER_NAME",
      title: "MANAGEMENT GROUP LEADER NAME",
      label: "MANAGEMENT GROUP LEADER NAME",
      dbColName: "MANAGEMENT_GROUP_LEADER_NAME",
    },
    {
      key: "MANAGEMENT_GROUP_LEADER_PHONE",
      title: "MANAGEMENT GROUP LEADER PHONE",
      label: "MANAGEMENT GROUP LEADER PHONE",
      dbColName: "MANAGEMENT_GROUP_LEADER_PHONE",
    },
    {
      key: "SENIOR_MANAGEMENT_GROUP_LEADER_NAME",
      title: "SENIOR MANAGEMENT GROUP LEADER NAME",
      label: "SENIOR MANAGEMENT GROUP LEADER NAME",
      dbColName: "SENIOR_MANAGEMENT_GROUP_LEADER_NAME",
    },
    {
      key: "REGION_LEADER_ID",
      title: "REGION LEADER ID",
      dbColName: "REGION_LEADER_ID",
    },
    {
      key: "REGION_LEADER_NAME",
      title: "REGION LEADER NAME",
      label: "REGION LEADER NAME",
      dbColName: "REGION_LEADER_NAME",
    },
    {
      key: "REGION_LEADER_PHONE",
      title: "REGION LEADER PHONE",
      label: "REGION LEADER PHONE",
      dbColName: "REGION_LEADER_PHONE",
    },
    {
      key: "REGION_LEADER_PHONE",
      title: "REGION LEADER PHONE",
      label: "REGION LEADER PHONE",
      dbColName: "REGION_LEADER_PHONE",
    },
    {
      key: "SENIOR_REGION_LEADER_NAME",
      title: "SENIOR REGION LEADER NAME",
      label: "SENIOR REGION LEADER NAME",
      dbColName: "SENIOR_REGION_LEADER_NAME",
    },
    {
      key: "SENIOR_REGION_LEADER_PHONE",
      title: "SENIOR REGION LEADER PHONE",
      label: "SENIOR REGION LEADER PHONE",
      dbColName: "SENIOR_REGION_LEADER_PHONE",
    },
    {
      key: "PARTNERSHIP_LEADER_ID",
      title: "PARTNERSHIP LEADER ID",
      label: "PARTNERSHIP LEADER ID",
      dbColName: "PARTNERSHIP_LEADER_ID",
    },
    {
      key: "PARTNERSHIP_LEADER_NAME",
      title: "PARTNERSHIP LEADER NAME",
      label: "PARTNERSHIP LEADER NAME",
      dbColName: "PARTNERSHIP_LEADER_NAME",
    },
    {
      key: "PARTNERSHIP_LEADER_PHONE",
      title: "PARTNERSHIP LEADER PHONE",
      label: "PARTNERSHIP LEADER PHONE",
      dbColName: "PARTNERSHIP_LEADER_PHONE",
    },
  ];

  const [data, setData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    year: 2023,
  });

  useEffect(() => {
    setLoading(true);
    service
      .getSaleOffices(pagination)
      .then((resp) => {
        setTotalItems(+resp.data.total_docs);
        setData(resp.data.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [pagination]);

  const handleExport = () => {
    exportToCSV(data, "sales")
  }

  return (
    <Fragment>
      <div className="flex flex-col mb-4">
        <h1 className="font-semibold text-gray-700 tracking-wide text-xl">
          Sales
        </h1>
        <p className="text-gray-500 text-sm">View all offices </p>
      </div>
      <Table
        data={data}
        pagination={pagination}
        handleExport={handleExport}
        setPagination={setPagination}
        loadingData={loading}
        filters={
          <Filters pagination={pagination} setPagination={setPagination} />
        }
        totalItems={totalItems}
        columns={columns}
      />
    </Fragment>
  );
};
