import { Table } from "common/Table/Table";
import { useEffect, useState } from "react";
import UserService from "services/user.service";
import { exportToCSV } from "utils/funcs";

const service = new UserService();

export const Breezy = () => {
  const columns = [
    { key: "UID", label: "UID", title: "UID", dbColName: "UID" },
    {
      key: "OBJECT_ID",
      label: "OBJECT ID",
      title: "OBJECT ID",
      dbColName: "OBJECT_ID",
    },
    {
      key: "OBJECT_TYPE",
      label: "OBJECT TYPE",
      title: "OBJECT TYPE",
      dbColName: "OBJECT_TYPE",
    },
    {
      key: "COMPANY_ID",
      label: "COMPANY ID",
      title: "COMPANY ID",
      dbColName: "COMPANY_ID",
    },
    {
      key: "COMPANY_NAME",
      label: "COMPANY NAME",
      title: "COMPANY NAME",
      dbColName: "COMPANY_NAME",
    },
    {
      key: "COMPANY_FRIENDLY_ID",
      label: "COMPANY FRIENDLY ID",
      title: "COMPANY FRIENDLY ID",
      dbColName: "COMPANY_FRIENDLY_ID",
    },
    {
      key: "POSITION_ID",
      label: "POSITION ID",
      title: "POSITION ID",
      dbColName: "POSITION_ID",
    },
    {
      key: "POSITION_NAME",
      label: "POSITION NAME",
      title: "POSITION NAME",
      dbColName: "POSITION_NAME",
    },
    {
      key: "PIPELINE_ID",
      label: "PIPELINE ID",
      title: "PIPELINE ID",
      dbColName: "PIPELINE_ID",
    },
    { key: "ADMINS", label: "ADMINS", title: "ADMINS", dbColName: "ADMINS" },
    {
      key: "LOCATION_NAME",
      label: "LOCATION NAME",
      title: "LOCATION NAME",
      dbColName: "LOCATION_NAME",
    },
    {
      key: "CANDIDATE_ID",
      label: "CANDIDATE ID",
      title: "CANDIDATE ID",
      dbColName: "CANDIDATE_ID",
    },
    {
      key: "FIRST_NAME",
      label: "FIRST NAME",
      title: "FIRST NAME",
      dbColName: "FIRST_NAME",
    },
    {
      key: "LAST_NAME",
      label: "LAST NAME",
      title: "LAST NAME",
      dbColName: "LAST_NAME",
    },
    {
      key: "FULL_NAME",
      label: "FULL NAME",
      title: "FULL NAME",
      dbColName: "FULL_NAME",
    },
    { key: "EMAIL", label: "EMAIL", title: "EMAIL", dbColName: "EMAIL" },
    { key: "PHONE", label: "PHONE", title: "PHONE", dbColName: "PHONE" },
    { key: "TAGS", label: "TAGS", title: "TAGS", dbColName: "TAGS" },
    {
      key: "STAGE_ID",
      label: "STAGE ID",
      title: "STAGE ID",
      dbColName: "STAGE_ID",
    },
    {
      key: "STAGE_NAME",
      label: "STAGE NAME",
      title: "STAGE NAME",
      dbColName: "STAGE_NAME",
    },
    {
      key: "ENTERED_STAGE_DATE",
      label: "ENTERED STAGE DATE",
      title: "ENTERED STAGE DATE",
      dbColName: "ENTERED_STAGE_DATE",
    },
    {
      key: "SOURCE_TYPE",
      label: "SOURCE TYPE",
      title: "SOURCE TYPE",
      dbColName: "SOURCE_TYPE",
    },
    {
      key: "SOURCE_ID",
      label: "SOURCE ID",
      title: "SOURCE ID",
      dbColName: "SOURCE_ID",
    },
    {
      key: "SOURCE_NAME",
      label: "SOURCE NAME",
      title: "SOURCE NAME",
      dbColName: "SOURCE_NAME",
    },
    {
      key: "PAYMENT",
      label: "PAYMENT",
      title: "PAYMENT",
      dbColName: "PAYMENT",
    },
    {
      key: "CREATION_DATE",
      label: "CREATION DATE",
      title: "CREATION DATE",
      dbColName: "CREATION_DATE",
    },
  ];

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 100,
  });

  useEffect(() => {
    setLoading(true);
    service
      .getBreezy(pagination)
      .then((resp) => {
        setData(
          resp.data.data.map((item) => ({
            ...item,
            ENTERED_STAGE_DATE: new Date(
              item.ENTERED_STAGE_DATE
            ).toLocaleString(),
          }))
        );
        setTotalItems(resp.data.total_docs);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [pagination]);

  const handleExport = () => {
    exportToCSV(data, "breezy")
  }

  return (
    <Table
      data={data}
      handleExport={handleExport}
      loadingData={loading}
      setPagination={setPagination}
      pagination={pagination}
      totalItems={totalItems}
      columns={columns}
    />
  );
};
