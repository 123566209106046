import React from "react"
import { useSelector } from "react-redux"
import { Route, Redirect, RouteProps } from "react-router-dom"
import { userSelector } from "redux/selectors/user"

interface IProps extends RouteProps {
    component: React.FC<RouteProps>
    permittedRoles?: string[]
    permission?: string
    authorized: boolean
    path: string
}

export const ProtectedRoute: React.FC<IProps> = ({
    component: Component,
    path,
    permittedRoles,
    authorized,
    permission,
}) => {
    const user = useSelector(userSelector)
    return (
        <Route
            path={path}
            exact
            render={p => {
                if (!authorized) {
                    return (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: p.location },
                            }}
                        />
                    )
                }

                // if (
                //      !user?.permissions?.includes(permission) && user?.admin !== "admin" && path !== "/settings"
                // ) {
                //     return <Redirect to="/" />
                // } else {
                    return <Component {...p} />
                // }
            }}
        />
    )
}

// function checkRoles(userRoles?: string[], permittedRoles?: string[]) {
//     return userRoles?.some(ur => permittedRoles?.includes(ur))
// }
