import { BrowserRouter as Router } from "react-router-dom"
import { Routes } from "routing/Routes"

// import "react-datepicker/dist/react-datepicker.css";

function App() {
    return (
        <Router>
            <Routes />
        </Router>
    )
}

export default App
