import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    loader: false,
    isLoggedIn: false,
    user: null,
    error: null
}

const userReducer = createSlice({
    name: "user",
    initialState,
    reducers: {
        initLogin: (state) =>  {
            state.loader = true
        },
        loginSuccess: (state, action) => {
            state.user = action.payload.data
            state.isLoggedIn = !!action.payload.token
            state.error = null
            state.loader = false
            state.error = null
        },
        loginFailure: (state, action) => {
            state.user = null
            state.isLoggedIn = false
            state.loader = false
            state.error = action.payload
        },
        logout: state => {
            state.loader = true
        },
        logoutSuccess: state => {
            state.user = null
            state.loader = false
            state.isLoggedIn = false
        },
        logoutFailure: state => {
            state.loader = false
        },
    },
})

export const {
    loginSuccess,
    logoutFailure,
    loginFailure,
    logout,
    logoutSuccess,
    initLogin
} = userReducer.actions

export default userReducer.reducer
